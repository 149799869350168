import React from 'react';
import { connect } from 'react-redux';
import {
    Paper, Typography, Grid,
} from '@material-ui/core';
import {ExchangeRateList, ExchangeRateCreate ,ExchangeRateUpdate} from '../components/ExchangeRate';
import {createExchange, fetchAllExchange, deleteExchange, getExchangeData} from '../actions/exchange';

class ExchangeRatePage extends React.Component {
    constructor(props) {
        super(props);
        this.renderComponent = this.renderComponent.bind(this);
    }

    renderActions(row) {
        return (<button>Actions</button>);
    }

    renderComponent(action) {
        switch (action.toUpperCase()) {
            case "LIST":
                return (
                    <ExchangeRateList {...this.props}/>
                )
            case "CREATE":
                return (
                    <ExchangeRateCreate {...this.props}/>
                )
            case "UPDATE":
                return (
                    <ExchangeRateUpdate {...this.props}/>
                )
            default:
                return (
                    <ExchangeRateList {...this.props}/>
                )
        }
    }

    render() {
        const {action} = this.props.params;
        return (
            <div className={"mc_page"}>
                {this.renderComponent(action)}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {...state}
}

const mapDispatchToProps = dispatch => {
    return {
        createExchange: async (data) => {
            return await dispatch(createExchange(data))
        },
        fetchAllExchange: async (data) => {
            return await dispatch(fetchAllExchange(data));
        },
        deleteExchange: async (data) => {
            return await dispatch(deleteExchange(data));
        },
        getExchangeData: async (data) => {
            return await dispatch(getExchangeData(data));
        },
        // updateUserProfile: async (data) => {
        //     return await dispatch(updateUserProfile(data));
        // }
    }
}

const action = {
    LIST: 'list',
    CREATE: 'create',
    UPDATE: 'update',
}

export default connect(mapStateToProps, mapDispatchToProps)(ExchangeRatePage);
import React from 'react';
import { connect } from 'react-redux';
import {Profile} from '../components/User';
import {
    getCurrentProfile,
    updateCurrentProfile,
} from '../actions/user';

class ProfilePage extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    render() {
        return (
            <div className={"mc_page"}>
                <Profile {...this.props}/>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {...state}
}

const mapDispatchToProps = dispatch => {
    return {
        getCurrentProfile: async () => {
            return await dispatch(getCurrentProfile());
        },
        updateCurrentProfile: async (data) => {
            return await dispatch(updateCurrentProfile(data));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
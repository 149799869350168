import React from 'react';
import {
    Button, Grid, Card, CardActionArea, 
    CardActions, CardContent, Divider, 
    CardMedia, Typography, TextField
} from '@material-ui/core';
import './RegisterPage.css';
import userImage from "../../assets/images/male-user.png";

class RegisterPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            email: '',
            password: '',
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(field, e) {
        this.state[field] = e.currentTarget.value;
        this.setState({...this.state});
    }

    render() {
        return (
            <Grid container className={"login-page-wrapper"} spacing={16}>
                <Card className={"any-center login-form"} sm={12} md={6} lg={4} >
                    <CardMedia
                        component="img"
                        alt="Contemplative Reptile"
                        height="140"
                        image={userImage}
                        title="Contemplative Reptile"
                        />
                        <CardContent>
                            <Typography variant="h6" color="inherit" className="login-title">REGISTER</Typography>
                            <Divider light />
                            <Grid container spacing={16}>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <TextField
                                    required
                                    error={!validateEmail(this.state.email)}
                                    id="email"
                                    label="Email"
                                    className="full-width"
                                    value={this.state.email}
                                    onChange={(e) => this.handleChange('email', e)}
                                    margin="normal"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <TextField
                                    required
                                    error={validatePassword(this.state.password)}
                                    id="password"
                                    label="Password"
                                    type="password"
                                    className="full-width"
                                    value={this.state.password}
                                    onChange={(e) => this.handleChange('password', e)}
                                    margin="normal"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <TextField
                                    required
                                    error={!this.state.fullname}
                                    id="fullname"
                                    label="Full Name"
                                    type="text"
                                    className="full-width"
                                    value={this.state.fullname}
                                    onChange={(e) => this.handleChange('fullname', e)}
                                    margin="normal"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <TextField
                                    required
                                    error={validatePassword(this.state.retypePassword)}
                                    id="retypePassword"
                                    label="Retype Password"
                                    className="full-width"
                                    value={this.state.retypePassword}
                                    onChange={(e) => this.handleChange('retypePassword', e)}
                                    margin="normal"
                                    type="password"
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    <CardContent>
                        <Grid container  direction="row"
                            justify="center"
                            alignItems="center">
                            <Button variant="contained" color="primary" 
                                    disabled={!validateEmail(this.state.email) || validatePassword(this.state.password)}
                                    onClick={() => this.props.onSubmit(this.state)}
                            >Register</Button>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        )
    }
}

const validateEmail = (email) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

const validatePassword = (pass) => {
    return !pass || pass.length < 6;
}

export default RegisterPage;
import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import {
    Paper, Typography, Grid,
} from '@material-ui/core';
import {MaterialTable} from '../components/common/Table';
import {ProductList, ProductCreate, ProductUpdate} from '../components/Product';
import {createProduct, getAllListProduct, deleteProduct, getProductData} from '../actions/product';
import {getCategoriesInShort} from '../actions/category';

class ProductPage extends React.Component {
    constructor(props) {
        super(props);
        this.renderComponent = this.renderComponent.bind(this);
    }

    renderComponent(action) {
        switch (action.toUpperCase()) {
            case "LIST":
                return (
                    <ProductList {...this.props}/>
                )
            case "CREATE":
                return (
                    <ProductCreate {...this.props}/>
                )
            case "UPDATE":
                return (
                    <ProductUpdate {...this.props}/>
                )
            default:
                return (
                    <ProductList {...this.props}/>
                )
        }
    }

    render() {
        const {action} = this.props.params;
        return (
            <div className={"mc_page"}>
                {this.renderComponent(action)}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {...state}
}

const mapDispatchToProps = dispatch => {
    return {
        createProduct: async (data) => {
            return await dispatch(createProduct(data));
        },
        getAllListProduct: async (data) => {
            return await dispatch(getAllListProduct(data));
        },
        deleteProduct: async (data) => {
            return await dispatch(deleteProduct(data));
        },
        getProductData: async (data) => {
            return await dispatch(getProductData(data));
        },
        getCategoriesInShort: async (data) => {
            return await dispatch(getCategoriesInShort());
        }
    }
}

const action = {
    LIST: 'list',
    CREATE: 'create',
    UPDATE: 'update',
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductPage);
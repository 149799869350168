// Validate Email Address
export const validateEmail = (email) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

// Validate Empty String
export const validateEmptyString = (string) => !string;

// Validate Product
export const validateProduct = (formData) => {
    let requiredFields = ['product_name', 'product_description', 'product_content', 'product_thumbnail', 'product_status', 'product_price'];
    for (let key of requiredFields) {
        if (!formData || !formData[key]) {
            return false;
        }
    }
    return true;
}

export const validateCategory = (formData) => {
    let requiredFields = ['category_name', 'category_type', 'category_parent'];
    for (let key of requiredFields) {
        if (!formData || !formData[key]) {
            return false;
        }
    }
    return true;
}

export const validateSupplier = (formData) => {
    let requiredFields = ['supplier_name', 'supplier_email', 'supplier_phone', 'supplier_supporter'];
    for (let key of requiredFields) {
        if (!formData || !formData[key]) {
            return false;
        }
        if (key == 'supplier_email' && !validateEmail(formData[key])) {
            return false;
        }
    }
    return true;
}

const validate = {
    validateEmail,
    validateEmptyString,
}
export default validate;
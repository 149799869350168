import React from 'react';
import {
    Button, Grid, Card, CardActionArea, 
    CardActions, CardContent, Divider, 
    CardMedia, Typography, TextField
} from '@material-ui/core';
import './LoginPage.css';
import userImage from "../../assets/images/male-user.png";

class LoginPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            otp: '',
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(field, e) {
        this.state[field] = e.currentTarget.value;
        this.setState({...this.state});
    }

    render() {
        return (
            <Grid container className={"login-page-wrapper"} spacing={16}>
                <Card className={"login-form"} sm={12} md={6} lg={4} className={"any-center"}>
                    <CardContent>
                        <Typography variant="h6" color="inherit" className="login-title">OTP</Typography>
                        <Divider light />
                        <Grid container>
                            <Grid item xs={12} md={12} lg={12}>
                                <TextField
                                required
                                error={!validateOTP(this.state.otp)}
                                id="otp"
                                label="OTP"
                                className="full-width"
                                value={this.state.otp}
                                onChange={(e) => this.handleChange('otp', e)}
                                margin="normal"
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardContent>
                        <Grid container  direction="row"
                            justify="center"
                            alignItems="center">
                            <Button variant="contained" color="primary" disabled={!validateOTP(this.state.otp)}>Verify</Button>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        )
    }
}

const validateOTP = (otp) => {
    return !otp || otp.length < 6;
}

export default LoginPage;
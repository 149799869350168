import React from 'react';
import { browserHistory } from 'react-router';
import {
	Paper,
	Typography,
	Grid,
	Button,
	TextField,
	FormControl,
	Select,
	MenuItem,
	InputLabel,
	Input,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions
} from '@material-ui/core';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import { Delete, Edit, Search, Unarchive } from '@material-ui/icons';
import { MaterialTable } from '../../components/common/Table';
import ReactSelect from '../../components/common/ReactSelect/ReactSelect';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { PdfExporter } from '../../components/common/PdfExporter';
import FinanceExcelExporter from '../../components/common/ExcelJS/FinanceExcelExporter';
import { DatePicker } from 'material-ui-pickers';
import FinancalTemplate from './FinancalTemplate';
import moment from 'moment';
import './Finance.css';
import { getLoggedInRole } from '../../utils/helper';
import { PERMISSION_LEVELS } from '../../constants';
import _ from 'lodash';

class FinancalList extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			userRole: 'customer',
			openConfirm: false,
			selectedItem: null,
			openPaymentDialog: false,
			openExporter: false,
			paymentData: {
				payment_value: 0,
				payment_note: ''
			},
			formData: {
				customer_selected: 0,
				date_from: moment()
					.startOf('month')
					.format('YYYY-MM-DD 00:00:00'),
				date_to: moment()
					.endOf('month')
					.format('YYYY-MM-DD 23:59:59')
			},
			users: [],
			page: {
				width: 808,
				height: 1144
			}
		};
	}

	componentDidMount() {
		this.getInitializeData();
		this.state.userRole = getLoggedInRole();
		const { userData } = this.props.user;
		this.state.formData.customer_selected = userData.id;
		this.setState({ ...this.state });
	}

	getInitializeData = async () => {
		await this.getAllUserForFinance();
		this.filterFinanceByCritia();
	};

	getAllUserForFinance = async () => {
		if (getLoggedInRole() == PERMISSION_LEVELS[0]) return;
		const users = await this.props.getAllUserForFinance();
		if (Array.isArray(users) && users.length > 0) {
			this.state.formData.customer_selected = users[0].id;
		}
		this.state.users = users;
		this.setState({ ...this.state });
	};

	handleSelectChange = (field, e) => {
		this.state.formData[field] = e.target.value;
		this.setState({ formData: { ...this.state.formData } });
	};

	handleSelectTimeChange(field, e) {
		this.state.formData[field] = e;
		this.setState({ formData: { ...this.state.formData } });
	}

	isFilterDisabled = () => {
		return (
			!this.state.formData.customer_selected ||
			!this.state.formData.date_from ||
			!this.state.formData.date_to
		);
	};

	filterFinanceByCritia = async (
		initialValues = {
			limit: 10,
			page: 1,
			orderBy: 'id',
			orderDir: 'asc',
			textSearch: ''
		}
	) => {
		const { customer_selected, date_from, date_to } = this.state.formData;
		await this.props.getAllListOrdersForFinance(
			{
				...initialValues
			},
			{
				customer_selected,
				date_from,
				date_to
			}
		);
		await this.props.getAllPaymentHistoryInRange({
			customer_selected,
			date_from,
			date_to
		});
	};

	handleClosePaymentDialog = () => {
		this.setState({ openPaymentDialog: false });
	};

	handleOpenPaymentDialog = data => {
		this.setState({ paymentHistories: data, openPaymentDialog: true });
	};

	handleCloseExporter = async () => {
		this.state.openExporter = false;
		this.setState({ ...this.state });
	};

	handleOpenExporter = async (
		initialValues = {
			limit: 10,
			page: 1,
			orderBy: 'id',
			orderDir: 'asc',
			textSearch: ''
		}
	) => {
		const { customer_selected, date_from, date_to } = this.state.formData;
		await this.props.getAllListOrdersForFinanceWithoutPaging(
			{
				...initialValues
			},
			{
				customer_selected,
				date_from,
				date_to
			}
		);
		await this.props.getAllPaymentHistoryInRange({
			customer_selected,
			date_from,
			date_to
		});
		this.state.openExporter = true;
		this.setState({ ...this.state });
	};

	renderTime(row) {
		return moment(row.created_at).format('DD-MM-YY HH:mm:SS');
	}

	renderItemCode(row) {
		return (
			<Tooltip title={row.item_code} aria-label={row.item_code}>
				<Link component='button' variant='body2'>
					{row.item_code ? row.item_code.substring(0, 15) : ''}
				</Link>
			</Tooltip>
		);
	}

	renderOrderCode(row) {
		return (
			<Tooltip title={row.order_code} aria-label={row.order_code}>
				<Link
					component='button'
					variant='body2'
					onClick={() =>
						browserHistory.push(
							`/admin/order-management/view/${row.order_code}`
						)
					}
				>
					{row.order_code ? row.order_code.substring(0, 15) : ''}
				</Link>
			</Tooltip>
		);
	}

	renderPayment = row => {
		const { payments = [] } = row;

		const paymentsContent = payments.map((payment, index) => {
			return `${moment(payment.createdAt).format('DD-MM-YY')} - ${
				payment.payment_value
			} - ${payment.payment_note}`;
		});

		return (
			<>
				<Button
					onClick={() =>
						this.handleOpenPaymentDialog(paymentsContent.join('\n'))
					}
				>
					Views payment
				</Button>
			</>
		);
	};

	render() {
		const { order, payment } = this.props;
		const { date_from, date_to } = this.state.formData;

		if (!order) return <div />;

		const {
			totalMoneyNeedPayTillLastMonth,
			totalMoneyNeedPayThisMonth,
			totalMoneyPaiedTillLastMonth,
			totalMoneyPaiedThisMonth
		} = order.financeData || {};

		const FINANCAL_PAGE_COLUMNS = [
			{ title: '#', sortable: false, data_field: 'index' },
			{
				title: 'Order date',
				sortable: false,
				data_field: 'created_at',
				render: this.renderTime
			},
			{
				title: 'Order ID',
				sortable: false,
				data_field: 'order_code',
				render: this.renderOrderCode
			},
			{ title: 'Item name', sortable: false, data_field: 'item_name' },
			{ title: 'Email', sortable: false, data_field: 'user_email' },
			{ title: 'Quantity', sortable: false, data_field: 'item_amount' },
			{ title: 'Total', sortable: false, data_field: 'item_total_price' },
			{
				title: 'Payment',
				sortable: false,
				data_field: null,
				render: this.renderPayment
			},
			{ title: 'Other', sortable: false, data_field: null },
			{ title: 'Note', sortable: false, data_field: null }
		];

		const { userData } = this.props.user;
		const USER_LIST =
			this.state.userRole === PERMISSION_LEVELS[0]
				? [
						{
							...userData,
							name: userData.first_name + ' ' + userData.last_name
						}
				  ]
				: this.state.users;

		return (
			<Paper elevation={1} square className={'container'}>
				<Typography variant='h5' component='h4' className={'mc_page_title'}>
					Financal Management
				</Typography>
				<Grid container spacing={16}>
					<Grid item xs={12} sm={4} md={4} lg={4}>
						<FormControl className={`full-width missing-margin`}>
							<ReactSelect onChange={e => this.handleSelectChange('customer_selected', e)}/>
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={3} md={3} lg={3}>
						<DatePicker
							margin='normal'
							label='From'
							format='DD/MM/YYYY'
							className='fullwidth'
							value={this.state.formData.date_from}
							onChange={e => this.handleSelectTimeChange('date_from', e)}
						/>
					</Grid>
					<Grid item xs={12} sm={3} md={3} lg={3}>
						<DatePicker
							margin='normal'
							label='To'
							className='fullwidth'
							format='DD/MM/YYYY'
							value={this.state.formData.date_to}
							onChange={e => this.handleSelectTimeChange('date_to', e)}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						sm={2}
						md={2}
						lg={2}
						justify='center'
						alignItems='center'
						className='d-flex'
					>
						<Button
							variant='contained'
							onClick={() => this.filterFinanceByCritia()}
							disabled={this.isFilterDisabled()}
						>
							<Search />
						</Button>
					</Grid>
				</Grid>
				<Grid container spacing={16}>
					<Grid item xs={12} sm={3} md={3} lg={3}>
						<TextField
							id='outlined-read-only-input'
							label='Old Debt [1]'
							defaultValue={`0`}
							value={
								+totalMoneyNeedPayTillLastMonth - +totalMoneyPaiedTillLastMonth
							}
							margin='normal'
							InputProps={{
								readOnly: true
							}}
							variant='outlined'
						/>
					</Grid>
					<Grid item xs={12} sm={3} md={3} lg={3}>
						<TextField
							id='outlined-read-only-input'
							label='Amount this month [2]'
							defaultValue={`0`}
							value={+totalMoneyNeedPayThisMonth}
							margin='normal'
							InputProps={{
								readOnly: true
							}}
							variant='outlined'
						/>
					</Grid>
					<Grid item xs={12} sm={3} md={3} lg={3}>
						<TextField
							id='outlined-read-only-input'
							label='Paid this month [3]'
							defaultValue={`0`}
							value={+totalMoneyPaiedThisMonth}
							margin='normal'
							InputProps={{
								readOnly: true
							}}
							variant='outlined'
						/>
					</Grid>
					<Grid item xs={12} sm={3} md={3} lg={3}>
						<TextField
							id='outlined-read-only-input'
							label='Debt this month [2] - [3]'
							defaultValue={`0`}
							value={+totalMoneyNeedPayThisMonth - +totalMoneyPaiedThisMonth}
							margin='normal'
							InputProps={{
								readOnly: true
							}}
							variant='outlined'
						/>
					</Grid>
					<Grid item xs={12} sm={3} md={3} lg={3}>
						<TextField
							id='outlined-read-only-input'
							label='Total debt [1] + [2] - [3]'
							defaultValue={`0`}
							value={
								+totalMoneyNeedPayThisMonth +
								+totalMoneyNeedPayTillLastMonth -
								+totalMoneyPaiedThisMonth -
								+totalMoneyPaiedTillLastMonth
							}
							margin='normal'
							InputProps={{
								readOnly: true
							}}
							variant='outlined'
						/>
					</Grid>
					<Grid container justify='center' alignItems='center'>
						<Grid
							item
							xs={12}
							sm={2}
							md={2}
							lg={2}
							justify='center'
							alignItems='center'
							className='d-flex'
						>
							<Button
								variant='contained'
								onClick={() => this.handleOpenExporter()}
							>
								<Unarchive /> Export
							</Button>
						</Grid>
						<Grid
							item
							xs={12}
							sm={2}
							md={2}
							lg={2}
							justify='center'
							alignItems='center'
							className='d-flex'
						>
							<FinanceExcelExporter
								fileName='Finance'
								data={{
									userData: USER_LIST
										? USER_LIST.find(value => {
												return (
													value.id === this.state.formData.customer_selected
												);
										  })
										: '-1',
									financeData: order.financeData,
									orderList: order.list,
									orderTotals: order.totals,
									date_from,
									date_to,
									paymentList: payment.list
								}}
								disabled={_.isEmpty(order.list)}
							/>
						</Grid>
					</Grid>
				</Grid>

				<MaterialTable
					columns={FINANCAL_PAGE_COLUMNS}
					rows={order.list}
					totals={order.totals}
					getList={this.filterFinanceByCritia}
					isSearchable={false}
				/>

				{/* View payment dialog */}
				<Dialog
					open={this.state.openPaymentDialog}
					onClose={this.handleClosePaymentDialog}
					aria-labelledby='responsive-dialog-title'
				>
					<DialogTitle id='responsive-dialog-title'>{'Info'}</DialogTitle>
					<DialogContent>
						<pre>{this.state.paymentHistories}</pre>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleClosePaymentDialog} color='primary'>
							Cancel
						</Button>
					</DialogActions>
				</Dialog>
				<PdfExporter
					component={
						<FinancalTemplate
							className={`hidden`}
							width={`${this.state.page.width}px`}
							height={`${this.state.page.height}px`}
							data={{
								user: USER_LIST
									? USER_LIST.find(value => {
											return value.id === this.state.formData.customer_selected;
									  })
									: '-1',
								financeData: order.financeData,
								orderList: order.list,
								orderTotals: order.totals,
								date_from,
								date_to,
								paymentList: payment.list
							}}
						/>
					}
					open={this.state.openExporter}
					onClose={this.handleCloseExporter}
					id='pdfExporter'
					orderData={{}}
				/>
			</Paper>
		);
	}
}
export default FinancalList;

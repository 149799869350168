import React from "react";
import { browserHistory } from "react-router";
import {
  Paper,
  Typography,
  Grid,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Input,
  MenuItem,
  FormHelperText
} from "@material-ui/core";
import { validateSupplier, validateEmail } from "../../utils/validatation";
import "./Supplier.css";

class SupplierUpdate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {
                supplier_name: '', // default is customer
                supplier_email: '',
                supplier_phone: '',
                supplier_qq: '',
                supplier_wechat: '',
                supplier_skype: '',
                supplier_supporter: '',
            }
        }

    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.getSupplierData = this.getSupplierData.bind(this);
  }

  async componentDidMount() {
    // Fetch order data for edit
    const { id } = this.props.params;
    await this.getSupplierData({id: id});

    //Fetch order for create cateogry
    this.setState({ ...this.state });
  }

  async getSupplierData(param) {
    let itemData = await this.props.getSupplierData(param);
    delete itemData.createdAt;
    delete itemData.updatedAt;
    if (itemData) {
      this.state.formData = { ...this.state.formData, ...itemData };
    }
    await this.setState({ formData: { ...this.state.formData } });
  }

  onSubmit() {
    console.log(`Submit!`);
    console.log(this.state.formData);
    const { formData } = this.state;
    if (!validateSupplier(formData)) {
      return;
    }
    return this.props.createSupplier(formData);
  }

  handleChange(field, e) {
    this.state.formData[field] = e.currentTarget.value;
    this.setState({ formData: { ...this.state.formData } });
  }

  handleSelectChange(field, e) {
    this.state.formData[field] = e.target.value;
    this.setState({ formData: { ...this.state.formData } });
  }

  render() {
    return (
      <Paper elevation={1} square className={"container"}>
        <Typography variant="h5" component="h4" className={"mc_page_title"}>
          Supplier Update
        </Typography>
        <Grid container spacing={16}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <TextField
              className={"full-width"}
              label="Supplier name"
              type="text"
              margin="normal"
              value={this.state.formData.supplier_name}
              onChange={e => this.handleChange("supplier_name", e)}
              error={!this.state.formData.supplier_name}
            />
          </Grid>
          
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <TextField
              className={"full-width"}
              label="Supplier email"
              type="text"
              margin="normal"
              value={this.state.formData.supplier_email}
              onChange={e => this.handleChange("supplier_email", e)}
              error={!this.state.formData.supplier_email || !validateEmail(this.state.formData.supplier_email)}
            />
          </Grid>
          
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <TextField
              className={"full-width"}
              label="Supplier phone"
              type="text"
              margin="normal"
              value={this.state.formData.supplier_phone}
              onChange={e => this.handleChange("supplier_phone", e)}
              error={!this.state.formData.supplier_phone}
            />
          </Grid>
          
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <TextField
              className={"full-width"}
              label="Supplier QQ"
              type="text"
              margin="normal"
              value={this.state.formData.supplier_qq}
              onChange={e => this.handleChange("supplier_qq", e)}
              error={!this.state.formData.supplier_qq}
            />
          </Grid>
          
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <TextField
              className={"full-width"}
              label="Supplier wechat"
              type="text"
              margin="normal"
              value={this.state.formData.supplier_wechat}
              onChange={e => this.handleChange("supplier_wechat", e)}
              error={!this.state.formData.supplier_wechat}
            />
          </Grid>
          
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <TextField
              className={"full-width"}
              label="Supplier skype"
              type="text"
              margin="normal"
              value={this.state.formData.supplier_skype}
              onChange={e => this.handleChange("supplier_skype", e)}
              error={!this.state.formData.supplier_skype}
            />
          </Grid>
          
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <TextField
              className={"full-width"}
              label="Supplier supporter"
              type="text"
              margin="normal"
              value={this.state.formData.supplier_supporter}
              onChange={e => this.handleChange("supplier_supporter", e)}
              error={!this.state.formData.supplier_supporter}
            />
          </Grid>
          
          <Grid container spacing={16} className={`footer-padding`}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Button
                variant="contained"
                color="primary"
                className={`pull-right`}
                onClick={this.onSubmit}
                disabled={!validateSupplier(this.state.formData)}
              >
                Update
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Button
                variant="contained"
                color="default"
                onClick={() =>
                  browserHistory.push(`/admin/supplier-management/list`)
                }
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default SupplierUpdate;

import React from 'react';
import Excel from 'exceljs/modern.browser';
import moment from 'moment';
import api from '../../../utils/api';
import { Button } from '@material-ui/core';
import _ from 'lodash';
class ExcelExporterJS extends React.Component {
	constructor(props) {
		super(props);
		this.workbook = new Excel.Workbook();
	}

	async componentDidMount() {
		this.workbook.views = [
			{
				x: 0,
				y: 0,
				width: 10000,
				height: 20000
			}
		];
	}

	downloadWorksheet = async () => {
		let res = await api.getTemplateFile(
			'/user/file/getTemplateFile?template_id=2',
			{
				responseType: 'blob'
			}
		);
		let buffer = await new Response(res.data).arrayBuffer();
		await this.workbook.xlsx.load(buffer);
		this.worksheet = await this.workbook.getWorksheet(13);
		await this.renderTemplate();
		console.log(`RENDERING ....`);
		const { fileName = 'Template_Example' } = this.props;
		this.workbook.xlsx.writeBuffer().then(function(data) {
			const blob = new Blob([data], {
				type:
					'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
			});
			const url = window.URL.createObjectURL(blob);
			const anchor = document.createElement('a');
			anchor.href = url;
			anchor.download = `${fileName}.xlsx`;
			anchor.click();
			window.URL.revokeObjectURL(url);
			anchor.remove();
		});
	};

	renderTemplate = async () => {
		const {
			userData = {},
			date_from,
			date_to,
			financeData,
			orderList,
			paymentList
		} = this.props.data;
		await this.renderUserData(userData, date_from, date_to, financeData);
		await this.renderOrderData(orderList, paymentList);
	};

	renderUserData = async (userData, date_from, date_to, financeData) => {
		this.worksheet.getCell('C2').value = userData.name;
		this.worksheet.getCell('C3').value = userData.email;
		this.worksheet.getCell('C4').value = moment(date_from).format('DD/MM/YYYY');
		this.worksheet.getCell('C5').value = moment(date_to).format('DD/MM/YYYY');
		this.worksheet.getCell('C6').value = moment().format('DD/MM/YYYY');
		this.worksheet.getCell('F2').value =
			financeData.totalMoneyNeedPayTillLastMonth;
		this.worksheet.getCell('F3').value = financeData.totalMoneyNeedPayThisMonth;
		this.worksheet.getCell('F4').value = financeData.totalMoneyPaiedThisMonth;
		this.worksheet.getCell('F5').value =
			financeData.totalMoneyPaiedTillLastMonth;
		this.worksheet.getCell('F6').value =
			financeData.totalMoneyNeedPayThisMonth +
			financeData.totalMoneyNeedPayTillLastMonth -
			financeData.totalMoneyPaiedThisMonth -
			financeData.totalMoneyPaiedTillLastMonth;
	};

	findRowForStartLoop = (signal = '_START_LOOP_') => {
		return new Promise((resolve, reject) => {
			this.worksheet.eachRow((row, rowNum) => {
				row.eachCell((cell, cellNum) => {
					if (cell.value == signal) {
						return resolve(rowNum);
					}
				});
			});
		});
	};

	renderOrderData = async (items, payments) => {
		const maxLength = items.length;
		let startLoop = await this.findRowForStartLoop();
		for (let i = 0; i < maxLength; i++) {
			let rowData = { ...items[i] };

			let newRow = [
				i + 1,
				moment(rowData.created_at).format('DD/MM/YYYY'),
				rowData.order_code,
				rowData.item_name,
				rowData.item_amount,
				rowData.item_total_price
			];
			await this.worksheet.spliceRows(startLoop + i, 1, newRow, []);
		}

		let startLoopPayment = await this.findRowForStartLoop(
			'_START_LOOP_PAYMENT_'
		);
		const payMaxLength = payments.length;
		for (let i = 0; i < payMaxLength; i++) {
			let rowData = { ...payments[i] };
			let newRow = [
				i + 1,
				moment(rowData.createdAt).format('DD/MM/YYYY'),
				rowData.payment_value,
				rowData.payment_note
			];
			await this.worksheet.spliceRows(startLoopPayment + i, 1, newRow, []);
		}
	};

	render() {
		const { orderList } = this.props.data;
		return (
			<Button
				variant='contained'
				onClick={this.downloadWorksheet}
				color='primary'
				disabled={_.isEmpty(orderList)}
			>
				Save Excel
			</Button>
		);
	}
}

export default ExcelExporterJS;

import React from 'react';
import {browserHistory} from 'react-router';
import {
    Paper, Typography, Grid, IconButton,
} from '@material-ui/core';
import {
    Delete, Edit
} from '@material-ui/icons';
import {MaterialTable} from '../../components/common/Table';
import moment from 'moment';
import {PERMISSION_LEVELS} from '../../constants';

class ProductList extends React.Component {
    constructor(props) {
        super(props);
        this.renderActions = this.renderActions.bind(this);
        this.deleteProduct = this.deleteProduct.bind(this);
    }

  componentDidMount() {
    this.props.getAllListProduct({
      limit: 10,
      page: 1,
      orderBy: "id",
      orderDir: "asc",
      textSearch: ""
    });
  }

  renderTime(row) {
    return moment(row.createdAt).format("DD-MM-YY HH:mm:SS");
  }

  async deleteProduct(id) {
      await this.props.deleteProduct(id);
      this.props.getAllListProduct({
          limit: 10, 
          page: 1, 
          orderBy: 'id', 
          orderDir: 'asc', 
          textSearch: '',
      })
  }

  renderActions(row) {
      return (
          <div>
              <IconButton variant="contained" size="small" color="primary" className="small-btn"
                  onClick={() => browserHistory.push(`/admin/product-management/update/${row.id}`)}>
                  <Edit />
              </IconButton>
              <IconButton variant="contained" size="small" color="secondary" className="small-btn"
                  onClick={() => this.deleteProduct(row.id)}>
                  <Delete />
              </IconButton>
          </div>
      );
  }

  render() {
    const { productList } = this.props.product;
    const PRODUCT_PAGE_COLUMNS = [
      { title: "#", sortable: false, data_field: "index" },
      {
        title: "Product Name",
        sortable: true,
        data_field: 'product_name',
      },
      {
        title: "Product Code",
        sortable: true,
        data_field: "product_code",
        defaultSort: true
      },
      { title: "Product Description", sortable: true, data_field: "product_description"},
      { title: "Product Content", sortable: true, data_field: "product_content" },
      { title: "Status", sortable: true, data_field: "product_status" },
      {
        title: "Created at",
        sortable: true,
        data_field: "createdAt",
        render: this.renderTime
      },
      {
        title: "Actions",
        sortable: false,
        data_field: null,
        render: this.renderActions
      }
    ];

    return (
      <Paper elevation={1} square className={"container"}>
        <Typography variant="h5" component="h4" className={"mc_page_title"}>
          Product Management
        </Typography>
        <MaterialTable
          columns={PRODUCT_PAGE_COLUMNS}
          rows={productList.list}
          totals={productList.totals}
          getList={this.props.getAllListProduct}
          addNewLink={"/admin/product-management/create"}
        />
      </Paper>
    );
  }
}

export default ProductList;

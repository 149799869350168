import React from 'react';
import { connect } from 'react-redux';
import {
    Paper, Typography, Grid,
} from '@material-ui/core';
import {DiscountList, DiscountCreate, DiscountUpdate} from '../components/DiscountRate';
import {createDiscount, fetchAllDiscount, deleteDiscount, getDiscountData} from '../actions/discount';

class DiscountPage extends React.Component {
    constructor(props) {
        super(props);
        this.renderComponent = this.renderComponent.bind(this);
    }

    renderActions(row) {
        return (<button>Actions</button>);
    }

    renderComponent(action) {
        switch (action.toUpperCase()) {
            case "LIST":
                return (
                    <DiscountList {...this.props}/>
                )
            case "CREATE":
                return (
                    <DiscountCreate {...this.props}/>
                )
            case "UPDATE":
                return (
                    <DiscountUpdate {...this.props}/>
                )
            default:
                return (
                    <DiscountList {...this.props}/>
                )
        }
    }

    render() {
        const {action} = this.props.params;
        return (
            <div className={"mc_page"}>
                {this.renderComponent(action)}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {...state}
}

const mapDispatchToProps = dispatch => {
    return {
        createDiscount: async (data) => {
            return await dispatch(createDiscount(data))
        },
        fetchAllDiscount: async (data) => {
            return await dispatch(fetchAllDiscount(data));
        },
        deleteDiscount: async (data) => {
            return await dispatch(deleteDiscount(data));
        },
        getDiscountData: async (data) => {
            return await dispatch(getDiscountData(data));
        },
        // updateUserProfile: async (data) => {
        //     return await dispatch(updateUserProfile(data));
        // }
    }
}

const action = {
    LIST: 'list',
    CREATE: 'create',
    UPDATE: 'update',
}

export default connect(mapStateToProps, mapDispatchToProps)(DiscountPage);
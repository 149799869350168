import React from "react";
import { connect } from "react-redux";
import {
	OrderList,
	OrderCreate,
	OrderUpdate,
	OrderView,
} from "../components/Order";
import { 
	adminCreateOrder, getAllListOrders, getAllListItemsInOrder,
	getItemData, updateItem, deleteItem, getOrderMeta, savePdf,
	saveOrderMeta, addPaymentHistory, getPaymentHistory, 
	getAllListOrdersForUser, getAllListItemsInOrderForUser, userCreateOrder
} from "../actions/order";
import { getUserProfile } from '../actions/user';
import { fetchAllExchangeNoQuery } from '../actions/exchange';
import { fetchAllSupplierNoQuery } from '../actions/supplier';

class OrderPage extends React.Component {
	constructor(props) {
		super(props);
	}

	renderComponent(action) {
		switch (action.toUpperCase()) {
			case "LIST":
				return (
					<OrderList {...this.props} />
				)
			case "CREATE":
				return (
					<OrderCreate {...this.props} />
				)
			case "UPDATE":
				return (
					<OrderUpdate {...this.props} />
				)
			case "VIEW":
				return (
					<OrderView {...this.props} />
				)
			default:
				return (
					<OrderList {...this.props} />
				)
		}
	}

	render() {
		const { action } = this.props.params;
		return (
			<div className={"mc_page"}>
				{this.renderComponent(action)}
			</div>
		);
	}
}

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
	getAllListOrders: async data => {
		return await dispatch(getAllListOrders(data));
	},
	getAllListOrdersForUser: async data => {
		return await dispatch(getAllListOrdersForUser(data));
	},
	getAllListItemsInOrder: async data => {
		return await dispatch(getAllListItemsInOrder(data));
	},
	getAllListItemsInOrderForUser: async data => {
		return await dispatch(getAllListItemsInOrderForUser(data));
	},
	updateItem: async (data) => {
	return await dispatch(updateItem(data));
	},
	adminCreateOrder: async (data) => {
		return await dispatch(adminCreateOrder(data));
	},
	userCreateOrder: async (data) => {
		return await dispatch(userCreateOrder(data));
	},
	getItemData: async (data) => {
		return await dispatch(getItemData(data));
	},
	deleteItem: async (data) => {
		return await dispatch(deleteItem(data));
	},
	fetchAllExchangeNoQuery: async (data) => {
		return await dispatch(fetchAllExchangeNoQuery(data));
	},
	fetchAllSupplierNoQuery: async (data) => {
		return await dispatch(fetchAllSupplierNoQuery(data));
	},
	getOrderMeta: async (data) => {
		return await dispatch(getOrderMeta(data));
	},
	getUserProfile: async (id) => {
		return await dispatch(getUserProfile(id));
	},
	saveOrderMeta: async (meta) => {
		return await dispatch(saveOrderMeta(meta));
	},
	addPaymentHistory: async (paymentData) => {
		return await dispatch(addPaymentHistory(paymentData));
	},
	getPaymentHistory: async (orderCode) => {
		return await dispatch(getPaymentHistory(orderCode));
	},
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(OrderPage);

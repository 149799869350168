import React from 'react';
import {Provider, dispatch} from 'react-redux';
import {Route, Router, browserHistory, IndexRoute} from 'react-router';
import {LocalizeProvider} from 'react-localize-redux';
import store, {history} from './store';
import {
    App,
    AuthPage,
    UserPage,
    OrderPage,
    SupplierPage,
    ExchangeRatePage,
    DiscountPage,
    FinancalPage,
    ProfilePage,
} from './containers'
import SwitchLayout from './components/common/Dashboard/SwitchLayout';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import MomentUtils from '@date-io/moment';
import { logout } from './actions/user';

//Mock for datatables js jquery
window._appBrowserHistory = browserHistory;
// Migrate deprecated typography variants
window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

const onDefaultRedirect = () => {
    const userData = localStorage.getItem('userData');
    try {
        let userObject = JSON.parse(userData);
        if (
            userObject && userObject.role === "admin" ||
            userObject && userObject.role === "employee"
        ) {
            browserHistory.push('/admin/order-management/list');
        } else if (userObject && userObject.role === "user") {
            browserHistory.push('/user/order-management/list');
        } else {
            store.dispatch(logout());
        }
    } catch (error) {
        store.dispatch(logout());
    }
}

const AppRouter = () => (
    <MuiThemeProvider>
    <MuiPickersUtilsProvider utils={MomentUtils}>
        <Provider store={store}>
            <LocalizeProvider>
                <Router history={history}>
                    <Route component={App}>
                        <Route path={`/auth/:action`} component={AuthPage}></Route>
                        <Route path={`/:role`} component={SwitchLayout}>
                            <Route path={`/:role/user-management/:action`} component={UserPage}></Route>
                            <Route path={`/:role/user-management/:action/:id`} component={UserPage}></Route>
                            <Route path={`/:role/order-management/:action`} component={OrderPage}></Route>
                            <Route path={`/:role/order-management/:action/:id`} component={OrderPage}></Route>
                            <Route path={`/:role/supplier-management/:action`} component={SupplierPage}></Route>
                            <Route path={`/:role/supplier-management/:action/:id`} component={SupplierPage}></Route>
                            <Route path={`/:role/exchange-management/:action`} component={ExchangeRatePage}></Route>
                            <Route path={`/:role/exchange-management/:action/:id`} component={ExchangeRatePage}></Route>
                            <Route path={`/:role/discount-management/:action`} component={DiscountPage}></Route>
                            <Route path={`/:role/discount-management/:action/:id`} component={DiscountPage}></Route>
                            <Route path={`/:role/financal-management/:action`} component={FinancalPage}></Route>
                            <Route path={`/:role/financal-management/:action/:id`} component={FinancalPage}></Route>
                            <Route path={`/:role/profile`} component={ProfilePage}></Route>
                        </Route>
                        <Route path={`/`} onEnter={onDefaultRedirect}></Route>
                    </Route>
                </Router>
            </LocalizeProvider>
        </Provider>
    </MuiPickersUtilsProvider>
    </MuiThemeProvider>
);

export default AppRouter;

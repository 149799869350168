import React from "react";
import { browserHistory } from "react-router";
import {
  Paper,
  Typography,
  Grid,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Input,
  MenuItem,
  FormHelperText
} from "@material-ui/core";
import "./ExchangeRate.css";

class ExchangeRateUpdate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {
              exchange_unit: '',
              exchange_value: '', // convert to VND
            }
        }

    this.getExchangeData = this.getExchangeData.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  async componentDidMount() {
    // Fetch order data for edit
    const { id } = this.props.params;
    await this.getExchangeData({id: id});

    //Fetch order for create cateogry
    this.setState({ ...this.state });
  }

  async getExchangeData(param) {
    let itemData = await this.props.getExchangeData(param);
    if (itemData && itemData.createdAt) {
      delete itemData.createdAt;
    }
    if (itemData && itemData.updatedAt) {
      delete itemData.updatedAt;
    }
    if (itemData) {
      this.state.formData = { ...this.state.formData, ...itemData };
    }
    await this.setState({ formData: { ...this.state.formData } });
  }

  onSubmit() {
    console.log(`Submit!`);
    console.log(this.state.formData);
    const { formData } = this.state;
    return this.props.createExchange(formData);
  }

  handleChange(field, e) {
    this.state.formData[field] = e.currentTarget.value;
    this.setState({ formData: { ...this.state.formData } });
  }

  handleSelectChange(field, e) {
    this.state.formData[field] = e.target.value;
    this.setState({ formData: { ...this.state.formData } });
  }

  render() {
    return (
      <Paper elevation={1} square className={"container"}>
        <Typography variant="h5" component="h4" className={"mc_page_title"}>
          Exchage Update
        </Typography>
        <Grid container spacing={16}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <TextField
              className={"full-width"}
              label="Unit"
              type="text"
              margin="normal"
              value={this.state.formData.exchange_unit}
              onChange={e => this.handleChange("exchange_unit", e)}
              error={!this.state.formData.exchange_unit}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <TextField
              className={"full-width"}
              label="Value"
              type="number"
              margin="normal"
              value={this.state.formData.exchange_value}
              onChange={e => this.handleChange("exchange_value", e)}
              error={!this.state.formData.exchange_value}
            />
          </Grid>
          <Grid container spacing={16} className={`footer-padding`}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Button
                variant="contained"
                color="primary"
                className={`pull-right`}
                onClick={this.onSubmit}
              >
                Update
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Button
                variant="contained"
                color="default"
                onClick={() =>
                  browserHistory.push(`/admin/exchange-management/list`)
                }
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default ExchangeRateUpdate;

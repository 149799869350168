import React from 'react';
import {
    TableHead, TableRow, TableCell,
    Tooltip, TableSortLabel,
} from '@material-ui/core';

class TableHeader extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            columns : [...this.props.columns]
        }
        this.handleSortClick = this.handleSortClick.bind(this);
    }

    componentDidMount() {
        this.state.columns = this.state.columns.map(c => {
            c.orderDir = "asc";
            c.active = false;
            if (c.defaultSort) {
                c.active = true;
            }
            return c;
        })
        this.setState({...this.state})
    }

    async handleSortClick(column, index) {
        // Change direction of table
        if (this.state.columns[index].orderDir === "asc") {
            this.state.columns[index].orderDir = "desc";
        } else {
            this.state.columns[index].orderDir = "asc";
        }
        // Change active status if needed
        if (!this.state.columns[index].active) {
            this.state.columns = this.state.columns.map(c => {
                c.active = false;
                return c;
            })
            this.state.columns[index].active = true;
        }
        await this.setState({...this.state});
        return this.props.onFetch(column, index);
    }

    render() {
        const { columns } = this.state;
        const renderedColumns = columns.map((c, i) => {
            return (
                <TableCell
                    key={i}
                    //numeric={row.numeric}
                    padding={'default'}
                >
                    <Tooltip
                        title="Sort"
                        //placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                        enterDelay={300}
                    >
                        <TableSortLabel
                            active={c.sortable && c.active}
                            direction={c.orderDir || "asc"}
                            onClick={() => {
                                if (c.sortable) {
                                    return this.handleSortClick(c, i);
                                } else {
                                    return null;
                                }
                            }}
                        >
                            {c.title}
                        </TableSortLabel>
                    </Tooltip>
                </TableCell>
            );
        })
        return (
            <TableHead>
                <TableRow>
                    {renderedColumns}
                </TableRow>
            </TableHead>
        );
    }
}

export default TableHeader;
import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import {Menu, MenuItem} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MenuIcon from '@material-ui/icons/Menu';
import MailIcon from '@material-ui/icons/Mail';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { browserHistory } from 'react-router';
import './Header.css';

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            isMenuOpen: false,
        }

        this.handleMenuClose = this.handleMenuClose.bind(this);
        this.handleProfileMenuOpen = this.handleProfileMenuOpen.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
    }

    handleProfileMenuOpen(e) {
        this.setState({isMenuOpen: true, anchorEl: e.currentTarget});
    }

    handleMenuClose(callback) {
        this.setState({isMenuOpen: false});
        if (callback && typeof callback === "function") callback();
    }

    handleLogout() {
        this.props.logout();
    }

    handleRedirectToProfile = () => {
        const {userData} = this.props.user;
        if (userData && userData.role === 'customer') {
            browserHistory.push('/user/profile');
        } else if (userData && (userData.role === 'admin' || userData.role === 'employee')) {
            browserHistory.push('/admin/profile');
        }
    }
    
    renderMenu() {
        const {userData} = this.props.user;
        return (
            <Menu
                anchorEl={this.state.anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={this.state.isMenuOpen}
                onClose={this.handleMenuClose}
            >
                <MenuItem>{userData && userData.email ? `Hi, ${userData.first_name} ${userData.last_name}` : ''}</MenuItem>
                <MenuItem onClick={() => this.handleMenuClose(() => this.handleRedirectToProfile())}>Profile</MenuItem>
                <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
            </Menu>
        );
    }
  
    render() {
        return (
            <AppBar position="static">
                <Toolbar >
                    <Grid container className={"align-left-right"}>
                        <Grid item className={"topbar-left"}>
                            <IconButton color="inherit" aria-label="Menu" onClick={() => this.props.toggleSidebar(!this.props.utils.isShowSidebar)}>
                                <MenuIcon />
                            </IconButton>
                            <Typography variant="h6" color="inherit" >
                                Dashboard
                            </Typography>
                        </Grid>
                        <Grid item className={"topbar-right"}>
                            {/* <Button color="inherit" className={"right"}>Login</Button> */}
                            <IconButton color="inherit">
                                <Badge badgeContent={4} color="secondary">
                                    <MailIcon />
                                </Badge>
                            </IconButton>
                            <IconButton color="inherit">
                                <Badge badgeContent={17} color="secondary">
                                    <NotificationsIcon />
                                </Badge>
                            </IconButton>
                            <IconButton
                                aria-owns={'material-appbar'}
                                aria-haspopup="true"
                                onClick={this.handleProfileMenuOpen}
                                color="inherit"
                            >
                                <AccountCircle />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Toolbar>
                {this.renderMenu()}
            </AppBar>
        );
    }
}

export default Header;
import React from 'react';
import { browserHistory } from 'react-router';
import { Paper, Typography, Grid, IconButton } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import { MaterialTable } from '../../components/common/Table';
import moment from 'moment';
import { PERMISSION_LEVELS } from '../../constants';
import _ from 'lodash';

class UserList extends React.Component {
	constructor(props) {
		super(props);
		this.renderActions = this.renderActions.bind(this);
		this.deleteUser = this.deleteUser.bind(this);
		this.state = {
			style_actions: {}
		};
	}
	componentDidMount() {
		this.props.userGetAllList({
			limit: 10,
			page: 1,
			orderBy: 'id',
			orderDir: 'asc',
			textSearch: ''
		});
		window.addEventListener('scroll', this.handleScrollDebounce, true);
		document
			.querySelector('.table-scrollable')
			.dispatchEvent(new Event('scroll'));
	}

	handleScrollDebounce = _.debounce(event => {
		let alignElement = document.querySelector('.table-scrollable');
		let anchorElement = document.querySelector(
			'.table-scrollable > table > thead > tr > th:last-child'
		);
		const { style_actions } = this.state;
		const maxOffsetLeft =
			anchorElement.offsetLeft + anchorElement.offsetWidth - 38;
		let left = alignElement.offsetWidth + event.srcElement.scrollLeft;
		left = left > maxOffsetLeft ? maxOffsetLeft : left;
		style_actions['left'] = `calc(${left}px)`;
		this.setState({ style_actions: style_actions });
	}, 100);

	renderFullName(row) {
		return row.first_name + ' ' + row.last_name;
	}

	renderTime(row) {
		return moment(row.createdAt).format('DD-MM-YY HH:mm:SS');
	}

	renderDiscount(row) {
		return `${row.discount}`;
	}

	async deleteUser(id) {
		await this.props.deleteUser(id);
		this.props.userGetAllList({
			limit: 10,
			page: 1,
			orderBy: 'id',
			orderDir: 'asc',
			textSearch: ''
		});
	}

	renderActions(row) {
		const { userData } = this.props.user;
		let isDisabled = false;
		if (row && userData) {
			isDisabled =
				PERMISSION_LEVELS.indexOf(userData.role) <
				PERMISSION_LEVELS.indexOf(row.role);
		}
		return (
			<div className='actions' style={{ ...this.state.style_actions }}>
				<IconButton
					variant='contained'
					size='small'
					color='primary'
					className='small-btn'
					disabled={isDisabled}
					onClick={() =>
						browserHistory.push(`/admin/user-management/update/${row.id}`)
					}
				>
					<Edit />
				</IconButton>
				<IconButton
					variant='contained'
					size='small'
					color='secondary'
					className='small-btn'
					disabled={isDisabled}
					onClick={() => this.deleteUser(row.id)}
				>
					<Delete />
				</IconButton>
			</div>
		);
	}

	render() {
		const { userList } = this.props.user;
		const USER_PAGE_COLUMNS = [
			{ title: '#', sortable: false, data_field: 'index' },
			{
				title: 'Full Name',
				sortable: true,
				data_field: null,
				render: this.renderFullName
			},
			{
				title: 'Email',
				sortable: true,
				data_field: 'email',
				defaultSort: true
			},
			{ title: 'Role', sortable: true, data_field: 'role' },
			{ title: 'Status', sortable: true, data_field: 'status' },
			{
				title: 'Created at',
				sortable: true,
				data_field: 'created_at',
				render: this.renderTime
			},
			{
				title: 'Discount (%)',
				sortable: true,
				data_field: 'discount',
				render: this.renderDiscount
			},
			{
				title: 'Actions',
				sortable: false,
				data_field: null,
				render: this.renderActions
			}
		];

		return (
			<Paper elevation={1} square className={'container'}>
				<Typography variant='h5' component='h4' className={'mc_page_title'}>
					User Management
				</Typography>
				<MaterialTable
					columns={USER_PAGE_COLUMNS}
					rows={userList.list}
					totals={userList.totals}
					getList={this.props.userGetAllList}
					addNewLink={'/admin/user-management/create'}
				/>
			</Paper>
		);
	}
}

export default UserList;

import React from "react";
import { browserHistory } from "react-router";
import {
  Paper,
  Typography,
  Grid,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Input,
  MenuItem,
  FormHelperText
} from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import { validateEmail } from "../../utils/validatation";
import "./User.css";

class UserCreate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {
                role: 'customer', // default is customer
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                address_1: '',
                address_2: '',
                discount: 0,
                company_name: '',
                tax_id: '',
            }
        }

    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.getListDiscount = this.getListDiscount.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.getListDiscount();
  }

  async getListDiscount() {
      let discounts = await this.props.fetchAllDiscountNoQuery();
      this.setState({discounts: discounts});
  }

  onSubmit() {
    console.log(`Submit!`);
    console.log(this.state.formData);
    const { formData } = this.state;
    if (!validateEmail(formData.email)) {
      return;
    }
    return this.props.invite(formData);
  }

  handleChange(field, e) {
    this.state.formData[field] = e.currentTarget.value;
    this.setState({ formData: { ...this.state.formData } });
  }

  handleSelectChange(field, e) {
    this.state.formData[field] = e.target.value;
    this.setState({ formData: { ...this.state.formData } });
  }

  render() {
    let discounts = this.state.discounts || [];
    return (
      <Paper elevation={1} square className={"container"}>
        <Typography variant="h5" component="h4" className={"mc_page_title"}>
          User Create
        </Typography>
        <Grid container spacing={16}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <TextField
              className={"full-width"}
              label="First name"
              type="text"
              margin="normal"
              value={this.state.formData.first_name}
              onChange={e => this.handleChange("first_name", e)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <TextField
              className={"full-width"}
              label="Last name"
              type="text"
              margin="normal"
              value={this.state.formData.last_name}
              onChange={e => this.handleChange("last_name", e)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <TextField
              className={"full-width"}
              label="Email"
              type="text"
              margin="normal"
              value={this.state.formData.email}
              onChange={e => this.handleChange("email", e)}
              error={!validateEmail(this.state.formData.email)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <FormControl className={`full-width missing-margin`}>
              <InputLabel htmlFor="role-helper">Role</InputLabel>
              <Select
                value={this.state.formData.role}
                onChange={e => this.handleSelectChange("role", e)}
                input={<Input name="role" id="role-helper" />}
                className={`full-width`}
              >
                <MenuItem value={"admin"} selected>
                  Admin
                </MenuItem>
                <MenuItem value={"employee"}>Employee</MenuItem>
                <MenuItem value={"customer"}>Customer</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {/* <Grid item xs={12} sm={6} md={6} lg={6}>
                        <TextField
                            className={"full-width"}
                            label="Password"
                            type="password"
                            margin="normal"
                            />
                    </Grid> */}
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <TextField
              className={"full-width"}
              label="Phone number"
              type="text"
              margin="normal"
              value={this.state.formData.phone}
              onChange={e => this.handleChange("phone", e)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <TextField
              className={"full-width"}
              label="Address 1"
              type="text"
              margin="normal"
              value={this.state.formData.address_1}
              onChange={e => this.handleChange("address_1", e)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <TextField
              className={"full-width"}
              label="Address 2"
              type="text"
              margin="normal"
              value={this.state.formData.address_2}
              onChange={e => this.handleChange("address_2", e)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <FormControl className={`full-width missing-margin`}>
                <InputLabel htmlFor="discount-helper">Discount</InputLabel>
                <Select
                    value={this.state.formData.discount}
                    onChange={(e) => this.handleSelectChange('discount', e)}
                    input={<Input name="discount" id="discount-helper" />}
                    className={`full-width`}
                >
                    {
                        discounts.map(d => (<MenuItem key={d.discount_value} value={d.discount_value} selected={this.state.formData.discount == d.discount_value}>{d.discount_unit} ({d.discount_value}%)</MenuItem>))
                    }
                </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <TextField
              className={"full-width"}
              label="Company name"
              type="text"
              margin="normal"
              value={this.state.formData.company_name}
              onChange={e => this.handleChange("company_name", e)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <TextField
              className={"full-width"}
              label="Tax ID"
              type="text"
              margin="normal"
              value={this.state.formData.tax_id}
              onChange={e => this.handleChange("tax_id", e)}
            />
          </Grid>
          <Grid container spacing={16} className={`footer-padding`}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Button
                variant="contained"
                color="primary"
                className={`pull-right`}
                onClick={this.onSubmit}
                disabled={!validateEmail(this.state.formData.email)}
              >
                Create
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Button
                variant="contained"
                color="default"
                onClick={() =>
                  browserHistory.push(`/admin/user-management/list`)
                }
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default UserCreate;

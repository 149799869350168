import { combineReducers } from 'redux';
import { localizeReducer } from 'react-localize-redux';
import userReducer from './user';
import productReducer from './product';
import categoryReducer from './category';
import adminReducer from './admin';
import orderReducer from './order';
import paymentReducer from './payment';
import utilsReducer from './utils';
import supplierReducer from './supplier';
import exchangeReducer from './exchange';
import discountReducer from './discount';
import photouploadReducer from './photoupload';

/*IMPORT REDUCERS HERE*/
const appReducer = combineReducers({
    localize: localizeReducer,
    user: userReducer,
    category: categoryReducer,
    product: productReducer,
    admin: adminReducer,
    order: orderReducer,
    payment: paymentReducer,
    utils: utilsReducer,
    photoupload: photouploadReducer,
    supplier: supplierReducer,
    exchange: exchangeReducer,
    discount: discountReducer,
});

const rootReducer = (state, action) => {
    let currentState = state;
    if (action.type === 'LOGOUT_SUCCESS') {
        currentState = undefined;
    }

    return appReducer(currentState, action);
};

export default rootReducer;

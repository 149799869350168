import api from '../utils/api';
import { toast } from 'react-toastify';
import { enableLoading, loadingFailed, loadingSuccess } from '../actions/utils';
import { browserHistory } from 'react-router';
import { PERMISSION_LEVELS } from '../constants';

export const LOGIN = `LOGIN`;
export const LOGIN_SUCCESS = `LOGIN_SUCCESS`;
export const OFFLINE_LOGIN_SUCCESS = `OFFLINE_LOGIN_SUCCESS`;
export const LOGIN_FAILED = `LOGIN_FAILED`;
export const UPDATE_LOGGED_USER_INFO = `UPDATE_LOGGED_USER_INFO`;
export const LOGOUT = `LOGOUT`;
export const LOGOUT_SUCCESS = `LOGOUT_SUCCESS`;
export const USER_GET_LIST_SUCCESS = `USER_GET_LIST_SUCCESS`;
export const REGISTER_SUCCESS = `REGISTER_SUCCESS`;
export const REGISTER_FAILED = `REGISTER_FAILED`;
export const DELETE_USER_SUCCESS = `DELETE_USER_SUCCESS`;
export const ACTIVATE_ACCOUNT_SUCCESS = `ACTIVATE_ACCOUNT_SUCCESS`;

export const login = (data) => {
    return async (dispatch) => {
        try {
            await dispatch(enableLoading());
            let res = await api.login(data) || {};
            if (res.data && res.data.status === "success") {
                const { data, message } = res.data;
                if (message) toast.success(message);
                if (data) await dispatch(loginSuccess(data));
                await dispatch(loadingSuccess());
            } else {
                if (res.data && res.data.message) toast.error(res.data.message);
                await dispatch(loadingFailed());
            }
        } catch (e) {
            console.log(e);
            await dispatch(loadingFailed());
            toast.error(e.message);
        }
    }
}

export const loginSuccess = (userInfo) => {
    return async () => {
        try {
            const { token, userData } = userInfo;
            token && localStorage.setItem('token', token);
            userData && localStorage.setItem('userData', JSON.stringify(userData));
            if (userData.role === "customer") {
                await browserHistory.push('/user/order-management/list');
            } else if (userData.role === "admin" || userData.role === "employee") {
                await browserHistory.push('/admin/order-management/list');
            } else {
                await browserHistory.push('/');
            }
        } catch (e) {
            console.log(e);
        }
    }
}

export const resetPassword = (data) => {
    return async (dispatch) => {
        try {
            await dispatch(enableLoading());
            let res = await api.resetPassword(data) || {};
            if (res.data && res.data.status === "success") {
                const { data, message } = res.data;
                if (message) toast.success(message);
                await dispatch(loadingSuccess());
            } else {
                if (res.data && res.data.message) toast.error(res.data.message);
                await dispatch(loadingFailed());
            }
        } catch (e) {
            console.log(e);
            await dispatch(loadingFailed());
            toast.error(e.message);
        }
    }
}

export const changePassword = (data) => {
    return async (dispatch) => {
        try {
            await dispatch(enableLoading());
            let res = await api.changePassword(data) || {};
            if (res.data && res.data.status === "success") {
                const { data, message } = res.data;
                if (message) toast.success(message);
                await dispatch(loadingSuccess());
            } else {
                if (res.data && res.data.message) toast.error(res.data.message);
                await dispatch(loadingFailed());
            }
        } catch (e) {
            console.log(e);
            await dispatch(loadingFailed());
            toast.error(e.message);
        }
    }
}

export const offlineLoginSuccess = (userInfo) => {
    return {
        type: OFFLINE_LOGIN_SUCCESS,
        payload: userInfo,
    }
}

export const getUserInfo = (query) => {
    return async (dispatch) => {
        try {
            await dispatch(enableLoading());
            let res = await api.getUserInfo(query);
            if (res.data && res.data.status === "success") {
                const { data, message } = res.data;
                if (data) await dispatch(getUserInfoSuccess(data));
                await dispatch(loadingSuccess());
            } else {
                if (res.data && res.data.message) toast.error(res.data.message);
                await dispatch(loadingFailed());
            }
        } catch (e) {
            console.log(e);
            await dispatch(loadingFailed());
            toast.error(e.message);
        }
    }
}

export const getCustomerProfile = () => {
    return async (dispatch) => {
        try {
            await dispatch(enableLoading());
            let res = await api.getCurrentProfile();
            if (res.data && res.data.status === "success") {
                const { data, message } = res.data;
                if (data) await dispatch(getUserInfoSuccess(data));
                await dispatch(loadingSuccess());
            } else {
                if (res.data && res.data.message) toast.error(res.data.message);
                await dispatch(loadingFailed());
            }
        } catch (e) {
            console.log(e);
            await dispatch(loadingFailed());
            toast.error(e.message);
        }
    }
}

export const getUserInfoSuccess = (userData) => {
    return async (dispatch) => {
        try {
            userData && localStorage.setItem('userData', JSON.stringify(userData));
            await dispatch(updateLoggedUserInfo(userData));
        } catch (e) {
            console.log(e);
        }
    }
}

export const updateLoggedUserInfo = (userData) => {
    return {
        type: UPDATE_LOGGED_USER_INFO,
        payload: userData,
    }
}

export const userGetAllList = (query) => {
    return async (dispatch) => {
        try {
            await dispatch(enableLoading());
            let res = await api.userGetAllList(query);
            if (res.data && res.data.status === "success") {
                const { data, message } = res.data;
                if (data) await dispatch(userGetAllListSuccess(data));
                await dispatch(loadingSuccess());
            } else {
                if (res.data && res.data.message) toast.error(res.data.message);
                await dispatch(loadingFailed());
            }
        } catch (e) {
            console.log(e);
            await dispatch(loadingFailed());
            toast.error(e.message);
        }
    }
}

export const userGetAllListSuccess = (listData) => {
    return {
        type: USER_GET_LIST_SUCCESS,
        payload: listData,
    }
}

export const logout = () => {
    return async (dispatch) => {
        try {
            localStorage.clear();
            browserHistory.push('/auth/login');
        } catch (e) {
            console.log(e);
            await dispatch(loadingFailed());
            toast.error(e.message);
        }
    }
}

export const register = (query) => {
    return async (dispatch) => {
        try {
            await dispatch(enableLoading());
            let res = await api.register(query);
            if (res.data && res.data.status === "success") {
                const { data, message } = res.data;
                if (data) toast.success('Your request is succeed. Please check your email to activate your account.')
                await dispatch(loadingSuccess());
            } else {
                if (res.data && res.data.message) toast.error(res.data.message);
                await dispatch(loadingFailed());
            }
        } catch (e) {
            console.log(e);
            await dispatch(loadingFailed());
            toast.error(e.message);
        }
    }
}

export const invite = (query) => {
    return async (dispatch) => {
        try {
            await dispatch(enableLoading());
            let res = await api.invite(query);
            if (res.data && res.data.status === "success") {
                const { data, message } = res.data;
                if (data) toast.success('Your request is succeed. Please check email to activate account.')
                await dispatch(loadingSuccess());
                return browserHistory.push('/admin/user-management/list')
            } else {
                if (res.data && res.data.message) toast.error(res.data.message);
                await dispatch(loadingFailed());
            }
        } catch (e) {
            console.log(e);
            await dispatch(loadingFailed());
            toast.error(e.message);
        }
    }
}

export const deleteUser = (query) => {
    return async (dispatch) => {
        try {
            await dispatch(enableLoading());
            let res = await api.deleteUser(query);
            if (res.data && res.data.status === "success") {
                const { data, message } = res.data;
                if (data) toast.success('User deleted successfully.')
                await dispatch(loadingSuccess());
                return browserHistory.push('/admin/user-management/list')
            } else {
                if (res.data && res.data.message) toast.error(res.data.message);
                await dispatch(loadingFailed());
            }
        } catch (e) {
            console.log(e);
            await dispatch(loadingFailed());
            toast.error(e.message);
        }
    }
}

export const getUserProfile = (query) => {
    return async (dispatch) => {
        try {
            await dispatch(enableLoading());
            let res = await api.getUserProfile(query);
            if (res.data && res.data.status === "success") {
                const { data, message } = res.data;
                await dispatch(loadingSuccess());
                if (data) return data;
            } else {
                if (res.data && res.data.message) toast.error(res.data.message);
                await dispatch(loadingFailed());
            }
        } catch (e) {
            console.log(e);
            await dispatch(loadingFailed());
            toast.error(e.message);
        }
    }
}

export const getCurrentProfile = () => {
    return async (dispatch) => {
        try {
            await dispatch(enableLoading());
            let res = await api.getCurrentProfile();
            if (res.data && res.data.status === "success") {
                const { data, message } = res.data;
                await dispatch(loadingSuccess());
                if (data) return data;
            } else {
                if (res.data && res.data.message) toast.error(res.data.message);
                await dispatch(loadingFailed());
            }
        } catch (e) {
            console.log(e);
            await dispatch(loadingFailed());
            toast.error(e.message);
        }
    }
}

export const updateUserProfile = (query) => {
    return async (dispatch) => {
        try {
            await dispatch(enableLoading());
            let res = await api.updateUserProfile(query);
            if (res.data && res.data.status === "success") {
                const { data, message } = res.data;
                if (data) toast.success('User updated successfully.')
                await dispatch(loadingSuccess());
                return browserHistory.push('/admin/user-management/list')
            } else {
                if (res.data && res.data.message) toast.error(res.data.message);
                await dispatch(loadingFailed());
            }
        } catch (e) {
            console.log(e);
            await dispatch(loadingFailed());
            toast.error(e.message);
        }
    }
}

export const updateCurrentProfile = (query) => {
    return async (dispatch) => {
        try {
            await dispatch(enableLoading());
            let res = await api.updateCurrentProfile(query);
            if (res.data && res.data.status === "success") {
                const { data } = res.data;
                if (data) toast.success('User updated successfully.');
                await dispatch(loadingSuccess());
            } else {
                if (res.data && res.data.message) toast.error(res.data.message);
                await dispatch(loadingFailed());
            }
        } catch (e) {
            console.log(e);
            await dispatch(loadingFailed());
            toast.error(e.message);
        }
    }
}

export const activate = (query) => {
    return async (dispatch) => {
        try {
            await dispatch(enableLoading());
            let res = await api.activate(query);
            if (res.data && res.data.status === "success") {
                const { data, message } = res.data;
                if (data) toast.success('User activated successfully.')
                await dispatch(loadingSuccess());
                //return browserHistory.push('/')
                /**
                 * @todo: redirect user 
                 * to the right page by permissions
                 */
            } else {
                if (res.data && res.data.message) toast.error(res.data.message);
                await dispatch(loadingFailed());
            }
        } catch (e) {
            console.log(e);
            await dispatch(loadingFailed());
            toast.error(e.message);
        }
    }
}
import React from "react";
import { browserHistory } from "react-router";
import {
  Paper,
  Typography,
  Grid,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import { MaterialTable } from "../common/Table";
import moment from "moment";
import { checkPermissionAdmin } from "../../utils/helper";

class ExchangeRateList extends React.Component {
  constructor(props) {
    super(props);

    this.renderActions = this.renderActions.bind(this);
    this.handleOpenConfirm = this.handleOpenConfirm.bind(this);
    this.handleCloseConfirm = this.handleCloseConfirm.bind(this);
    this.handleActionConfirm = this.handleActionConfirm.bind(this);

    this.state = {
      openConfirm: false,
      selectedItem: null
    };
  }

  componentDidMount() {
    this.props.fetchAllExchange({
      limit: 10,
      page: 1,
      orderBy: "id",
      orderDir: "asc",
      textSearch: ""
    });
  }

  handleOpenConfirm(index) {
    this.state.openConfirm = true;
    this.state.selectedItem = index;
    this.setState({ ...this.state });
  }

  handleCloseConfirm() {
    this.state.openConfirm = false;
    this.setState({ ...this.state });
  }

  async handleActionConfirm() {
    if (this.state.selectedItem) {
      await this.props.deleteExchange({
        exchange_id: this.state.selectedItem.id
      });
    }
    await this.props.fetchAllExchange({
      limit: 10,
      page: 1,
      orderBy: "id",
      orderDir: "asc",
      textSearch: ""
    });
    await this.setState({ selectedItem: null });
    await this.handleCloseConfirm();
  }

  renderTime(row) {
    return moment(row.createdAt).format("DD-MM-YY HH:mm:SS");
  }

  renderActions(row) {
    return (
      <div>
        <IconButton
          variant="contained"
          size="small"
          color="primary"
          className="small-btn"
          onClick={() =>
            browserHistory.push(`/admin/exchange-management/update/${row.id}`)
          }
          disabled={!checkPermissionAdmin()}
        >
          <Edit />
        </IconButton>
        <IconButton
          variant="contained"
          size="small"
          color="secondary"
          className="small-btn"
          onClick={() => this.handleOpenConfirm(row)}
          disabled={!checkPermissionAdmin()}
        >
          <Delete />
        </IconButton>
      </div>
    );
  }

  render() {
    const { exchange } = this.props;

    const EXCHANGE_PAGE_COLUMNS = [
      { title: "#", sortable: false, data_field: "index" },
      { title: "Exchange Unit", sortable: false, data_field: "exchange_unit" },
      {
        title: "Exchange Value",
        sortable: false,
        data_field: "exchange_value"
      },
      {
        title: "Created at",
        sortable: false,
        data_field: null,
        render: this.renderTime
      },
      {
        title: "Actions",
        sortable: false,
        data_field: null,
        render: this.renderActions
      }
    ];

    return (
      <Paper elevation={1} square className={"container"}>
        <Typography variant="h5" component="h4" className={"mc_page_title"}>
          Exchange Rate Management
        </Typography>
        <MaterialTable
          columns={EXCHANGE_PAGE_COLUMNS}
          rows={exchange.list}
          totals={exchange.totals}
          getList={this.props.fetchAllExchange}
          addNewLink={"/admin/exchange-management/create"}
        />
        {/* CONFIRMATION DIALOG */}
        <Dialog
          open={this.state.openConfirm}
          onClose={this.handleCloseConfirm}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">{"Warning!"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this exchange?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseConfirm} color="primary">
              Cancel
            </Button>
            <Button
              onClick={this.handleActionConfirm}
              color="primary"
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    );
  }
}

export default ExchangeRateList;

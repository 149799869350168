import Immutable from 'seamless-immutable';
import {
    ENABLE_LOADING, LOADING_FAILED, LOADING_SUCCESS, TOGGLE_SIDEBAR,
} from '../actions/utils';

const INIT_STATE = Immutable({
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailed: false,
    isShowSidebar: false,
});

export default function (state = INIT_STATE, action) {
    switch (action.type) {
        case ENABLE_LOADING:
            return Object.assign({}, state, {isLoading: true});
        case LOADING_SUCCESS:
            return Object.assign({}, state, {isLoading: false, isLoadingSuccess: true, isLoadingFailed: false});
        case LOADING_FAILED:
            return Object.assign({}, state, {isLoading: false, isLoadingSuccess: false, isLoadingFailed: true});
        case TOGGLE_SIDEBAR:
            return Object.assign({}, state, {isShowSidebar: action.payload});
        default:
            return state;
    }
}

import api from '../utils/api';
import {toast} from 'react-toastify';
import {enableLoading, loadingSuccess, loadingFailed} from '../actions/utils';

export const GET_SIGNED_URL_SUCCESS = `GET_SIGNED_URL_SUCCESS`;

export const getSignedUrl = (query) => {
    return async (dispatch) => {
        try {
            await dispatch(enableLoading());
            let res = await api.getSignedUrl(query);
            if (res.data && res.data.status === "success") {
                const {data, message} = res.data;
                if (message) toast.success(message);
                await dispatch(loadingSuccess());
                await dispatch(getSignedUrlSuccess(data));
                return data;
            } else {
                if (res.data && res.data.message) toast.error(res.data.message);
                await dispatch(loadingFailed());
            }
        } catch (e) {
            console.log(e)
        }
    }
}

export const getSignedUrlSuccess = (data) => {
    return {
        type: GET_SIGNED_URL_SUCCESS,
        payload: data,
    }
}

export const uploadFileToSignedUrl = (query) => {
    return async (dispatch) => {
        try {
            await dispatch(enableLoading());
            let res = await api.uploadFileToSignedUrl(query);
            if (res.data && res.data.status === "success") {
                const {data, message} = res.data;
                if (message) toast.success(message);
                await dispatch(loadingSuccess());
                return data;
            } else {
                if (res.data && res.data.message) toast.error(res.data.message);
                await dispatch(loadingFailed());
            }
        } catch (e) {
            console.log(e)
        }
    }
}
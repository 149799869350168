import React from 'react';
import moment from 'moment';

const Catalogue = (props) => {
    const {width, height, className, anchorId, orderData} = props;
    let {items, userData} = orderData;
    const itemAsOrder = items[Object.keys(items)[0]];

    let itemsX = [];
    let id, index = 0, items_total_price = 0;
    for (id in items) {
        index++;
        items_total_price += +items[id].item_total_price;
        itemsX.push(
            <tr style={{height: '39px'}} key={index}>
                <td style={{borderRightColor: 'rgb(0, 0, 0)', borderBottomColor: 'rgb(0, 0, 0)', borderLeftColor: 'rgb(0, 0, 0)', overflow: 'hidden', padding: '0px 3px', verticalAlign: 'middle', fontFamily: '"Times New Roman"', fontSize: '8pt', overflowWrap: 'break-word', textAlign: 'center'}}>{index}</td>
                <td colSpan={2} data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;\bMạch A &quot;}" rowSpan={1} style={{borderRightColor: 'rgb(0, 0, 0)', borderBottomColor: 'rgb(0, 0, 0)', overflow: 'hidden', padding: '0px 3px', verticalAlign: 'middle', fontFamily: '"Times New Roman"', fontSize: '8pt', overflowWrap: 'break-word', textAlign: 'center'}}>{items[id].item_name}</td>
                <td colSpan={6} data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Mạch 1 lớp &quot;}" rowSpan={1} style={{borderRightColor: 'rgb(0, 0, 0)', borderBottomColor: 'rgb(0, 0, 0)', overflow: 'hidden', padding: '0px 3px', verticalAlign: 'middle', fontFamily: '"Times New Roman"', fontSize: '8pt', overflowWrap: 'break-word', textAlign: 'center'}}>{items[id].item_description}</td>
                <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;cái&quot;}" style={{borderRightColor: 'rgb(0, 0, 0)', borderBottomColor: 'rgb(0, 0, 0)', overflow: 'hidden', padding: '0px 3px', verticalAlign: 'middle', fontFamily: '"Times New Roman"', fontSize: '8pt', overflowWrap: 'break-word', textAlign: 'center'}}>cái</td>
                <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;2 tuần&quot;}" style={{borderRightColor: 'rgb(0, 0, 0)', borderBottomColor: 'rgb(0, 0, 0)', overflow: 'hidden', padding: '0px 3px', verticalAlign: 'middle', fontFamily: '"Times New Roman"', fontSize: '8pt', overflowWrap: 'break-word', textAlign: 'center'}}>2 tuần</td>
                <td data-sheets-value="{&quot;1&quot;:3,&quot;3&quot;:1000}" style={{borderRightColor: 'rgb(0, 0, 0)', borderBottomColor: 'rgb(0, 0, 0)', overflow: 'hidden', padding: '0px 3px', verticalAlign: 'middle', backgroundColor: 'rgb(255, 255, 255)', fontFamily: '"Times New Roman"', fontSize: '8pt', overflowWrap: 'break-word', textAlign: 'center'}}>{items[id].item_amount}</td>
                <td data-sheets-numberformat="[null,2,&quot;_-* #,##0\\ _D_M_-;\\-* #,##0\\ _D_M_-;_-* \&quot;-\&quot;??\\ _D_M_-;_-@&quot;,1]" data-sheets-value="{&quot;1&quot;:3,&quot;3&quot;:29545}" style={{borderRightColor: 'rgb(0, 0, 0)', borderBottomColor: 'rgb(0, 0, 0)', overflow: 'hidden', padding: '0px 3px', verticalAlign: 'middle', fontFamily: '"Times New Roman"', fontSize: '8pt', overflowWrap: 'break-word', textAlign: 'right'}}>{Math.round(items[id].item_total_price / items[id].item_amount)}</td>
                <td data-sheets-formula="=R[0]C[-7]*R[0]C[-8]" data-sheets-numberformat="[null,2,&quot;_-* #,##0\\ _D_M_-;\\-* #,##0\\ _D_M_-;_-* \&quot;-\&quot;??\\ _D_M_-;_-@&quot;,1]" data-sheets-value="{&quot;1&quot;:3,&quot;3&quot;:0}" style={{borderRightColor: 'rgb(0, 0, 0)', borderBottomColor: 'rgb(0, 0, 0)', overflow: 'hidden', padding: '0px 3px', verticalAlign: 'middle', backgroundColor: 'rgb(255, 255, 255)', fontFamily: '"Times New Roman"', fontSize: '8pt', overflowWrap: 'break-word', textAlign: 'right'}}>{items[id].item_total_price}</td>
            </tr>   
        )
    }

    return (
        <div id={anchorId} style={{width: `${width}`, height: `${height}`}}>
            <title>BÁO GIÁ</title>
            <table border={1} cellPadding={0} cellSpacing={0} dir="ltr" style={{tableLayout: 'fixed', fontSize: '11pt', fontFamily: 'Calibri', width: '0px', borderCollapse: 'collapse', border: 'none'}} xmlns="http://www.w3.org/1999/xhtml"><colgroup />
            <colgroup><col width={49} />
                <col width={112} />
                <col width={76} />
                <col width={60} />
                <col width={60} />
                <col width={60} />
                <col width={50} />
                <col width={55} />
                <col width={50} />
                <col width={60} />
                <col width={60} />
                <col width={46} />
                <col width={70} />
                <col width={74} /></colgroup><tbody>
                <tr style={{height: '20px'}}>
                <td colSpan={7} data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ESK VIỆT NAM&quot;}" rowSpan={1} style={{overflow: 'hidden', padding: '0px 3px 0px 3px', verticalAlign: 'middle', fontFamily: 'Times New Roman', fontSize: '18pt', fontWeight: 'bold', color: '#ff0000', textAlign: 'center'}}>ESK VIỆT NAM</td>
                <td colSpan={7} data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;CÔNG TY CỔ PHẦN SẢN XUẤT VÀ THƯƠNG MẠI ESK VIỆT NAM&quot;}" rowSpan={1} style={{overflow: 'hidden', padding: '0px 3px', verticalAlign: 'middle', fontFamily: '"Times New Roman"', textAlign: 'center'}}>CÔNG TY CỔ PHẦN SẢN XUẤT VÀ THƯƠNG MẠI ESK VIỆT NAM</td>
                </tr>
                <tr style={{height: '20px'}}>
                <td colSpan={7} data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Chuyên cung cấp linh kiện điện tử, mạch in, gia công SMT,….&quot;}" rowSpan={1} style={{overflow: 'hidden', padding: '0px 3px', verticalAlign: 'bottom', fontFamily: '"Times New Roman"', fontSize: '8pt'}}>Chuyên cung cấp linh kiện điện tử, mạch in, gia công SMT,….</td>
                <td style={{overflow: 'hidden', padding: '0px 3px 0px 3px', verticalAlign: 'bottom'}}>&nbsp;</td>
                <td style={{overflow: 'hidden', padding: '0px 3px 0px 3px', verticalAlign: 'bottom'}}>&nbsp;</td>
                <td style={{overflow: 'hidden', padding: '0px 3px 0px 3px', verticalAlign: 'bottom'}}>&nbsp;</td>
                <td style={{overflow: 'hidden', padding: '0px 3px 0px 3px', verticalAlign: 'bottom'}}>&nbsp;</td>
                <td style={{overflow: 'hidden', padding: '0px 3px 0px 3px', verticalAlign: 'bottom'}}>&nbsp;</td>
                <td style={{overflow: 'hidden', padding: '0px 3px 0px 3px', verticalAlign: 'bottom'}}>&nbsp;</td>
                <td style={{overflow: 'hidden', padding: '0px 3px 0px 3px', verticalAlign: 'bottom'}}>&nbsp;</td>
                </tr>
                <tr style={{height: '20px'}}>
                <td colSpan={7} data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;CÔNG TY CỔ PHẦN SẢN XUẤT VÀ THƯƠNG MẠI ESK VIỆT NAM&quot;}" rowSpan={1} style={{overflow: 'hidden', padding: '0px 3px', verticalAlign: 'bottom', fontFamily: '"Times New Roman"', fontSize: '8pt'}}>CÔNG TY CỔ PHẦN SẢN XUẤT VÀ THƯƠNG MẠI ESK VIỆT NAM</td>
                <td style={{overflow: 'hidden', padding: '0px 3px 0px 3px', verticalAlign: 'bottom'}}>&nbsp;</td>
                <td style={{overflow: 'hidden', padding: '0px 3px 0px 3px', verticalAlign: 'bottom'}}>&nbsp;</td>
                <td style={{overflow: 'hidden', padding: '0px 3px 0px 3px', verticalAlign: 'bottom'}}>&nbsp;</td>
                <td style={{overflow: 'hidden', padding: '0px 3px 0px 3px', verticalAlign: 'bottom'}}>&nbsp;</td>
                <td style={{overflow: 'hidden', padding: '0px 3px 0px 3px', verticalAlign: 'bottom'}}>&nbsp;</td>
                <td style={{overflow: 'hidden', padding: '0px 3px 0px 3px', verticalAlign: 'bottom'}}>&nbsp;</td>
                <td style={{overflow: 'hidden', padding: '0px 3px 0px 3px', verticalAlign: 'bottom'}}>&nbsp;</td>
                </tr>
                <tr style={{height: '20px'}}>
                <td colSpan={7} data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Add.: 105 Hoàng Văn Thái, Thanh Xuân, Hà Nội&quot;}" rowSpan={1} style={{overflow: 'hidden', padding: '0px 3px', verticalAlign: 'bottom', fontFamily: '"Times New Roman"', fontSize: '8pt'}}>Add.: 105 Hoàng Văn Thái, Thanh Xuân, Hà Nội</td>
                <td colSpan={7} data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Sản phẩm: Linh kiện điện tử chính hãng Digikey, Mouser, Arrow, china,..., mạch in 1-44 lớp, Gia công SMT hoàn thiện sản phẩm,...\n\nDịch vụ: Thiết kế, chế tạo, lắp đặt, sửa chữa, tích hợp hệ thống\n\nLinh kiện chính hãng: Microchip, Texas instrument, NXP, Atmel,...&quot;}" rowSpan={6} style={{overflow: 'hidden', padding: '0px 3px', verticalAlign: 'top', fontFamily: '"Times New Roman"', fontSize: '8pt', overflowWrap: 'break-word'}}>
                    <div style={{maxHeight: '120px'}}>Sản phẩm: Linh kiện điện tử chính hãng Digikey, Mouser, Arrow, china,..., mạch in 1-44 lớp, Gia công SMT hoàn thiện sản phẩm,...
                    <br />
                    <br />
                    Dịch vụ: Thiết kế, chế tạo, lắp đặt, sửa chữa, tích hợp hệ thống
                    <br />
                    <br />
                    Linh kiện chính hãng: Microchip, Texas instrument, NXP, Atmel,...
                    </div>
                </td>
                </tr>
                <tr style={{height: '20px'}}>
                <td colSpan={7} data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Tel: +844 3566 7851&quot;}" rowSpan={1} style={{overflow: 'hidden', padding: '0px 3px', verticalAlign: 'bottom', fontFamily: '"Times New Roman"', fontSize: '8pt'}}>Tel: +844 3566 7851</td>
                </tr>
                <tr style={{height: '20px'}}>
                <td colSpan={7} data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Fax: +844 3566 7852&quot;}" rowSpan={1} style={{overflow: 'hidden', padding: '0px 3px', verticalAlign: 'bottom', fontFamily: '"Times New Roman"', fontSize: '8pt'}}>Fax: +844 3566 7852</td>
                </tr>
                <tr style={{height: '20px'}}>
                <td colSpan={7} data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;E-mail: pnlabvietnam@gmail.com&quot;}" rowSpan={1} style={{overflow: 'hidden', padding: '0px 3px', verticalAlign: 'bottom', fontFamily: '"Times New Roman"', fontSize: '8pt'}}>E-mail: pnlabvietnam@gmail.com</td>
                </tr>
                <tr style={{height: '20px'}}>
                <td colSpan={7} data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Website: http://www.pnlabvn.com&quot;}" rowSpan={1} style={{overflow: 'hidden', padding: '0px 3px', verticalAlign: 'bottom', fontFamily: '"Times New Roman"', fontSize: '8pt'}}>Website: http://www.pnlabvn.com</td>
                </tr>
                <tr style={{height: '20px'}}>
                <td colSpan={7} data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Hotline: 0989537500&quot;}" rowSpan={1} style={{overflow: 'hidden', padding: '0px 3px', verticalAlign: 'bottom', fontFamily: '"Times New Roman"', fontSize: '8pt'}}>Hotline: 0989537500</td>
                </tr>
                <tr style={{height: '20px'}}>
                <td style={{overflow: 'hidden', padding: '0px 3px 0px 3px', verticalAlign: 'bottom'}}>&nbsp;</td>
                <td style={{overflow: 'hidden', padding: '0px 3px 0px 3px', verticalAlign: 'bottom'}}>&nbsp;</td>
                <td style={{overflow: 'hidden', padding: '0px 3px 0px 3px', verticalAlign: 'bottom'}}>&nbsp;</td>
                <td style={{overflow: 'hidden', padding: '0px 3px 0px 3px', verticalAlign: 'bottom'}}>&nbsp;</td>
                <td style={{overflow: 'hidden', padding: '0px 3px 0px 3px', verticalAlign: 'bottom'}}>&nbsp;</td>
                <td style={{overflow: 'hidden', padding: '0px 3px 0px 3px', verticalAlign: 'bottom'}}>&nbsp;</td>
                <td style={{overflow: 'hidden', padding: '0px 3px 0px 3px', verticalAlign: 'bottom'}}>&nbsp;</td>
                <td style={{overflow: 'hidden', padding: '0px 3px 0px 3px', verticalAlign: 'bottom'}}>&nbsp;</td>
                <td style={{overflow: 'hidden', padding: '0px 3px 0px 3px', verticalAlign: 'bottom'}}>&nbsp;</td>
                <td style={{overflow: 'hidden', padding: '0px 3px 0px 3px', verticalAlign: 'bottom'}}>&nbsp;</td>
                <td style={{overflow: 'hidden', padding: '0px 3px 0px 3px', verticalAlign: 'bottom'}}>&nbsp;</td>
                <td style={{overflow: 'hidden', padding: '0px 3px 0px 3px', verticalAlign: 'bottom'}}>&nbsp;</td>
                <td style={{overflow: 'hidden', padding: '0px 3px 0px 3px', verticalAlign: 'bottom'}}>&nbsp;</td>
                <td style={{overflow: 'hidden', padding: '0px 3px 0px 3px', verticalAlign: 'bottom'}}>&nbsp;</td>
                </tr>
                <tr style={{height: '20px'}}>
                    <td colSpan={14} data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Báo Giá Sản Phẩm&quot;}" rowSpan={1} style={{overflow: 'hidden', padding: '0px 3px', verticalAlign: 'middle', fontFamily: 'Arial', fontSize: '14pt', fontWeight: 'bold', overflowWrap: 'break-word', textAlign: 'center'}}>
                        <div style={{maxHeight: '40px'}}>Báo Giá Sản Phẩm</div>
                    </td>
                </tr>
                <tr style={{height: '20px'}}>
                <td colSpan={9} data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;order ID &quot;}" rowSpan={2} style={{overflow: 'hidden', padding: '0px 3px', verticalAlign: 'middle', fontFamily: 'Arial', fontSize: '14pt', fontWeight: 'bold', overflowWrap: 'break-word', textAlign: 'center'}}>
                    <div style={{maxHeight: '40px', fontSize: '10px', textAlign:'right'}}>Order ID</div>
                </td>
                <td colSpan={5} data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;PN-123&quot;}" rowSpan={2} style={{overflow: 'hidden', padding: '0px 3px', verticalAlign: 'middle', fontFamily: 'Arial', fontSize: '14pt', fontWeight: 'bold', overflowWrap: 'break-word', textAlign: 'center'}}>
                    <div style={{maxHeight: '40px', fontSize: '10px', textAlign:'right'}}>{itemAsOrder.order_code}</div>
                </td>
                </tr>
                <tr style={{height: '20px'}} />
                <tr style={{height: '20px'}}>
                <td colSpan={11} data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Hà nội, ngày&quot;}" rowSpan={1} style={{overflow: 'hidden', padding: '0px 3px', verticalAlign: 'middle', fontFamily: 'Arial', fontSize: '8pt', fontStyle: 'italic', overflowWrap: 'break-word', textAlign: 'right'}}>Hà nội, ngày {new moment().format('DD/MM/Y')}</td>
                <td colSpan={3} data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ngày báo giá &quot;}" rowSpan={1} style={{overflow: 'hidden', padding: '0px 3px', verticalAlign: 'middle', fontFamily: 'Arial', fontSize: '8pt', fontStyle: 'italic', overflowWrap: 'break-word', textAlign: 'center'}}>ngày báo giá {new moment().format('DD/MM/Y')}</td>
                </tr>
                <tr style={{height: '20px'}}>
                <td colSpan={14} data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Kính gửi:&quot;}" rowSpan={1} style={{overflow: 'hidden', padding: '0px 3px', verticalAlign: 'middle', fontFamily: 'Arial', fontSize: '8pt', overflowWrap: 'break-word'}}>Kính gửi: {userData.first_name + " " + userData.last_name}</td>
                </tr>
                <tr style={{height: '20px'}}>
                <td colSpan={10} data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Địa chỉ liên Hệ :&quot;}" rowSpan={1} style={{overflow: 'hidden', padding: '0px 3px', verticalAlign: 'middle', fontFamily: 'Arial', fontSize: '8pt', overflowWrap: 'break-word'}}>Địa chỉ liên Hệ : {userData.address_1 || userData.address_2 || userData.address_3}</td>
                <td colSpan={2} data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Điện thoại : &quot;}" rowSpan={1} style={{overflow: 'hidden', padding: '0px 3px', verticalAlign: 'bottom', fontSize: '8pt', textAlign: 'center'}}>Điện thoại : {userData.phone}</td>
                <td colSpan={2} rowSpan={1} style={{overflow: 'hidden', padding: '0px 3px', verticalAlign: 'bottom', fontSize: '8pt', textAlign: 'center'}}>&nbsp;</td>
                </tr>
                <tr style={{height: '32px'}}>
                <td colSpan={14} data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;ESK Chuyên cung cấp linh kiện điện tử, mạch in, gia công SMT,….. Chúng tôi cung cấp dịch đặt hàng nhanh 3-5 ngày, 1-2 tuần, vui lòng liên hệ với chúng tôi để biết thêm thông tin.&quot;}" rowSpan={1} style={{overflow: 'hidden', padding: '0px 3px', verticalAlign: 'middle', fontFamily: 'Arial', fontSize: '8pt', overflowWrap: 'break-word'}}>
                    <span style={{fontSize: '8pt'}}>ESK Chuyên cung cấp linh kiện điện tử, mạch in, gia công SMT,….. Chúng tôi cung cấp dịch </span>
                    <span style={{fontSize: '8pt', fontWeight: 'bold', color: 'rgb(255, 0, 0)'}}>đặt hàng nhanh 3-5 ngày, 1-2 tuần,</span>
                    <span style={{fontSize: '8pt'}}> vui lòng liên hệ với chúng tôi để biết thêm thông tin.</span>
                </td>
                </tr>
                <tr style={{height: '20px'}}>
                <td style={{borderBottomColor: 'rgb(0, 0, 0)', overflow: 'hidden', padding: '0px 3px', verticalAlign: 'middle'}}>&nbsp;</td>
                <td style={{borderBottomColor: 'rgb(0, 0, 0)', overflow: 'hidden', padding: '0px 3px', verticalAlign: 'middle'}}>&nbsp;</td>
                <td style={{borderBottomColor: 'rgb(0, 0, 0)', overflow: 'hidden', padding: '0px 3px', verticalAlign: 'middle'}}>&nbsp;</td>
                <td style={{borderBottomColor: 'rgb(0, 0, 0)', overflow: 'hidden', padding: '0px 3px', verticalAlign: 'middle'}}>&nbsp;</td>
                <td style={{borderBottomColor: 'rgb(0, 0, 0)', overflow: 'hidden', padding: '0px 3px', verticalAlign: 'middle'}}>&nbsp;</td>
                <td style={{borderBottomColor: 'rgb(0, 0, 0)', overflow: 'hidden', padding: '0px 3px', verticalAlign: 'middle'}}>&nbsp;</td>
                <td data-sheets-numberformat="[null,2,&quot;_(* #,##0.00_);_(* \\(#,##0.00\\);_(* \&quot;-\&quot;??_);_(@_)&quot;,1]" style={{borderBottomColor: 'rgb(0, 0, 0)', overflow: 'hidden', padding: '0px 3px', verticalAlign: 'middle'}}>&nbsp;</td>
                <td data-sheets-numberformat="[null,2,&quot;_(* #,##0.00_);_(* \\(#,##0.00\\);_(* \&quot;-\&quot;??_);_(@_)&quot;,1]" style={{borderBottomColor: 'rgb(0, 0, 0)', overflow: 'hidden', padding: '0px 3px', verticalAlign: 'middle'}}>&nbsp;</td>
                <td style={{borderBottomColor: 'rgb(0, 0, 0)', overflow: 'hidden', padding: '0px 3px', verticalAlign: 'bottom'}}>&nbsp;</td>
                <td style={{borderBottomColor: 'rgb(0, 0, 0)', overflow: 'hidden', padding: '0px 3px', verticalAlign: 'bottom'}}>&nbsp;</td>
                <td style={{borderBottomColor: 'rgb(0, 0, 0)', overflow: 'hidden', padding: '0px 3px', verticalAlign: 'bottom'}}>&nbsp;</td>
                <td style={{borderBottomColor: 'rgb(0, 0, 0)', overflow: 'hidden', padding: '0px 3px', verticalAlign: 'bottom'}}>&nbsp;</td>
                <td style={{borderBottomColor: 'rgb(0, 0, 0)', overflow: 'hidden', padding: '0px 3px', verticalAlign: 'bottom'}}>&nbsp;</td>
                <td data-sheets-numberformat="[null,2,&quot;_(* #,##0.00_);_(* \\(#,##0.00\\);_(* \&quot;-\&quot;??_);_(@_)&quot;,1]" data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;VNĐ&quot;}" style={{borderBottomColor: 'rgb(0, 0, 0)', overflow: 'hidden', padding: '0px 3px', verticalAlign: 'middle', fontFamily: 'Arial', fontSize: '8pt', fontWeight: 'bold', overflowWrap: 'break-word', textAlign: 'right'}}>VNĐ</td>
                </tr>
                <tr style={{height: '20px'}}>
                <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;STT&quot;}" style={{borderRightColor: 'rgb(0, 0, 0)', borderBottomColor: 'rgb(0, 0, 0)', borderLeftColor: 'rgb(0, 0, 0)', overflow: 'hidden', padding: '0px 3px', verticalAlign: 'middle', backgroundColor: 'rgb(192, 192, 192)', fontFamily: '"Times New Roman"', fontSize: '8pt', fontWeight: 'bold', overflowWrap: 'break-word', color: 'rgb(51, 51, 51)', textAlign: 'center'}}>STT</td>
                <td colSpan={2} data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Tên sản phẩm &quot;}" rowSpan={1} style={{borderRightColor: 'rgb(0, 0, 0)', borderBottomColor: 'rgb(0, 0, 0)', overflow: 'hidden', padding: '0px 3px', verticalAlign: 'middle', backgroundColor: 'rgb(192, 192, 192)', fontFamily: '"Times New Roman"', fontSize: '8pt', fontWeight: 'bold', overflowWrap: 'break-word', color: 'rgb(51, 51, 51)', textAlign: 'center'}}>Tên sản phẩm</td>
                <td colSpan={6} data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Mô tả sản phẩm&quot;}" rowSpan={1} style={{borderRightColor: 'rgb(0, 0, 0)', borderBottomColor: 'rgb(0, 0, 0)', overflow: 'hidden', padding: '0px 3px', verticalAlign: 'middle', backgroundColor: 'rgb(192, 192, 192)', fontFamily: '"Times New Roman"', fontSize: '8pt', fontWeight: 'bold', overflowWrap: 'break-word', color: 'rgb(51, 51, 51)', textAlign: 'center'}}>Mô tả sản phẩm</td>
                <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;đơn vị tính&quot;}" style={{borderRightColor: 'rgb(0, 0, 0)', borderBottomColor: 'rgb(0, 0, 0)', overflow: 'hidden', padding: '0px 3px', verticalAlign: 'middle', backgroundColor: 'rgb(192, 192, 192)', fontFamily: '"Times New Roman"', fontSize: '8pt', fontWeight: 'bold', overflowWrap: 'break-word', color: 'rgb(51, 51, 51)', textAlign: 'center'}}>đơn vị tính</td>
                <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Giao hàng&quot;}" style={{borderRightColor: 'rgb(0, 0, 0)', borderBottomColor: 'rgb(0, 0, 0)', overflow: 'hidden', padding: '0px 3px', verticalAlign: 'middle', backgroundColor: 'rgb(192, 192, 192)', fontFamily: '"Times New Roman"', fontSize: '8pt', fontWeight: 'bold', overflowWrap: 'break-word', color: 'rgb(51, 51, 51)', textAlign: 'center'}}>Giao hàng</td>
                <td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;S. Lg&quot;}" style={{borderRightColor: 'rgb(0, 0, 0)', borderBottomColor: 'rgb(0, 0, 0)', overflow: 'hidden', padding: '0px 3px', verticalAlign: 'middle', backgroundColor: 'rgb(192, 192, 192)', fontFamily: '"Times New Roman"', fontSize: '8pt', fontWeight: 'bold', overflowWrap: 'break-word', color: 'rgb(51, 51, 51)', textAlign: 'center'}}>S. Lg</td>
                <td data-sheets-numberformat="[null,2,&quot;_(* #,##0.00_);_(* \\(#,##0.00\\);_(* \&quot;-\&quot;??_);_(@_)&quot;,1]" data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Đơn giá&quot;}" style={{borderRightColor: 'rgb(0, 0, 0)', borderBottomColor: 'rgb(0, 0, 0)', overflow: 'hidden', padding: '0px 3px', verticalAlign: 'middle', backgroundColor: 'rgb(192, 192, 192)', fontFamily: '"Times New Roman"', fontSize: '8pt', fontWeight: 'bold', overflowWrap: 'break-word', color: 'rgb(51, 51, 51)', textAlign: 'center'}}>Đơn giá</td>
                <td data-sheets-numberformat="[null,2,&quot;_(* #,##0.00_);_(* \\(#,##0.00\\);_(* \&quot;-\&quot;??_);_(@_)&quot;,1]" data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Thành tiền&quot;}" style={{borderRightColor: 'rgb(0, 0, 0)', borderBottomColor: 'rgb(0, 0, 0)', overflow: 'hidden', padding: '0px 3px', verticalAlign: 'middle', backgroundColor: 'rgb(192, 192, 192)', fontFamily: '"Times New Roman"', fontSize: '8pt', fontWeight: 'bold', overflowWrap: 'break-word', color: 'rgb(51, 51, 51)', textAlign: 'center'}}>Thành tiền</td>
                </tr>
                {
                    itemsX
                }
                <tr style={{height: '20px'}}>
                <td colSpan={13} data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Tổng giá trị (chưa bao gồm thuế VAT 10%)&quot;}" rowSpan={1} style={{borderRightColor: 'rgb(0, 0, 0)', borderBottomColor: 'rgb(0, 0, 0)', borderLeftColor: 'rgb(0, 0, 0)', overflow: 'hidden', padding: '0px 3px', verticalAlign: 'middle', fontFamily: '"Times New Roman"', fontSize: '8pt', fontStyle: 'italic', overflowWrap: 'break-word', textAlign: 'right'}}>Tổng giá trị (chưa bao gồm thuế VAT 10%)</td>
                <td data-sheets-formula="=SUM(R[-1]C[0])" data-sheets-numberformat="[null,2,&quot;_-* #,##0\\ _D_M_-;\\-* #,##0\\ _D_M_-;_-* \&quot;-\&quot;??\\ _D_M_-;_-@&quot;,1]" data-sheets-value="{&quot;1&quot;:3,&quot;3&quot;:0}" style={{borderRightColor: 'rgb(0, 0, 0)', borderBottomColor: 'rgb(0, 0, 0)', overflow: 'hidden', padding: '0px 3px', verticalAlign: 'middle', fontFamily: '"Times New Roman"', fontSize: '8pt', fontWeight: 'bold', overflowWrap: 'break-word', textAlign: 'right'}}>{items_total_price}</td>
                </tr>
                <tr style={{height: '21px'}}>
                <td colSpan={13} data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Triết Khấu&quot;}" rowSpan={1} style={{borderRightColor: 'rgb(0, 0, 0)', borderBottomColor: 'rgb(0, 0, 0)', borderLeftColor: 'rgb(0, 0, 0)', overflow: 'hidden', padding: '0px 3px', verticalAlign: 'middle', fontFamily: '"Times New Roman"', fontSize: '8pt', fontStyle: 'italic', overflowWrap: 'break-word', textAlign: 'right'}}>Triết Khấu</td>
                <td data-sheets-numberformat="[null,2,&quot;_-* #,##0\\ _D_M_-;\\-* #,##0\\ _D_M_-;_-* \&quot;-\&quot;??\\ _D_M_-;_-@&quot;,1]" style={{borderRightColor: 'rgb(0, 0, 0)', borderBottomColor: 'rgb(0, 0, 0)', overflow: 'hidden', padding: '0px 3px', verticalAlign: 'middle', fontFamily: '"Times New Roman"', fontSize: '8pt', fontWeight: 'bold', overflowWrap: 'break-word', textAlign: 'right'}}>{Math.round(+userData.discount / 100 * items_total_price)}</td>
                </tr>
                <tr style={{height: '21px'}}>
                <td colSpan={13} data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Vat 10%&quot;}" rowSpan={1} style={{borderRightColor: 'rgb(0, 0, 0)', borderBottomColor: 'rgb(0, 0, 0)', borderLeftColor: 'rgb(0, 0, 0)', overflow: 'hidden', padding: '0px 3px', verticalAlign: 'middle', fontFamily: '"Times New Roman"', fontSize: '8pt', fontStyle: 'italic', overflowWrap: 'break-word', textAlign: 'right'}}></td>
                <td data-sheets-formula="=(R[-2]C[0]-R[-1]C[0])*0.1" data-sheets-numberformat="[null,2,&quot;_-* #,##0\\ _D_M_-;\\-* #,##0\\ _D_M_-;_-* \&quot;-\&quot;??\\ _D_M_-;_-@&quot;,1]" data-sheets-value="{&quot;1&quot;:3,&quot;3&quot;:0}" style={{borderRightColor: 'rgb(0, 0, 0)', borderBottomColor: 'rgb(0, 0, 0)', overflow: 'hidden', padding: '0px 3px', verticalAlign: 'middle', fontFamily: '"Times New Roman"', fontSize: '8pt', fontWeight: 'bold', overflowWrap: 'break-word', textAlign: 'right'}}>{/*Math.round((100 - +userData.discount)/100 * items_total_price)*/}</td>
                </tr>
                <tr style={{height: '20px'}}>
                <td colSpan={13} data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Tổng giá trị (đã bao gồm thuế VAT 10%)&quot;}" rowSpan={1} style={{borderRightColor: 'rgb(0, 0, 0)', borderBottomColor: 'rgb(0, 0, 0)', borderLeftColor: 'rgb(0, 0, 0)', overflow: 'hidden', padding: '0px 3px', verticalAlign: 'middle', fontFamily: '"Times New Roman"', fontSize: '8pt', fontStyle: 'italic', overflowWrap: 'break-word', textAlign: 'right'}}>Tổng giá trị (đã bao gồm thuế VAT 10%)</td>
                <td data-sheets-formula="=SUM(R[-3]C[0]:R[-1]C[0])" data-sheets-numberformat="[null,2,&quot;_-* #,##0\\ _D_M_-;\\-* #,##0\\ _D_M_-;_-* \&quot;-\&quot;??\\ _D_M_-;_-@&quot;,1]" data-sheets-value="{&quot;1&quot;:3,&quot;3&quot;:0}" style={{borderRightColor: 'rgb(0, 0, 0)', borderBottomColor: 'rgb(0, 0, 0)', overflow: 'hidden', padding: '0px 3px', verticalAlign: 'middle', fontFamily: '"Times New Roman"', fontSize: '8pt', fontWeight: 'bold', overflowWrap: 'break-word', textAlign: 'right'}}>{Math.round((100 - +userData.discount)/100 * items_total_price * 1.1)}</td>
                </tr>
                <tr style={{height: '21px'}}>
                <td style={{overflow: 'hidden', padding: '0px 3px 0px 3px', verticalAlign: 'middle'}}>&nbsp;</td>
                <td style={{overflow: 'hidden', padding: '0px 3px 0px 3px', verticalAlign: 'middle'}}>&nbsp;</td>
                <td style={{overflow: 'hidden', padding: '0px 3px 0px 3px', verticalAlign: 'middle'}}>&nbsp;</td>
                <td style={{overflow: 'hidden', padding: '0px 3px 0px 3px', verticalAlign: 'middle'}}>&nbsp;</td>
                <td style={{overflow: 'hidden', padding: '0px 3px 0px 3px', verticalAlign: 'middle'}}>&nbsp;</td>
                <td style={{overflow: 'hidden', padding: '0px 3px 0px 3px', verticalAlign: 'middle'}}>&nbsp;</td>
                <td style={{overflow: 'hidden', padding: '0px 3px 0px 3px', verticalAlign: 'middle'}}>&nbsp;</td>
                <td data-sheets-numberformat="[null,2,&quot;_-* #,##0\\ _D_M_-;\\-* #,##0\\ _D_M_-;_-* \&quot;-\&quot;??\\ _D_M_-;_-@&quot;,1]" style={{overflow: 'hidden', padding: '0px 3px 0px 3px', verticalAlign: 'middle'}}>&nbsp;</td>
                <td style={{overflow: 'hidden', padding: '0px 3px 0px 3px', verticalAlign: 'bottom'}}>&nbsp;</td>
                <td style={{overflow: 'hidden', padding: '0px 3px 0px 3px', verticalAlign: 'bottom'}}>&nbsp;</td>
                <td style={{overflow: 'hidden', padding: '0px 3px 0px 3px', verticalAlign: 'bottom'}}>&nbsp;</td>
                <td style={{overflow: 'hidden', padding: '0px 3px 0px 3px', verticalAlign: 'bottom'}}>&nbsp;</td>
                <td style={{overflow: 'hidden', padding: '0px 3px 0px 3px', verticalAlign: 'bottom'}}>&nbsp;</td>
                <td style={{overflow: 'hidden', padding: '0px 3px 0px 3px', verticalAlign: 'bottom'}}>&nbsp;</td>
                </tr>
                <tr style={{height: '21px'}}>
                <td colSpan={8} data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Ghi chú:&quot;}" rowSpan={1} style={{overflow: 'hidden', padding: '0px 3px', verticalAlign: 'middle', fontFamily: '"Times New Roman"', fontSize: '8pt', fontWeight: 'bold', overflowWrap: 'break-word'}}>Ghi chú:</td>
                <td style={{overflow: 'hidden', padding: '0px 3px 0px 3px', verticalAlign: 'bottom'}}>&nbsp;</td>
                <td style={{overflow: 'hidden', padding: '0px 3px 0px 3px', verticalAlign: 'bottom'}}>&nbsp;</td>
                <td style={{overflow: 'hidden', padding: '0px 3px 0px 3px', verticalAlign: 'bottom'}}>&nbsp;</td>
                <td style={{overflow: 'hidden', padding: '0px 3px 0px 3px', verticalAlign: 'bottom'}}>&nbsp;</td>
                <td style={{overflow: 'hidden', padding: '0px 3px 0px 3px', verticalAlign: 'bottom'}}>&nbsp;</td>
                <td style={{overflow: 'hidden', padding: '0px 3px 0px 3px', verticalAlign: 'bottom'}}>&nbsp;</td>
                </tr>
                <tr style={{height: '20px'}}>
                <td colSpan={14} data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;1. Hiệu lực báo giá: trong vòng 30 ngày kể từ ngày gửi .&quot;}" rowSpan={1} style={{overflow: 'hidden', padding: '0px 3px', verticalAlign: 'middle', fontFamily: '"Times New Roman"', fontSize: '8pt', overflowWrap: 'break-word'}}>
                    <span style={{fontSize: '8pt', fontWeight: 'bold', fontStyle: 'italic'}}>1. Hiệu lực báo giá:</span>
                    <span style={{fontSize: '8pt', fontStyle: 'italic'}} />
                    <span style={{fontSize: '8pt'}}>trong vòng 30 ngày kể từ ngày gửi .</span>
                </td>
                </tr>
                <tr style={{height: '23px'}}>
                <td colSpan={14} data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;2. Thanh toán: tạm ứng 100% khi đặt hàng đối với đơn hàng dưới 1 triệu và 50% với đơn hàng trên 1 triệu. Thanh toán nốt 50% khi có thông báo giao hàng&quot;}" rowSpan={1} style={{overflow: 'hidden', padding: '0px 3px', verticalAlign: 'middle', fontFamily: '"Times New Roman"', fontSize: '8pt', fontWeight: 'bold', fontStyle: 'italic', overflowWrap: 'break-word'}}>2. Thanh toán: tạm ứng 100% khi đặt hàng đối với đơn hàng dưới 1 triệu và 50% với đơn hàng trên 1 triệu. Thanh toán nốt 50% khi có thông báo giao hàng</td>
                </tr>
                <tr style={{height: '44px'}}>
                <td colSpan={14} data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;3. Hình thức chuyển khoản: Tiền mặt hoặc chuyển khoản. Tài khoản cá nhân số : 0011002288654  Vietcombank Nguyễn Ngọc Hải, Chi nhánh Hoàn kiếm. \nTài khoản công ty số : 19130701443014  CÔNG TY CỔ PHẦN SẢN XUẤT VÀ THƯƠNG MẠI ESK VIỆT NAM    \nNgân hàng Techcombank – Chi nhánh Thăng Long, Hà Nội&quot;}" rowSpan={1} style={{overflow: 'hidden', padding: '0px 3px', verticalAlign: 'middle', fontFamily: '"Times New Roman"', fontSize: '8pt', overflowWrap: 'break-word'}}>
                    <span style={{fontSize: '8pt', fontWeight: 'bold', fontStyle: 'italic'}}>3. Hình thức chuyển khoản:</span>
                    <span style={{fontSize: '8pt', fontStyle: 'italic'}} />
                    <span style={{fontSize: '8pt'}}>Tiền mặt hoặc chuyển khoản. Tài khoản cá nhân số : 0011002288654 Vietcombank Nguyễn Ngọc Hải, Chi nhánh Hoàn kiếm.
                    <br />
                    Tài khoản công ty số : 19130701443014 CÔNG TY CỔ PHẦN SẢN XUẤT VÀ THƯƠNG MẠI ESK VIỆT NAM
                    <br />
                    Ngân hàng Techcombank – Chi nhánh Thăng Long, Hà Nội
                    </span>
                </td>
                </tr>
                <tr style={{height: '21px'}}>
                <td colSpan={8} data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;4. Thời gian thực hiện: giao hàng 1-2 tuần sau khi xác nhận đặt hàng&quot;}" rowSpan={1} style={{overflow: 'hidden', padding: '0px 3px', verticalAlign: 'middle', fontFamily: '"Times New Roman"', fontSize: '8pt', overflowWrap: 'break-word'}}>
                    <span style={{fontSize: '8pt', fontWeight: 'bold', fontStyle: 'italic'}}>4. Thời gian thực hiện:</span>
                    <span style={{fontSize: '8pt', fontStyle: 'italic'}} />
                    <span style={{fontSize: '8pt'}}>giao hàng 1-2 tuần sau khi xác nhận đặt hàng</span>
                </td>
                <td style={{overflow: 'hidden', padding: '0px 3px', verticalAlign: 'bottom', fontFamily: '"Times New Roman"', fontSize: '8pt'}}>&nbsp;</td>
                <td style={{overflow: 'hidden', padding: '0px 3px', verticalAlign: 'bottom', fontFamily: '"Times New Roman"', fontSize: '8pt'}}>&nbsp;</td>
                <td style={{overflow: 'hidden', padding: '0px 3px', verticalAlign: 'bottom', fontFamily: '"Times New Roman"', fontSize: '8pt'}}>&nbsp;</td>
                <td style={{overflow: 'hidden', padding: '0px 3px', verticalAlign: 'bottom', fontFamily: '"Times New Roman"', fontSize: '8pt'}}>&nbsp;</td>
                <td style={{overflow: 'hidden', padding: '0px 3px', verticalAlign: 'bottom', fontFamily: '"Times New Roman"', fontSize: '8pt'}}>&nbsp;</td>
                <td style={{overflow: 'hidden', padding: '0px 3px', verticalAlign: 'bottom', fontFamily: '"Times New Roman"', fontSize: '8pt'}}>&nbsp;</td>
                </tr>
                <tr style={{height: '21px'}}>
                <td style={{overflow: 'hidden', padding: '0px 3px 0px 3px', verticalAlign: 'middle'}}>&nbsp;</td>
                <td style={{overflow: 'hidden', padding: '0px 3px 0px 3px', verticalAlign: 'middle'}}>&nbsp;</td>
                <td style={{overflow: 'hidden', padding: '0px 3px 0px 3px', verticalAlign: 'middle'}}>&nbsp;</td>
                <td style={{overflow: 'hidden', padding: '0px 3px 0px 3px', verticalAlign: 'middle'}}>&nbsp;</td>
                <td style={{overflow: 'hidden', padding: '0px 3px 0px 3px', verticalAlign: 'middle'}}>&nbsp;</td>
                <td style={{overflow: 'hidden', padding: '0px 3px 0px 3px', verticalAlign: 'middle'}}>&nbsp;</td>
                <td style={{overflow: 'hidden', padding: '0px 3px 0px 3px', verticalAlign: 'middle'}}>&nbsp;</td>
                <td style={{overflow: 'hidden', padding: '0px 3px 0px 3px', verticalAlign: 'middle'}}>&nbsp;</td>
                <td style={{overflow: 'hidden', padding: '0px 3px 0px 3px', verticalAlign: 'bottom'}}>&nbsp;</td>
                <td style={{overflow: 'hidden', padding: '0px 3px 0px 3px', verticalAlign: 'bottom'}}>&nbsp;</td>
                <td style={{overflow: 'hidden', padding: '0px 3px 0px 3px', verticalAlign: 'bottom'}}>&nbsp;</td>
                <td style={{overflow: 'hidden', padding: '0px 3px 0px 3px', verticalAlign: 'bottom'}}>&nbsp;</td>
                <td style={{overflow: 'hidden', padding: '0px 3px 0px 3px', verticalAlign: 'bottom'}}>&nbsp;</td>
                <td style={{overflow: 'hidden', padding: '0px 3px 0px 3px', verticalAlign: 'bottom'}}>&nbsp;</td>
                </tr>
                <tr style={{height: '20px'}}>
                <td colSpan={7} data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Xác nhận đặt hàng của quý khách&quot;}" rowSpan={2} style={{overflow: 'hidden', padding: '0px 3px', verticalAlign: 'middle', fontFamily: '"Times New Roman"', fontSize: '8pt', overflowWrap: 'break-word', textAlign: 'center'}}>
                    <div style={{maxHeight: '40px'}}>Xác nhận đặt hàng của quý khách</div>
                </td>
                <td colSpan={7} data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Xin trân trọng cảm ơn quý khách hàng\nPhụ trách kinh doanh&quot;}" rowSpan={2} style={{overflow: 'hidden', padding: '0px 3px', verticalAlign: 'bottom', fontFamily: '"Times New Roman"', fontSize: '8pt', overflowWrap: 'break-word', textAlign: 'center'}}>
                    <div style={{maxHeight: '40px'}}>Xin trân trọng cảm ơn quý khách hàng
                    <br />
                    Phụ trách kinh doanh
                    </div>
                </td>
                </tr>
                <tr style={{height: '20px'}} /></tbody></table><style type="text/css" dangerouslySetInnerHTML={{__html: "* {border: unset;}\n    " }} />
        </div>
        );
    };

export default Catalogue;
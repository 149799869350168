import Immutable from 'seamless-immutable';
import {FETCH_ORDER_SUCCESS} from '../actions/order';

const INIT_STATE = Immutable({
    itemData: null,
    list: [],
    totals: 0,
});

export default function (state = INIT_STATE, action) {
    switch (action.type) {
        case FETCH_ORDER_SUCCESS:
            return Object.assign({}, state, {list: action.payload.list, totals: action.payload.totals, totalPayment: action.payload.totalPayment, discount: action.payload.discount, financeData: action.payload.financeData});
        default:
            return state;
    }
}

import Immutable from 'seamless-immutable';
import {
    GET_SIGNED_URL_SUCCESS,
} from '../actions/photoupload';

const INIT_STATE = Immutable({
    uploaded_src: '',
    uploaded_path: '',
});

export default function (state = INIT_STATE, action) {
    switch (action.type) {
        case GET_SIGNED_URL_SUCCESS:
            return Object.assign({}, state, {uploaded_src: action.payload.url, uploaded_path: action.payload.path});
        default:
            return state;
    }
}

import React from 'react';
import ReactS3Uploader from 'react-s3-uploader';
import {connect} from 'react-redux';
import {getSignedUrl} from '../../../actions/photoupload';
import './FileUpload.css';
import {
    Grid,
    CircularProgress,
    Typography,
} from "@material-ui/core";

class AnyUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
        }
        this.uploadInput = React.createRef();
        this.uploader = React.createRef();
        this.onUploadError = this.onUploadError.bind(this);
        this.onUploadProgress = this.onUploadProgress.bind(this);
        this.onUploadFinish = this.onUploadFinish.bind(this);
        this.onUploadStart = this.onUploadStart.bind(this);
        this.onGetSignedUrl = this.onGetSignedUrl.bind(this);
    }

    async onGetSignedUrl(file, callback) {
        let customFileName, filename = this.props.customName;
        if (filename) {
            customFileName = filename + `_${file.name}`;
        }
        let data =  await this.props.getSignedUrl({"filename": customFileName || file.name});
        this.setState({...this.state, uploaded: {uploaded_path: data.path, uploaded_src: data.url}});
        return callback({signedUrl: data.url});
    }

    onUploadStart(file, next) {
        this.setState({...this.state, loading: true});
        return next(file);
    }

    onUploadProgress(p) {
        this.setState({progress: p})
    }

    onUploadFinish() {
        let {uploaded_path, uploaded_src} = this.state.uploaded;
        this.setState({...this.state, loading: false, uploaded_path, uploaded_src});
        this.props.onChange && this.props.onChange(this.state);
    }

    onUploadError(p) {
        this.setState({loading: false});
        console.log(p)
    }

    render() {
        const {label, name, value, onChange, className, type} = this.props;
        return (
            <Grid container>
                <Grid item sm={12} md={6} lg={6}>
                    {
                        this.state.progress == 100 ? <Typography color="primary" >Upload Success!</Typography>:
                        <CircularProgress color="secondary" variant="determinate" value={this.state.progress} />
                    }
                </Grid>
                <Grid>
                    <ReactS3Uploader
                        ref = {r => this.uploader = r}
                        accept={ type ? `${type}` : "image/*"}
                        getSignedUrl={this.onGetSignedUrl}
                        preprocess={this.onUploadStart}
                        onProgress={this.onUploadProgress}
                        onFinish={this.onUploadFinish}
                        onError={this.onUploadError}
                        uploadRequestHeaders={{ 'x-amz-acl': 'public-read' }}
                        contentDisposition="auto"
                        inputRef={cmp => this.uploadInput = cmp}
                        autoUpload={true}
                        disabled={this.state.loading}
                        />
                </Grid>
            </Grid>
        );
    }
}

const mapPropsToDispatch = dispatch => ({
    getSignedUrl: async (query) => {
        return await dispatch(getSignedUrl(query));
    }
});

const mapStateToProps = state => ({...state});

export default connect(mapStateToProps, mapPropsToDispatch)(AnyUpload);
import React from "react";
import { browserHistory } from "react-router";
import {
  Paper,
  Typography,
  Grid,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  Input,
  MenuItem
} from "@material-ui/core";
import Link from "@material-ui/core/Link";
import Tooltip from "@material-ui/core/Tooltip";
import { Delete, Edit } from "@material-ui/icons";
import { MaterialTable } from "../../components/common/Table";
import { ITEM_STATUS } from "../../constants";
import { doRightRole } from "../../utils/helper";
import moment from "moment";
import _ from "lodash";
import { getLoggedInRole } from "../../utils/helper";
import { PERMISSION_LEVELS } from "../../constants";

class OrderList extends React.Component {
  constructor(props) {
    super(props);
    this.renderActions = this.renderActions.bind(this);
    this.handleOpenConfirm = this.handleOpenConfirm.bind(this);
    this.handleCloseConfirm = this.handleCloseConfirm.bind(this);
    this.handleActionConfirm = this.handleActionConfirm.bind(this);
    this.renderItemCode = this.renderItemCode.bind(this);
    this.renderOrderCode = this.renderOrderCode.bind(this);

    this.state = {
      userRole: 'customer',
      openConfirm: false,
      selectedItem: null,
      formData: {
        item_status: ""
      },
      style_actions: {}
    };
  }

  componentDidMount() {
    const { role } = this.props.params;
    if (role === "admin") {
      this.fetchList = this.props.getAllListOrders;
    } else if (role === "user") {
      this.fetchList = this.props.getAllListOrdersForUser;
    }
    this.fetchList({
      limit: 10,
      page: 1,
      orderBy: "id",
      orderDir: "desc",
      textSearch: ""
    });
    this.state.userRole = getLoggedInRole();
    this.setState({...this.state});
    window.addEventListener("scroll", this.handleScrollDebounce, true);
    document.querySelector('.table-scrollable').dispatchEvent(new Event('scroll'));
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScrollDebounce, true);
  }

  handleScrollDebounce = _.debounce(event => {
    let alignElement = document.querySelector('.table-scrollable');
    let anchorElement = document.querySelector('.table-scrollable > table > thead > tr > th:last-child');
    const { style_actions } = this.state;
    const maxOffsetLeft = anchorElement.offsetLeft + anchorElement.offsetWidth - 38;
    let left = alignElement.offsetWidth + event.srcElement.scrollLeft;
    left = left > maxOffsetLeft ? maxOffsetLeft : left;
    style_actions["left"] = `calc(${left}px)`;
    this.setState({ style_actions: style_actions });
  }, 100);

  handleOpenConfirm(index) {
    this.state.openConfirm = true;
    this.state.selectedItem = index;
    this.setState({ ...this.state });
  }

  handleCloseConfirm() {
    this.state.openConfirm = false;
    this.setState({ ...this.state });
  }

  async handleActionConfirm() {
    if (this.state.selectedItem) {
      await this.props.deleteItem({ item_id: this.state.selectedItem });
    }
    await this.fetchList({
      limit: 10,
      page: 1,
      orderBy: "id",
      orderDir: "desc",
      textSearch: ""
    });
    await this.setState({ selectedItem: null });
    await this.handleCloseConfirm();
  }

  handleSelectChange = async (field, e) => {
    this.state.formData[field] = e.target.value;
    await this.setState({ ...this.state });
    this.fetchList({
      limit: 10,
      page: 1,
      orderBy: "id",
      orderDir: "desc",
      textSearch: "",
      ...this.state.formData
    });
  };

  redirectToOrderView = async row => {
    return await doRightRole(
      {
        admin: () => {
          browserHistory.push(`/admin/order-management/view/${row.order_code}`);
        },
        employee: () => {
          browserHistory.push(`/admin/order-management/view/${row.order_code}`);
        },
        customer: function() {
          browserHistory.push(`/user/order-management/view/${row.order_code}`);
        }
      },
      {}
    );
  };

  redirectToOrderItemView = async row => {
    return await doRightRole(
      {
        admin: () => {
          browserHistory.push(`/admin/order-management/update/${row.id}`);
        },
        employee: () => {
          browserHistory.push(`/admin/order-management/update/${row.id}`);
        },
        customer: function() {
          browserHistory.push(`/user/order-management/update/${row.id}`);
        }
      },
      {}
    );
  };

  renderFullName(row) {
    return row.first_name + " " + row.last_name;
  }

  renderTime(row) {
    return moment(row.created_at).format("DD-MM-YY HH:mm:SS");
  }

  renderShippingTime(row) {
    return row.item_shipping_expected
      ? moment(row.item_shipping_expected).format("DD-MM-YY")
      : "";
  }

  renderItemTotalPrice = (row) => {
    return <>{row.item_total_price.toFixed(2)}{row.discount ? <span className="padding-badge">{' ▾' + row.discount + '% '}</span> : ""}</>
  }

  renderItemCode(row) {
    return (
      <Tooltip title={row.item_code} aria-label={row.item_code}>
        <Link component="button" variant="body2">
          {row.item_code ? row.item_code.substring(0, 15) : ""}
        </Link>
      </Tooltip>
    );
  }

  renderOrderCode(row) {
    return (
      <Tooltip title={row.order_code} aria-label={row.order_code}>
        <Link
          component="button"
          variant="body2"
          onClick={() => this.redirectToOrderView(row)}
        >
          {row.order_code ? row.order_code.substring(0, 15) : ""}
        </Link>
      </Tooltip>
    );
  }

  renderActions(row) {
    if (this.state.userRole === PERMISSION_LEVELS[0]) return <span>--</span>;
    return (
      <>
        <div className="actions" style={{ ...this.state.style_actions }}>
          <IconButton
            variant="contained"
            size="small"
            color="primary"
            className="small-btn"
            onClick={() => this.redirectToOrderItemView(row)}
          >
            <Edit />
          </IconButton>
          <IconButton
            variant="contained"
            size="small"
            color="secondary"
            className="small-btn"
            onClick={async () => await this.handleOpenConfirm(row.id)}
          >
            <Delete />
          </IconButton>
        </div>
      </>
    );
  }

  render() {
    const { order } = this.props;
    const { role } = this.props.params;
    if (!order) return <div />;
    const ORDER_PAGE_COLUMNS = [
      { title: "#", sortable: false, data_field: "index" },
      {
        title: "Customer Email",
        sortable: true,
        data_field: "user_email"
      },
      {
        title: "Customer Name",
        sortable: true,
        data_field: "user_fullname"
      },
      {
        title: "Created at",
        sortable: true,
        data_field: "createdAt",
        render: this.renderTime
      },
      {
        title: "Item name",
        sortable: true,
        data_field: "item_name"
      },
      {
        title: "Item code",
        sortable: true,
        data_field: "item_code",
        render: this.renderItemCode
      },
      {
        title: "Order code",
        sortable: true,
        data_field: "order_code",
        render: this.renderOrderCode
      },
      {
        title: "Amount",
        sortable: true,
        data_field: "item_amount"
      },
      {
        title: "Price/Unit",
        sortable: true,
        data_field: "item_unit_price"
      },
      {
        title: "Total Price",
        sortable: true,
        render: this.renderItemTotalPrice
      },
      {
        title: "Status",
        sortable: true,
        data_field: "item_status"
      },
      {
        title: "Supplier",
        sortable: true,
        data_field: "item_supplier"
      },
      {
        title: "Import Code",
        sortable: true,
        data_field: "item_import_code"
      },
      {
        title: "Export code",
        sortable: true,
        data_field: "item_export_code"
      },
      {
        title: "Shipping fee",
        sortable: true,
        data_field: "item_shipping_fee"
      },
      {
        title: "Shipping (Expected)",
        sortable: true,
        data_field: "item_shipping_expected",
        render: this.renderShippingTime
      },
      {
        title: "Note",
        sortable: true,
        data_field: "item_note"
      },
      {
        title: "Actions",
        sortable: false,
        data_field: null,
        //className: "actions",
        render: this.renderActions
      }
    ];
    if (getLoggedInRole() == PERMISSION_LEVELS[0]) {
      ORDER_PAGE_COLUMNS.splice(12, 3);
    }
    return (
      <Paper elevation={1} square className={"container"}>
        <Typography variant="h5" component="h4" className={"mc_page_title"}>
          Order Management
        </Typography>
        <Grid container>
          <Grid item sm={3} xl={3} lg={3}>
            <FormControl className={`full-width missing-margin`}>
              <InputLabel htmlFor="item_status-helper">Item Status</InputLabel>
              <Select
                value={this.state.formData.item_status}
                onChange={e => this.handleSelectChange("item_status", e)}
                input={<Input name="item_status" id="item_status-helper" />}
                className={`full-width`}
              >
                {ITEM_STATUS.map((value, index) => {
                  return (
                    <MenuItem
                      value={value}
                      key={index}
                      selected={value.id === this.state.formData.item_status}
                    >
                      {value}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          {/* <Grid item sm={3} xl={3} lg={3}>
            <DatePicker
              margin="normal"
              label="From"
              format="DD/MM/YYYY"
              className="fullwidth"
              value={this.state.formData.date_from}
              onChange={e => this.handleSelectTimeChange("date_from", e)}
            />
          </Grid>
          <Grid item sm={3} xl={3} lg={3}>
            <DatePicker
              margin="normal"
              label="From"
              format="DD/MM/YYYY"
              className="fullwidth"
              value={this.state.formData.date_from}
              onChange={e => this.handleSelectTimeChange("date_from", e)}
            />
          </Grid> */}
        </Grid>
        <MaterialTable
          columns={ORDER_PAGE_COLUMNS}
          rows={order.list}
          totals={order.totals}
          getList={this.fetchList}
          addNewLink={role ? `/${role}/order-management/create` : `/`}
        />
        {/* CONFIRMATION DIALOG */}
        <Dialog
          open={this.state.openConfirm}
          onClose={this.handleCloseConfirm}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">{"Warning!"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this item?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseConfirm} color="primary">
              Cancel
            </Button>
            <Button
              onClick={this.handleActionConfirm}
              color="primary"
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    );
  }
}
export default OrderList;

import api from '../utils/api';
import {toast} from 'react-toastify';
import {enableLoading, loadingFailed, loadingSuccess} from '../actions/utils';
import {browserHistory} from 'react-router';

export const CREATE_PRODUCT_SUCCESS = `CREATE_PRODUCT_SUCCESS`;
export const PRODUCT_GET_LIST_SUCCESS = `PRODUCT_GET_LIST_SUCCESS`;

export const createProduct = (data) => {
    return async (dispatch) => {
        try {
            await dispatch(enableLoading());
            let res = await api.createProduct(data) || {};
            if (res.data && res.data.status === "success") {
                const {data, message} = res.data;
                if (message) toast.success(message);
                await dispatch(loadingSuccess());
                if (data) browserHistory.push('/admin/product-management/list');
            } else {
                if (res.data && res.data.message) toast.error(res.data.message);
                await dispatch(loadingFailed());
            }
        } catch (e) {
            console.log(e);
            await dispatch(loadingFailed());
            toast.error(e.message);
        }
    }
}

export const getAllListProduct = (query) => {
    return async (dispatch) => {
        try {
            await dispatch(enableLoading());
            let res = await api.getAllListProduct(query);
            if (res.data && res.data.status === "success") {
                const {data, message} = res.data;
                if (data) await dispatch(getAllListProductSuccess(data));
                await dispatch(loadingSuccess());
            } else {
                if (res.data && res.data.message) toast.error(res.data.message);
                await dispatch(loadingFailed());
            }
        } catch (e) {
            console.log(e);
            await dispatch(loadingFailed());
            toast.error(e.message);
        }
    }
}

export const getAllListProductSuccess = (listData) => {
    return {
        type: PRODUCT_GET_LIST_SUCCESS,
        payload: listData,
    }
}

export const deleteProduct = (query) => {
    return async (dispatch) => {
        try {
            await dispatch(enableLoading());
            let res = await api.deleteProduct(query);
            if (res.data && res.data.status === "success") {
                const {data, message} = res.data;
                if (data) toast.success('Product deleted successfully.')
                await dispatch(loadingSuccess());
                return browserHistory.push('/admin/product-management/list')
            } else {
                if (res.data && res.data.message) toast.error(res.data.message);
                await dispatch(loadingFailed());
            }
        } catch (e) {
            console.log(e);
            await dispatch(loadingFailed());
            toast.error(e.message);
        }
    }
}

export const getProductData = (query) => {
    return async (dispatch) => {
        try {
            await dispatch(enableLoading());
            let res = await api.getProductData(query);
            if (res.data && res.data.status === "success") {
                const {data, message} = res.data;
                await dispatch(loadingSuccess());
                //return browserHistory.push('/admin/user-management/list')
                if (data) return data;
            } else {
                if (res.data && res.data.message) toast.error(res.data.message);
                await dispatch(loadingFailed());
            }
        } catch (e) {
            console.log(e);
            await dispatch(loadingFailed());
            toast.error(e.message);
        }
    }
}
import api from '../utils/api';
import {toast} from 'react-toastify';
import {enableLoading, loadingFailed, loadingSuccess} from '../actions/utils';

export const FETCH_ALL_PAYMENT_INRANGE = `financal/FETCH_ALL_PAYMENT_INRANGE`;

export const getAllPaymentHistoryInRange = (data) => {
    return async (dispatch) => {
        try {
            await dispatch(enableLoading());
            let res = await api.getAllPaymentHistoryInRange(data) || {};
            if (res.data && res.data.status === "success") {
                const {data, message} = res.data;
                if (message) toast.success(message);
                if (data) await dispatch(getAllPaymentHistoryInRangeSuccess(data));;
                await dispatch(loadingSuccess());
            } else {
                if (res.data && res.data.message) toast.error(res.data.message);
                await dispatch(loadingFailed());
            }
        } catch (e) {
            console.log(e);
            await dispatch(loadingFailed());
            toast.error(e.message);
        }
    }
}

export const getAllPaymentHistoryInRangeSuccess = (data) => {
    return {
        type: FETCH_ALL_PAYMENT_INRANGE,
        payload: data
    }
}




export const REACT_APP_NODE_API_DOMAIN = `${process.env.REACT_APP_NODE_API_DOMAIN}`;
export const REACT_APP_NODE_API_PORT = `${process.env.REACT_APP_NODE_API_PORT}`;
export const REACT_APP_S3_HOST_URL = `${process.env.REACT_APP_S3_HOST_URL}`;

export const STATUS_CODE = {
    TOKEN_EXPIRED: 5,
}

export const PERMISSION_LEVELS = [
    'customer', 'employee', 'admin'
]

export const ITEM_STATUS = ['canceled', 'new', 'catalogued', 'confirmed', 'ordering', 'checked', 'shipped'];

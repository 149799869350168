import api from '../utils/api';
import {toast} from 'react-toastify';
import {enableLoading, loadingFailed, loadingSuccess} from '../actions/utils';
import {browserHistory} from 'react-router';

export const CREATE_DISCOUNT_SUCCESS = `CREATE_DISCOUNT_SUCCESS`;
export const FETCH_DISCOUNT_SUCCESS = `FETCH_DISCOUNT_SUCCESS`;
export const FETCH_ALL_DISCOUNT_SUCCESS = `FETCH_ALL_DISCOUNT_SUCCESS`;

export const getDiscountData = (data) => {
    return async (dispatch) => {
        try {
            await dispatch(enableLoading());
            let res = await api.getDiscountData(data) || {};
            if (res.data && res.data.status === "success") {
                const {data, message} = res.data;
                await dispatch(loadingSuccess());
                if (data) return data;
            } else {
                if (res.data && res.data.message) toast.error(res.data.message);
                await dispatch(loadingFailed());
            }
        } catch (e) {
            console.log(e);
            await dispatch(loadingFailed());
            toast.error(e.message);
        }
    }
}

export const deleteDiscount = (data) => {
    return async (dispatch) => {
        try {
            await dispatch(enableLoading());
            let res = await api.deleteDiscount(data) || {};
            if (res.data && res.data.status === "success") {
                const {data, message} = res.data;
                if (message) toast.success(message);
                await dispatch(loadingSuccess());
            } else {
                if (res.data && res.data.message) toast.error(res.data.message);
                await dispatch(loadingFailed());
            }
        } catch (e) {
            console.log(e);
            await dispatch(loadingFailed());
            toast.error(e.message);
        }
    }
}

export const fetchAllDiscount = (data) => {
    return async (dispatch) => {
        try {
            await dispatch(enableLoading());
            let res = await api.fetchAllDiscount(data) || {};
            if (res.data && res.data.status === "success") {
                const {data, message} = res.data;
                //if (message) toast.success(message);
                await dispatch(loadingSuccess());
                //if (data) browserHistory.push('/admin/discount-management/list');
                if (data) await dispatch(fetchAllDiscountSuccess(data));
            } else {
                if (res.data && res.data.message) toast.error(res.data.message);
                await dispatch(loadingFailed());
            }
        } catch (e) {
            console.log(e);
            await dispatch(loadingFailed());
            toast.error(e.message);
        }
    }
}

export const fetchAllDiscountSuccess = (data) => {
    return {
        type: FETCH_ALL_DISCOUNT_SUCCESS,
        payload: data
    }
}

export const createDiscount = (data) => {
    return async (dispatch) => {
        try {
            await dispatch(enableLoading());
            let res = await api.createDiscount(data) || {};
            if (res.data && res.data.status === "success") {
                const {data, message} = res.data;
                if (message) toast.success(message);
                await dispatch(loadingSuccess());
                if (data) browserHistory.push('/admin/discount-management/list');
            } else {
                if (res.data && res.data.message) toast.error(res.data.message);
                await dispatch(loadingFailed());
            }
        } catch (e) {
            console.log(e);
            await dispatch(loadingFailed());
            toast.error(e.message);
        }
    }
}

export const fetchAllDiscountNoQuery = () => {
    return async (dispatch) => {
        try {
            await dispatch(enableLoading());
            let res = await api.fetchAllDiscountNoQuery() || {};
            if (res.data && res.data.status === "success") {
                const {data, message} = res.data;
                await dispatch(loadingSuccess());
                if (data) return data;
            } else {
                if (res.data && res.data.message) toast.error(res.data.message);
                await dispatch(loadingFailed());
            }
        } catch (e) {
            console.log(e);
            await dispatch(loadingFailed());
            toast.error(e.message);
        }
    }
}
import api from '../utils/api';
import {toast} from 'react-toastify';
import {enableLoading, loadingFailed, loadingSuccess} from '../actions/utils';

export const GET_ADMIN_PROFILE_CHANGE = `UPDATE_LOGGED_USER_INFO`;

export const getAdminProfile = (query) => {
    return async (dispatch) => {
        try {
            await dispatch(enableLoading());
            let res = await api.getAdminProfile(query);
            if (res.data && res.data.status === "success") {
                const {data, message} = res.data;
                if (data) await dispatch(getAdminProfileSuccess(data));
                await dispatch(loadingSuccess());
            } else {
                if (res.data && res.data.message) toast.error(res.data.message);
                await dispatch(loadingFailed());
            }
        } catch (e) {
            console.log(e);
            await dispatch(loadingFailed());
            toast.error(e.message);
        }
    }
}

export const getAdminProfileSuccess = (userData) => {
    return async (dispatch) => {
        try {
            userData && localStorage.setItem('userData', JSON.stringify(userData));
            await dispatch(getAdminProfileChange(userData));
        } catch (e) {
            console.log(e);
        }
    }
}

export const getAdminProfileChange = (userData) => {
    return {
        type: GET_ADMIN_PROFILE_CHANGE,
        payload: userData,
    }
}


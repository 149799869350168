import React from 'react';
import {Form, Grid} from 'tabler-react';
import ReactS3Uploader from 'react-s3-uploader';
import {connect} from 'react-redux';
import {getSignedUrl} from '../../../actions/photoupload';
import './FileUpload.css';
import ImagePreview from "./ImagePreview";

class FileUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
        }
        this.uploadInput = React.createRef();
        this.uploader = React.createRef();
        this.onUploadError = this.onUploadError.bind(this);
        this.onUploadProgress = this.onUploadProgress.bind(this);
        this.onUploadFinish = this.onUploadFinish.bind(this);
        this.onUploadStart = this.onUploadStart.bind(this);
        this.onGetSignedUrl = this.onGetSignedUrl.bind(this);
    }

    async onGetSignedUrl(file, callback) {
        let data =  await this.props.getSignedUrl({"filename": file.name});
        this.setState({...this.state, uploaded: {uploaded_path: data.path, uploaded_src: data.url}});
        return callback({signedUrl: data.url});
    }

    onUploadStart(file, next) {
        this.setState({...this.state, loading: true});
        return next(file);
    }

    onUploadProgress(p) {
        this.setState({progress: p})
    }

    onUploadFinish() {
        let {uploaded_path, uploaded_src} = this.state.uploaded;
        this.setState({...this.state, loading: false, uploaded_path, uploaded_src});
        this.props.onChange && this.props.onChange(this.state);
    }

    onUploadError(p) {
        this.setState({loading: false});
        console.log(p)
    }

    render() {
        const {label, name, value, onChange, className, type} = this.props;
        return (
            <Form.Group label={label || "Image"}>
                <Grid.Row>
                    <Grid.Col md={6} sm={12} lg={6}>
                        <ImagePreview type={type || "image"} src={this.state.uploaded_src || this.props.availableSrc} progress={this.state.progress} loading={this.state.loading}/>
                    </Grid.Col>
                    <Grid.Col md={6} sm={12} lg={6}>
                        <div className={`uploader-wrapper`}>
                            <ReactS3Uploader
                                ref = {r => this.uploader = r}
                                accept={ type ? `${type}` : "image/*"}
                                getSignedUrl={this.onGetSignedUrl}
                                preprocess={this.onUploadStart}
                                onProgress={this.onUploadProgress}
                                onFinish={this.onUploadFinish}
                                onError={this.onUploadError}
                                uploadRequestHeaders={{ 'x-amz-acl': 'public-read' }}
                                contentDisposition="auto"
                                inputRef={cmp => this.uploadInput = cmp}
                                autoUpload={true}
                                disabled={this.state.loading}
                                />
                        </div>
                    </Grid.Col>
                    
                </Grid.Row>
            </Form.Group>
        );
    }
}

const mapPropsToDispatch = dispatch => ({
    getSignedUrl: async (query) => {
        return await dispatch(getSignedUrl(query));
    }
});

const mapStateToProps = state => ({...state});

export default connect(mapStateToProps, mapPropsToDispatch)(FileUpload);
import React from 'react';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
//import store from '../../store';
import Grid from '@material-ui/core/Grid';
import UserSidebar from './Sidebar/UserSidebar';
import Header from './Header/Header';
import './DashboardLayout.css';

import {toggleSidebar} from '../../../actions/utils';
import {logout, getCustomerProfile} from '../../../actions/user';

class UserDashboardLayout extends React.Component {
    constructor(props) {
        super(props);
    }
    async componentDidMount() {
        await this.props.getCustomerProfile();
    }
    render() {
        let childrenWithProps = React.Children.map(this.props.children,
                (child) => {
                    return React.cloneElement(child, { ...this.props, children: child.props.children })
                }
            );
        return (
            <Grid container className={"dashboard-layout-wrapper"}>
                <Header {...this.props}/>
                <Grid item className={"full-width"}>
                    {childrenWithProps}
                </Grid>
                <UserSidebar {...this.props}/>
            </Grid>
        );
    }
}

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
    toggleSidebar: async (data) => {
        return dispatch(toggleSidebar(data))
    },
    logout: async () => {
        return await dispatch(logout());
    },
    getCustomerProfile: async (data) => {
        return dispatch(getCustomerProfile(data));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(UserDashboardLayout);

import { REACT_APP_S3_HOST_URL, PERMISSION_LEVELS } from "../constants";
import _ from "lodash";

export const buildS3Url = relativePath => {
    return `${REACT_APP_S3_HOST_URL}/${relativePath}`;
};

export default {
    calculateTotalItemPrice: (
        items_number,
        unit_price,
        exchanges,
        exchange_id,
        item_shipping_fee,
        factor,
        discount
    ) => {
        let exchange = _.find(exchanges, { id: exchange_id });
        discount = discount || 0;
        return exchange ?
            (+exchange.exchange_value *
                +unit_price *
                (factor ? +factor : 1) *
                +items_number * (100 - discount) / 100 +
                +item_shipping_fee).toFixed(2) :
            0;
    }
};

export const doRightRole = async(options, data = {}) => {
    let userJSON = localStorage.getItem("userData");
    try {
        let userData = JSON.parse(userJSON);
        if (!userData || !userData.role) throw new Error("Invalid role.");
        if (options && options[userData.role]) {
            console.log(`DO RIGHT ROLES: `, options[userData.role]);
            return await options[userData.role](data);
        } else {
            throw new Error(`Options[${userData.role}] not found.`);
        }
    } catch (err) {
        console.error(err.message);
    }
    return false;
};

export const getLoggedInRole = () => {
    let userJSON = localStorage.getItem("userData");
    try {
        let userData = JSON.parse(userJSON);
        if (!userData || !userData.role) throw new Error("Invalid role.");
        return userData.role;
    } catch (err) {
        console.error(err.message);
    }
    return PERMISSION_LEVELS[0];
};

export const checkPermissionAdmin = () => {
    const role = getLoggedInRole();
    return PERMISSION_LEVELS[2] === role || PERMISSION_LEVELS[1] === role;
};
import React from "react";
import { browserHistory } from "react-router";
import {
  Paper,
  Typography,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControl,
  TextField,
  Grid,
  InputLabel,
  Input,
  Checkbox
} from "@material-ui/core";
import Link from "@material-ui/core/Link";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import { Delete, Edit, Payment } from "@material-ui/icons";
import { MaterialTable } from "../../components/common/Table";
import { PdfExporter } from "../../components/common/PdfExporter";
import ExcelExporterJS from "../../components/common/ExcelJS/ExcelExporterJS";
import Catalogue from "../../assets/templates/Catalogue";
import _ from "lodash";
import moment from "moment";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { getLoggedInRole } from "../../utils/helper";
import { PERMISSION_LEVELS } from "../../constants";
window.html2canvas = html2canvas;

class OrderView extends React.Component {
  constructor(props) {
    super(props);
    this.renderActions = this.renderActions.bind(this);
    this.handleCloseExporter = this.handleCloseExporter.bind(this);
    this.handleOpenExporter = this.handleOpenExporter.bind(this);
    this.handleOpenConfirm = this.handleOpenConfirm.bind(this);
    this.handleCloseConfirm = this.handleCloseConfirm.bind(this);
    this.handleActionConfirm = this.handleActionConfirm.bind(this);
    this.handleChecklistChange = this.handleChecklistChange.bind(this);
    this.renderCheckboxExport = this.renderCheckboxExport.bind(this);
    this.renderItemCode = this.renderItemCode.bind(this);
    this.renderOrderCode = this.renderOrderCode.bind(this);
    this.getOrderMeta = this.getOrderMeta.bind(this);
    this.savePdf = this.savePdf.bind(this);

    this.state = {
      openConfirm: false,
      openExporter: false,
      openPaymentDialog: false,
      selectedItem: null,
      style_actions: {},
      checkList: {},
      userData: {},
      paymentData: {
        order_code: 0,
        item_id: 0,
        payment_note: "",
        payment_value: 0
      },
      orderMeta: {
        paid: 0,
        note: "",
        remaining: 0,
        total_payment: 0
      },
      page: {
        width: 808,
        height: 1144
      },
      paymentHistory: {
        list: [],
        joinedNotes: "",
        sumPayments: 0
      }
    };
  }

  componentDidMount() {
    const { role, id } = this.props.params;
    if (role === "admin") {
      this.fetchList = this.props.getAllListItemsInOrder;
      this.getOrderMeta(id);
      this.getPaymentHistory(id);
    } else if (role === "user") {
      this.fetchList = this.props.getAllListItemsInOrderForUser;
    }
    this.fetchList({
      limit: 10,
      page: 1,
      orderBy: "id",
      orderDir: "asc",
      textSearch: "",
      orderId: id
    });
    window.addEventListener("scroll", this.handleScrollDebounce, true);
    document.querySelector('.table-scrollable').dispatchEvent(new Event('scroll'));
  }

  onChangeField(field, e) {
    this.setState({
      orderMeta: { ...this.state.orderMeta, [field]: e.target.value }
    });
  }

  onChangePaymentField = (entry, field, e) => {
    this.state[entry][field] = e.target.value;
    this.setState({ ...this.state });
  };

  getOrderMeta(id) {
    this.props.getOrderMeta(id).then(data => {
      this.setState({ orderMeta: data.orderMeta });
    });
  }

  handleScrollDebounce = _.debounce(event => {
    let alignElement = document.querySelector('.table-scrollable');
    let anchorElement = document.querySelector('.table-scrollable > table > thead > tr > th:last-child');
    const { style_actions } = this.state;
    const maxOffsetLeft = anchorElement.offsetLeft + anchorElement.offsetWidth - 38;
    let left = alignElement.offsetWidth + event.srcElement.scrollLeft;
    left = left > maxOffsetLeft ? maxOffsetLeft : left;
    style_actions["left"] = `calc(${left}px)`;
    this.setState({ style_actions: style_actions });
  }, 100);

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScrollDebounce, true);
  }

  handleOpenConfirm(index) {
    this.state.openConfirm = true;
    this.state.selectedItem = index;
    this.setState({ ...this.state });
  }

  handleCloseConfirm() {
    this.state.openConfirm = false;
    this.setState({ ...this.state });
  }

  handleClosePaymentDialog = async isCancel => {
    const { id } = this.props.params;
    this.state.openPaymentDialog = false;
    try {
      await this.props.addPaymentHistory({
        ...this.state.paymentData,
        order_code: id
      });
    } catch (err) {
      console.log(err);
    }
    this.getPaymentHistory(id);
    this.setState({ ...this.state });
  };

  handleOpenPaymentDialog = async (order_code, item_id) => {
    this.state.openPaymentDialog = true;
    this.state.paymentData = { order_code, item_id };
    this.setState({ ...this.state });
  };

  handleCloseExporter() {
    this.state.openExporter = false;
    this.setState({ ...this.state });
  }

  initialize = async () => {
    const checkList = this.state.checkList;
    const itemAsOrder = checkList[Object.keys(checkList)[0]];
    if (!itemAsOrder || !itemAsOrder.user_id) return;
    let userData = await this.props.getUserProfile(itemAsOrder.user_id);
    this.state.userData = userData || {};
    await this.setState({ ...this.state });
    return {
      items: { ...this.state.checkList },
      userData: { ...this.state.userData }
    };
  };

  async handleOpenExporter() {
    await this.initialize();
    this.state.openExporter = true;
    this.setState({ ...this.state });
  }

  handleChecklistChange(val, item) {
    if (val && !this.state.checkList[item.id]) {
      this.state.checkList[item.id] = item;
    } else if (!val || this.state.checkList[item.id]) {
      this.state.checkList[item.id] && delete this.state.checkList[item.id];
    }
    this.setState({ checkList: { ...this.state.checkList } });
  }

  async handleActionConfirm() {
    if (this.state.selectedItem) {
      await this.props.deleteItem({ item_id: this.state.selectedItem });
    }
    const { id } = this.props.params;
    await this.fetchList({
      limit: 10,
      page: 1,
      orderBy: "id",
      orderDir: "asc",
      textSearch: "",
      orderId: id
    });
    await this.setState({ selectedItem: null });
    await this.handleCloseConfirm();
  }

  remaining(totalPayment, discount, paid) {
    totalPayment = totalPayment || 0;
    discount = discount || 0;
    paid = paid || 0;
    const remain =
      +totalPayment * (1 - +discount) / 100 - paid;
    return remain >= 0 ? remain.toFixed(2) : 0;
  }

  async savePdf() {
    const canvas = await html2canvas(document.getElementById("savePdf"), {
      dpi: 300, // Set to 300 DPI
      scale: 3 // Adjusts your resolution
    });
    var img = canvas.toDataURL("image/jpeg", 1);
    var doc = new jsPDF("p", "in");
    doc.addImage(img, "JPEG", 0.5, 0.5, 7.27, 10.69);
    doc.save("catalogue.pdf");
  }

  saveOrderMeta = () => {
    const { order } = this.props;
    //Save order meta data if need for editable
    this.state.orderMeta.remaining = this.remaining(
      order.totalPayment,
      order.discount,
      this.state.paymentHistory.sumPayments
    );
    this.state.orderMeta.paid = this.state.paymentHistory.sumPayments;
    this.state.orderMeta.total_payment = order.totalPayment;
    this.state.orderMeta.discount = order.discount;
    const { id } = this.props.params;
    this.props.saveOrderMeta({
      ...this.state.orderMeta,
      order_code: id
    });
  };

  getPaymentHistory = async id => {
    const paymentHistory = await this.props.getPaymentHistory(id);
    this.state.paymentHistory.list = paymentHistory;
    this.state.paymentHistory.sumPayments = 0;
    this.state.paymentHistory.joinedNotes = paymentHistory.map(payment => {
      this.state.paymentHistory.sumPayments += +payment.payment_value;
      return `
        ${moment(payment.created_at).format("DD/MM/YYYY")} paid ${
        payment.payment_value
      } note: ${payment.payment_note}
      `;
    });
    this.setState({ paymentHistory: { ...this.state.paymentHistory } });
  };

  renderCheckboxExport(row) {
    return (
      <Checkbox
        checked={!!this.state.checkList[row.id]}
        onChange={(e, val) => this.handleChecklistChange(val, row)}
        disabled={isNaN(row.item_total_price)}
      />
    );
  }

  renderFullName(row) {
    return row.first_name + " " + row.last_name;
  }

  renderTime(row) {
    return moment(row.created_at).format("DD-MM-YY HH:mm:SS");
  }

  renderShippingTime(row) {
    return row.item_shipping_expected
      ? moment(row.item_shipping_expected).format("DD-MM-YY")
      : "";
  }

  renderItemCode(row) {
    return (
      <Tooltip title={row.item_code} aria-label={row.item_code}>
        <Link component="button" variant="body2">
          {row.item_code ? row.item_code.substring(0, 15) : ""}
        </Link>
      </Tooltip>
    );
  }

  renderOrderCode(row) {
    return (
      <Tooltip title={row.order_code} aria-label={row.order_code}>
        <Link
          component="button"
          variant="body2"
          //onClick={()=>browserHistory.push(`/admin/order-management/view/${row.order_code}`)}
        >
          {row.order_code ? row.order_code.substring(0, 15) : ""}
        </Link>
      </Tooltip>
    );
  }

  renderActions(row) {
    if (getLoggedInRole() == PERMISSION_LEVELS[0]) return <span>--</span>;
    return (
      <div className="actions" style={{ ...this.state.style_actions }}>
        <IconButton
          variant="contained"
          size="small"
          color="primary"
          className="small-btn"
          onClick={() =>
            browserHistory.push(`/admin/order-management/update/${row.id}`)
          }
        >
          <Edit />
        </IconButton>
        <IconButton
          variant="contained"
          size="small"
          color="secondary"
          className="small-btn"
          onClick={async () => await this.handleOpenConfirm(row.id)}
        >
          <Delete />
        </IconButton>
      </div>
    );
  }

  render() {
    const { order, classes } = this.props;
    const { id } = this.props.params;
    if (!order) return <div />;
    const ORDER_PAGE_COLUMNS = [
      { title: "#", sortable: false, data_field: "index" },
      {
        title: "Ex.Pdf",
        sortable: false,
        data_field: null,
        render: this.renderCheckboxExport
      },
      {
        title: "Customer Email",
        sortable: true,
        data_field: "user_email"
      },
      {
        title: "Customer Name",
        sortable: true,
        data_field: "user_fullname"
      },
      {
        title: "Created at",
        sortable: true,
        data_field: "createdAt",
        render: this.renderTime
      },
      {
        title: "Item name",
        sortable: true,
        data_field: "item_name"
      },
      {
        title: "Item code",
        sortable: true,
        data_field: "item_code",
        render: this.renderItemCode
      },
      {
        title: "Order code",
        sortable: true,
        data_field: "order_code",
        render: this.renderOrderCode
      },
      {
        title: "Amount",
        sortable: true,
        data_field: "item_amount"
      },
      {
        title: "Price/Unit",
        sortable: true,
        data_field: "item_unit_price"
      },
      {
        title: "Total Price",
        sortable: true,
        data_field: "item_total_price"
      },
      {
        title: "Status",
        sortable: true,
        data_field: "item_status"
      },
      {
        title: "Supplier",
        sortable: true,
        data_field: "item_supplier"
      },
      {
        title: "Import Code",
        sortable: true,
        data_field: "item_import_code"
      },
      {
        title: "Export code",
        sortable: true,
        data_field: "item_export_code"
      },
      {
        title: "Shipping fee",
        sortable: true,
        data_field: "item_shipping_fee"
      },
      {
        title: "Shipping (Expected)",
        sortable: true,
        data_field: "item_shipping_expected",
        render: this.renderShippingTime
      },
      {
        title: "Note",
        sortable: true,
        data_field: "item_note"
      },
      {
        title: "Actions",
        sortable: false,
        data_field: null,
        render: this.renderActions
      }
    ];
    
    if (getLoggedInRole() == PERMISSION_LEVELS[0]) {
      ORDER_PAGE_COLUMNS.splice(13, 3);
    }

    return (
      <Paper elevation={1} square className={"container"}>
        <Typography variant="h5" component="h4" className={"mc_page_title"}>
          Order Detail
        </Typography>
        <MaterialTable
          columns={ORDER_PAGE_COLUMNS}
          rows={order.list}
          totals={order.totals}
          getList={query => this.fetchList({ ...query, orderId: id })}
        />
        <Grid container>
          <Grid item xs={8} sm={8} md={8} lg={8}>
            <Grid container spacing={16}>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <Grid container className={"align-center"}>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <InputLabel htmlFor="total_payment-helper">
                      Total Payment:{" "}
                    </InputLabel>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Input
                      name="total_payment"
                      type="number"
                      value={order.totalPayment}
                      disabled
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <Grid container className={"align-center"}>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <InputLabel htmlFor="discount-helper">
                      Discount:{" "}
                    </InputLabel>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Input
                      name="discount"
                      type="number"
                      value={order.discount}
                      disabled
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <Grid container className={"align-center"}>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <InputLabel htmlFor="paied-helper">Paid: </InputLabel>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Input
                      name="paid"
                      type="number"
                      value={this.state.paymentHistory.sumPayments}
                      //onChange={(e) => this.onChangeField('paid', e)}
                      disabled={true}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <Grid container className={"align-center"}>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <InputLabel htmlFor="remaining-helper">
                      Remaining:{" "}
                    </InputLabel>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Input
                      name="remaining"
                      type="number"
                      value={this.remaining(
                        order.totalPayment,
                        order.discount,
                        this.state.paymentHistory.sumPayments
                      )}
                      disabled={true}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4}>
                <Button
                  variant="contained"
                  size="small"
                  color="info"
                  className={classes.margin}
                  onClick={this.handleOpenPaymentDialog}
                >
                  <Payment /> Add payment
                </Button>
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={3}>
                <Button
                  variant="contained"
                  onClick={this.handleOpenExporter}
                  color="primary"
                  disabled={_.isEmpty(this.state.checkList)}
                >
                  Save Pdf
                </Button>
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={3}>
                <ExcelExporterJS
                  fileName="Catalogue"
                  items={this.state.checkList}
                  initialize={this.initialize}
                  disabled={_.isEmpty(this.state.checkList)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4}>
            <Grid container className={"align-center"}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <InputLabel htmlFor="payment-note-helper">
                  Payment note:{" "}
                </InputLabel>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  className={"full-with fullwidth"}
                  multiline
                  rowsMax="4"
                  value={this.state.paymentHistory.joinedNotes}
                  margin="normal"
                  disabled={true}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container className={"align-center"}>
            <Grid item xs={3} sm={3} md={3} lg={3}>
              <PdfExporter
                component={
                  <Catalogue
                    className={`hidden`}
                    width={`${this.state.page.width}px`}
                    height={`${this.state.page.height}px`}
                  />
                }
                open={this.state.openExporter}
                onClose={this.handleCloseExporter}
                id="pdfExporter"
                orderData={{
                  items: this.state.checkList,
                  userData: this.state.userData
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* CONFIRMATION DIALOG */}
        <Dialog
          open={this.state.openConfirm}
          onClose={this.handleCloseConfirm}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">{"Warning!"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this item?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseConfirm} color="primary">
              Cancel
            </Button>
            <Button
              onClick={this.handleActionConfirm}
              color="primary"
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        {/* Add payment dialog */}
        <Dialog
          open={this.state.openPaymentDialog}
          onClose={this.handleClosePaymentDialog}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Add payment"}
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={16}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid container className={"align-center"}>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <InputLabel htmlFor="total_payment-helper">
                      Payment amount:{" "}
                    </InputLabel>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Input
                      name="new_payment"
                      type="number"
                      className="full-width"
                      value={this.state.paymentData.payment_value}
                      onChange={e =>
                        this.onChangePaymentField(
                          "paymentData",
                          "payment_value",
                          e
                        )
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid container className={"align-center"}>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <InputLabel htmlFor="discount-helper">
                      Payment note:{" "}
                    </InputLabel>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <TextField
                      multiline
                      rowsMax="4"
                      value={this.state.paymentData.payment_note}
                      margin="normal"
                      className="full-width"
                      onChange={e =>
                        this.onChangePaymentField(
                          "paymentData",
                          "payment_note",
                          e
                        )
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.handleClosePaymentDialog(false)}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={() => this.handleClosePaymentDialog(true)}
              color="primary"
              autoFocus
            >
              Add
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    );
  }
}

const styles = theme => ({
  button: {
    margin: theme.spacing.unit
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit
  }
});

export default withStyles(styles)(OrderView);

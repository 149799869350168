import Immutable from 'seamless-immutable';
import {GET_ADMIN_PROFILE_CHANGE} from '../actions/admin';

const INIT_STATE = Immutable({
    adminLoggedProfile: null,
});

export default function (state = INIT_STATE, action) {
    switch (action.type) {
        case GET_ADMIN_PROFILE_CHANGE:
            return Object.assign({}, state, {adminLoggedProfile: action.payload});
        default:
            return state;
    }
}

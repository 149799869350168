import api from '../utils/api';
import {toast} from 'react-toastify';
import {enableLoading, loadingFailed, loadingSuccess} from '../actions/utils';
import {browserHistory} from 'react-router';

export const FETCH_ORDER_SUCCESS = `FETCH_ORDER_SUCCESS`;
export const FETCH_ALL_PAYMENT_INRANGE = `financal/FETCH_ALL_PAYMENT_INRANGE`;

export const getAllPaymentHistoryInRange = (data) => {
    return async (dispatch) => {
        try {
            await dispatch(enableLoading());
            let res = await api.getAllPaymentHistoryInRange(data) || {};
            if (res.data && res.data.status === "success") {
                const {data, message} = res.data;
                if (message) toast.success(message);
                if (data) await dispatch(getAllPaymentHistoryInRangeSuccess(data));;
                await dispatch(loadingSuccess());
            } else {
                if (res.data && res.data.message) toast.error(res.data.message);
                await dispatch(loadingFailed());
            }
        } catch (e) {
            console.log(e);
            await dispatch(loadingFailed());
            toast.error(e.message);
        }
    }
}

export const getAllPaymentHistoryInRangeSuccess = (data) => {
    return {
        type: FETCH_ALL_PAYMENT_INRANGE,
        payload: data
    }
}

export const getAllListItemsInOrder = (data) => {
    return async (dispatch) => {
        try {
            await dispatch(enableLoading());
            let res = await api.getAllListItemsInOrder(data) || {};
            if (res.data && res.data.status === "success") {
                const {data, message} = res.data;
                if (message) toast.success(message);
                if (data) await dispatch(getAllListOrdersSuccess(data));;
                await dispatch(loadingSuccess());
            } else {
                if (res.data && res.data.message) toast.error(res.data.message);
                await dispatch(loadingFailed());
            }
        } catch (e) {
            console.log(e);
            await dispatch(loadingFailed());
            toast.error(e.message);
        }
    }
}

export const getAllListOrders = (data) => {
    return async (dispatch) => {
        try {
            await dispatch(enableLoading());
            let res = await api.getAllListOrders(data) || {};
            if (res.data && res.data.status === "success") {
                const {data, message} = res.data;
                if (message) toast.success(message);
                if (data) await dispatch(getAllListOrdersSuccess(data));;
                await dispatch(loadingSuccess());
            } else {
                if (res.data && res.data.message) toast.error(res.data.message);
                await dispatch(loadingFailed());
            }
        } catch (e) {
            console.log(e);
            await dispatch(loadingFailed());
            toast.error(e.message);
        }
    }
}

export const getAllListOrdersSuccess = (data) => {
    return {
        type: FETCH_ORDER_SUCCESS,
        payload: data
    }
}

export const getItemData = (data) => {
    return async (dispatch) => {
        try {
            await dispatch(enableLoading());
            let res = await api.getItemData(data) || {};
            if (res.data && res.data.status === "success") {
                const {data, message} = res.data;
                if (message) toast.success(message);
                await dispatch(loadingSuccess());
                if (data) return data;
            } else {
                if (res.data && res.data.message) toast.error(res.data.message);
                await dispatch(loadingFailed());
            }
        } catch (e) {
            console.log(e);
            await dispatch(loadingFailed());
            toast.error(e.message);
        }
    }
}

export const getOrderMeta = (id) => {
    return async (dispatch) => {
        try {
            await dispatch(enableLoading());
            let res = await api.getOrderMeta(id) || {};
            if (res.data && res.data.status === "success") {
                const {data, message} = res.data;
                if (message) toast.success(message);
                await dispatch(loadingSuccess());
                if (data) return data;
            } else {
                if (res.data && res.data.message) toast.error(res.data.message);
                await dispatch(loadingFailed());
            }
        } catch (e) {
            console.log(e);
            await dispatch(loadingFailed());
            toast.error(e.message);
        }
    }
}

export const getAllUserForFinance = (data) => {
    return async (dispatch) => {
        try {
            await dispatch(enableLoading());
            let res = await api.getAllUserForFinance(data) || {};
            if (res.data && res.data.status === "success") {
                const {data, message} = res.data;
                if (message) toast.success(message);
                await dispatch(loadingSuccess());
                if (data) return Promise.resolve(data);
            } else {
                if (res.data && res.data.message) toast.error(res.data.message);
                await dispatch(loadingFailed());
            }
        } catch (e) {
            console.log(e);
            await dispatch(loadingFailed());
            toast.error(e.message);
        }
    }
}

export const getAllListOrdersForFinanceWithoutPaging = (data) => {
    return async (dispatch) => {
        try {
            await dispatch(enableLoading());
            let res = await api.getAllListOrdersForFinanceWithoutPaging(data) || {};
            if (res.data && res.data.status === "success") {
                const {data, message} = res.data;
                if (message) toast.success(message);
                await dispatch(loadingSuccess());
                if (data) return Promise.resolve(data);
            } else {
                if (res.data && res.data.message) toast.error(res.data.message);
                await dispatch(loadingFailed());
            }
        } catch (e) {
            console.log(e);
            await dispatch(loadingFailed());
            toast.error(e.message);
        }
    }
}




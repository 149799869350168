import React from 'react';
import DashboardLayout from './DashboardLayout';
import UserDashboardLayout from './UserDashboardLayout';

const SwitchLayout = (props) => {
    const {params : {role}} = props;
    if (
        role && role === "admin" ||
        role && role === "employee"
    ) {
        return <DashboardLayout {...props} />;
    } else if (role && role === "user") {
        return <UserDashboardLayout {...props}/>;
    }
}

export default SwitchLayout;
import React from 'react';
import {connect} from 'react-redux';
import {browserHistory} from 'react-router';
import {LoginPage, RegisterPage, ActivatePage, ResetPassword, ChangePassword} from '../components/Auth';
import {login, activate, register, resetPassword, changePassword} from '../actions/user';

class AuthPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.onLogin = this.onLogin.bind(this)
        this.onActivate = this.onActivate.bind(this)
        this.onRegister = this.onRegister.bind(this)
        this.onResetPassword = this.onResetPassword.bind(this)
        this.onChangePassword = this.onChangePassword.bind(this)
    }

    onLogin(data) {
        return this.props.login(data);
    }

    onRegister(data) {
        return this.props.register(data)
    }
    
    onActivate(data) {
        return this.props.activate(data);
    }

    onResetPassword(data) {
        return this.props.resetPassword(data);
    }

    onChangePassword(data) {
        return this.props.changePassword(data);
    }

    render() {
        const {params} = this.props;
        
        switch (params.action) {
            case "login":
                return (
                    <LoginPage onChange={this.onChange} onSubmit={this.onLogin}/>
                );
            case "register":
                return (
                    <RegisterPage onChange={this.onChange} onSubmit={this.onRegister}/>
                );
            case "reset-password":
                return (
                    <ResetPassword onChange={this.onChange} onSubmit={this.onResetPassword}/>
                );
            case "change-password":
                return (
                    <ChangePassword onChange={this.onChange} onSubmit={this.onChangePassword} location={this.props.location}/>
                );
            case "activate":
                return (
                    <ActivatePage onSubmit={this.onActivate} location={this.props.location}/>
                );
            default:
                return <div>{browserHistory.push('/auth/login')}</div>
        }
        
    }
}

const mapStateToProps = (state) => ({...state});

const mapDispatchToProps = (dispatch) => ({
    login: async (data) => {
        await dispatch(login(data));
    },
    resetPassword: async (data) => {
        await dispatch(resetPassword(data));
    },
    changePassword: async (data) => {
        await dispatch(changePassword(data));
    },
    activate: async (data) => {
        await dispatch(activate(data));
    },
    register: async (data) => {
        await dispatch(register(data));
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(AuthPage);
import React from "react";
import {
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  TableSortLabel
} from "@material-ui/core";

class TableBodier extends React.Component {
  constructor(props) {
    super(props);
  }
  renderRow(row, cols) {
    let result = [];
    for (let colData of cols) {
      result.push(
        <TableCell
          key={`${row.id}:${cols.indexOf(colData)}`}
          padding={"none"}
          className={`vertical-align-middle ${
            colData && colData.className ? colData.className : ``
          }`}
        >
          {colData.render ? colData.render(row) : row[colData.data_field]}
        </TableCell>
      );
    }
    return result;
  }

  renderRows(rows, cols) {
    return rows.map((r, i) => {
      return (
        <TableRow hover key={i}>
          {this.renderRow(r, cols)}
        </TableRow>
      );
    });
  }

  render() {
    let { rows, columns } = this.props;
    rows = rows || [];
    columns = columns || [];
    return <TableBody>{this.renderRows(rows, columns)}</TableBody>;
  }
}

export default TableBodier;

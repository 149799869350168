import React from 'react';
import {
    Button, Grid, Card, CardActionArea, 
    CardActions, CardContent, Divider, 
    CardMedia, Typography, TextField
} from '@material-ui/core';
import './LoginPage.css';
import {browserHistory} from 'react-router';

class ResetPassword extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            email: '',
            password: '',
        }
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    handleChange(field, e) {
        this.state[field] = e.currentTarget.value;
        this.setState({...this.state});
    }

    onSubmit() {
        return this.props.onSubmit(this.state)
    }

    render() {
        return (
            <Grid container className={"login-page-wrapper"} spacing={16}>
                <Card className={"login-form"} sm={12} md={8} lg={6} className={"any-center min-with-300"}>
                    <CardContent>
                        <Typography variant="h6" color="inherit" className="login-title">RESET PASSWORD</Typography>
                        <Divider light />
                        <Grid container>
                            <Grid item xs={12} md={12} lg={12}>
                                <TextField
                                required
                                error={!validateEmail(this.state.email)}
                                id="email"
                                label="Email"
                                className="full-width"
                                value={this.state.email}
                                onChange={(e) => this.handleChange('email', e)}
                                margin="normal"
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardContent>
                        <Grid container  direction="row"
                            justify="center"
                            alignItems="center">
                            <Button variant="contained" color="primary" disabled={!validateEmail(this.state.email)} onClick={this.onSubmit}>Send Request</Button>
                        </Grid>
                    </CardContent>
                    <CardContent>
                        <Grid container  direction="row"
                            justify="center"
                            alignItems="center">
                            <a href="#" onClick={()=>browserHistory.push('/auth/login')}>Back to Login page</a>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        )
    }
}

const validateEmail = (email) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export default ResetPassword;
import api from '../utils/api';
import {toast} from 'react-toastify';
import {enableLoading, loadingFailed, loadingSuccess} from '../actions/utils';
import {browserHistory} from 'react-router';

export const CREATE_SUPPLIER_SUCCESS = `CREATE_SUPPLIER_SUCCESS`;
export const FETCH_SUPPLIER_SUCCESS = `FETCH_SUPPLIER_SUCCESS`;
export const FETCH_ALL_SUPPLIER_SUCCESS = `FETCH_ALL_SUPPLIER_SUCCESS`;

export const getSupplierData = (data) => {
    return async (dispatch) => {
        try {
            await dispatch(enableLoading());
            let res = await api.getSupplierData(data) || {};
            if (res.data && res.data.status === "success") {
                const {data, message} = res.data;
                await dispatch(loadingSuccess());
                if (data) return data;
            } else {
                if (res.data && res.data.message) toast.error(res.data.message);
                await dispatch(loadingFailed());
            }
        } catch (e) {
            console.log(e);
            await dispatch(loadingFailed());
            toast.error(e.message);
        }
    }
}

export const deleteSupplier = (data) => {
    return async (dispatch) => {
        try {
            await dispatch(enableLoading());
            let res = await api.deleteSupplier(data) || {};
            if (res.data && res.data.status === "success") {
                const {data, message} = res.data;
                if (message) toast.success(message);
                await dispatch(loadingSuccess());
            } else {
                if (res.data && res.data.message) toast.error(res.data.message);
                await dispatch(loadingFailed());
            }
        } catch (e) {
            console.log(e);
            await dispatch(loadingFailed());
            toast.error(e.message);
        }
    }
}

export const fetchAllSupplier = (data) => {
    return async (dispatch) => {
        try {
            await dispatch(enableLoading());
            let res = await api.fetchAllSupplier(data) || {};
            if (res.data && res.data.status === "success") {
                const {data, message} = res.data;
                //if (message) toast.success(message);
                await dispatch(loadingSuccess());
                //if (data) browserHistory.push('/admin/supplier-management/list');
                if (data) await dispatch(fetchAllSupplierSuccess(data));
            } else {
                if (res.data && res.data.message) toast.error(res.data.message);
                await dispatch(loadingFailed());
            }
        } catch (e) {
            console.log(e);
            await dispatch(loadingFailed());
            toast.error(e.message);
        }
    }
}

export const fetchAllSupplierNoQuery = () => {
    return async (dispatch) => {
        try {
            await dispatch(enableLoading());
            let res = await api.fetchAllSupplierNoQuery() || {};
            if (res.data && res.data.status === "success") {
                const {data, message} = res.data;
                await dispatch(loadingSuccess());
                if (data) return data;
            } else {
                if (res.data && res.data.message) toast.error(res.data.message);
                await dispatch(loadingFailed());
            }
        } catch (e) {
            console.log(e);
            await dispatch(loadingFailed());
            toast.error(e.message);
        }
    }
}

export const fetchAllSupplierSuccess = (data) => {
    return {
        type: FETCH_ALL_SUPPLIER_SUCCESS,
        payload: data
    }
}

export const createSupplier = (data) => {
    return async (dispatch) => {
        try {
            await dispatch(enableLoading());
            let res = await api.createSupplier(data) || {};
            if (res.data && res.data.status === "success") {
                const {data, message} = res.data;
                if (message) toast.success(message);
                await dispatch(loadingSuccess());
                if (data) browserHistory.push('/admin/supplier-management/list');
            } else {
                if (res.data && res.data.message) toast.error(res.data.message);
                await dispatch(loadingFailed());
            }
        } catch (e) {
            console.log(e);
            await dispatch(loadingFailed());
            toast.error(e.message);
        }
    }
}
import React from "react";
import {
  Button,
  Grid,
  Card,
  CardContent,
  Divider,
  CardMedia,
  Typography,
  TextField
} from "@material-ui/core";
import "./ActivatePage.css";
import { browserHistory } from "react-router";
import userImage from "../../assets/images/male-user.png";

class ActivatePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      retypePassword: ""
    };
    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    const { query } = this.props.location;
    this.state.email = query.email;
  }

  handleChange(field, e) {
    this.state[field] = e.currentTarget.value;
    this.setState({ ...this.state });
  }

  onSubmit() {
    const { query } = this.props.location;
    let formData = { ...this.state, token: query.token };
    return this.props.onSubmit(formData);
  }

  render() {
    return (
      <Grid container className={"login-page-wrapper"} spacing={16}>
        <Card
          className={"login-form"}
          sm={12}
          md={6}
          lg={4}
          className={"any-center"}
        >
          <CardMedia
            component="img"
            alt="Contemplative Reptile"
            height="140"
            image={userImage}
            title="Contemplative Reptile"
          />
          <CardContent>
            <Typography variant="h6" color="inherit" className="login-title">
              ACTIVATE YOUR ACCOUNT
            </Typography>
            <Divider light />
            <Grid container>
              <Grid item xs={12} md={12} lg={12}>
                <TextField
                  required
                  error={!validateEmail(this.state.email)}
                  id="email"
                  label="Email"
                  className="full-width"
                  value={this.state.email}
                  onChange={e => this.handleChange("email", e)}
                  margin="normal"
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <TextField
                  required
                  error={validatePasswordIndivi(this.state.password)}
                  id="password"
                  label="Password"
                  type="password"
                  className="full-width"
                  value={this.state.password}
                  onChange={e => this.handleChange("password", e)}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <TextField
                  required
                  error={this.state.password != this.state.retypePassword}
                  id="retypePassword"
                  label="Retype Password"
                  type="password"
                  className="full-width"
                  value={this.state.retypePassword}
                  onChange={e => this.handleChange("retypePassword", e)}
                  margin="normal"
                  helperText={
                    this.state.password != this.state.retypePassword
                      ? "Password not match."
                      : ""
                  }
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardContent>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Button
                variant="contained"
                color="primary"
                disabled={
                  !validateEmail(this.state.email) ||
                  validatePassword(
                    this.state.password,
                    this.state.retypePassword
                  )
                }
                onClick={this.onSubmit}
              >
                Activate
              </Button>
            </Grid>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <a href="#" onClick={() => browserHistory.push("/auth/login")}>
                Back to Login page
              </a>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    );
  }
}

const validateEmail = email => {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const validatePassword = (pass, retypePass) => {
  return (
    !pass ||
    pass.length < 6 ||
    !retypePass ||
    retypePass.length < 6 ||
    pass != retypePass
  );
};

const validatePasswordIndivi = pass => {
  return !pass || pass.length < 6;
};

export default ActivatePage;

import Immutable from 'seamless-immutable';
import {FETCH_ALL_SUPPLIER_SUCCESS} from '../actions/supplier';

const INIT_STATE = Immutable({
    supplierData: null,
    list: [],
    totals: 0,
});

export default function (state = INIT_STATE, action) {
    switch (action.type) {
        case FETCH_ALL_SUPPLIER_SUCCESS:
            return Object.assign({}, state, {list: action.payload.list, totals: action.payload.totals});
        default:
            return state;
    }
}

import React from 'react';
import {
    Dialog,
	DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from '@material-ui/core';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
window.html2canvas = html2canvas;

class PdfExporter extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        }
    }

    savePdf = async () => {
        const source = document.getElementById(this.props.id)
        const canvas = await html2canvas(source, {
            dpi: 300, // Set to 300 DPI
            scale: 3, // Adjusts your resolution
        });
        var img = canvas.toDataURL("image/jpeg", 1);
        var doc = new jsPDF('p', 'in');
        doc.addImage(img, 'JPEG', 0.5, 0.5, 7.27, 10.69);
        doc.save('catalogue.pdf');

        // const source = document.getElementById(this.props.id);
        // const width = 8.27, height = 11.69, padding = 0.5, dpi = 300;
        // var pdf = new jsPDF('p', 'in');
        
        // for (var i = 1; i <= source.clientHeight/(height * dpi); i++) {
        //     const canvas = await html2canvas(source, {
        //         dpi, // Set to 300 DPI
        //         scale: 3, // Adjusts your resolution
        //     });
        //     var img = canvas.toDataURL("image/jpeg", 1);
            
        //     pdf.addPage(width, height)
        //     pdf.setPage(i);
        //     pdf.addImage(img, 'JPEG', padding, padding, width - padding * 2, height * i - padding * 2);
        // }
        // pdf.save('catalogue.pdf');

        // var quotes = document.getElementById(this.props.id);
        // html2canvas(quotes)
        // .then((canvas) => {
        //         //! MAKE YOUR PDF
        //         var pdf = new jsPDF('p', 'pt', 'letter');

        //         for (var i = 0; i <= quotes.clientHeight/980; i++) {
        //             //! This is all just html2canvas stuff
        //             var srcImg  = canvas;
        //             var sX      = 0;
        //             var sY      = 980*i; // start 980 pixels down for every new page
        //             var sWidth  = 900;
        //             var sHeight = 980;
        //             var dX      = 0;
        //             var dY      = 0;
        //             var dWidth  = 900;
        //             var dHeight = 980;

        //             window.onePageCanvas = document.createElement("canvas");
        //             window.onePageCanvas.setAttribute('width', 900);
        //             window.onePageCanvas.setAttribute('height', 980);
        //             var ctx = window.onePageCanvas.getContext('2d');
        //             // details on this usage of this function: 
        //             // https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Tutorial/Using_images#Slicing
        //             ctx.drawImage(srcImg,sX,sY,sWidth,sHeight,dX,dY,dWidth,dHeight);

        //             // document.body.appendChild(canvas);
        //             var canvasDataURL = window.onePageCanvas.toDataURL("image/png", 1.0);

        //             var width         = window.onePageCanvas.width;
        //             var height        = window.onePageCanvas.clientHeight;

        //             //! If we're on anything other than the first page,
        //             // add another page
        //             if (i > 0) {
        //                 pdf.addPage(612, 791); //8.5" x 11" in pts (in*72)
        //             }
        //             //! now we declare that we're working on that page
        //             pdf.setPage(i+1);
        //             //! now we add content to that page!
        //             pdf.addImage(canvasDataURL, 'PNG', 20, 40, (width*.62), (height*.62));

        //         }
        //         //! after the for loop is finished running, we save the pdf.
        //         pdf.save('Test.pdf');
        //     });
    }
    
    render() {
        const { open, onClose, id, title, component, orderData } = this.props;
        return (
            <Dialog
            open={open}
            onClose={onClose}
            >
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    { React.cloneElement(component, {anchorId: id, orderData: orderData}) }
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        Close
                    </Button>
                    <Button onClick={this.savePdf} color="primary" autoFocus>
                        Export
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default PdfExporter;
import React from 'react';
import { MenuItem, Input, Menu, Button, Grid, Paper, TextField, InputAdornment } from '@material-ui/core';
import { Clear, ArrowDropDown } from '@material-ui/icons';
import './ReactSelect.css';
import api from '../../../utils/api';

export default class ReactSelect extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [
				{ id: 1, email: 'cuong.tran@grr.la' },
				{ id: 2, email: 'cu2@grr.la' }
			],
			selected: undefined,
			searchText: '',
			showList: false
		};
	}

	handleChange = event => {
		this.setState({ selected: event.target.value, name: event.target.name });
	};

	onSearchChange = async event => {
		await this.setState({ searchText: event.target.value });
		const rs = await api.getUserListForFinance({textSearch: this.state.searchText.trim()});
		let {data : {list = []}} = rs.data;
		list = list.map(l => ({id: l.id, email: l.email}))
		await this.setState({data: [...list]});
		await this.doShowList();
		console.log(this.inputFocus)
	};

	doShowList = event => {
		this.setState({ showList: true, anchorEl: this.inputFocus.current });
	};

	doHideList = async () => {
		await this.setState({ showList: false });
	};

	doSelectOptions = (id, email) => {
		this.setState({ selected: id, searchText: email });
		this.props.onChange({target: {value:id}});
		this.doHideList();
	};

	doClearSelection = () => {
		this.setState({ selected: undefined });
	};

	renderOptions() {
		return this.state.data.map((dt, i) => {
			return (
				<MenuItem
					label=''
					value={dt.id}
					key={dt.id}
					name={dt.email}
					onClick={() => this.doSelectOptions(dt.id, dt.email)}
				>
					{dt.id} - {dt.email}
				</MenuItem>
			);
		});
	}

	renderValue() {
		let selected = this.state.data.find(dt => dt.id === this.state.selected);
		if (!selected) return;
		return (
			<Button
				aria-label='react-input-value'
			>
				{JSON.stringify(selected)} <Clear onClick={this.doClearSelection} />
			</Button>
		);
	}

	render() {
		return (
			<Grid container>
				<Grid item className="position-relative full-width" >
					<TextField
						ref={ref => this.inputFocus = ref}
						className='react-select'
						label="Customer code"
						value={`${this.state.searchText.trim()}`}
						onChange={this.onSearchChange}
						autoFocus={true}
						InputProps={{
						endAdornment: <InputAdornment position="end"><ArrowDropDown/></InputAdornment>,
						}}
					/>
					{
						this.state.showList ?
						<Paper
							// open={this.state.showList}
							// onClose={this.doHideList}
							// anchorEl={this.state.anchorEl}
							className='select-list'
						>
							{this.renderOptions()}
						</Paper> : ''
					}
				</Grid>
			</Grid>
		);
	}
}

import React from 'react';
import {browserHistory} from 'react-router';
import {
    Paper, Typography, Grid, Button,
    TextField, FormControl, InputLabel,
    Select, Input, MenuItem,
} from '@material-ui/core';
import {toast} from 'react-toastify';
import './User.css';
import _ from 'lodash';

class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {
                role: 'customer', // default is customer
                first_name: '',
                last_name: '',
                email: '',
                password: '',
                password_retype: '',
                phone: '',
                address_1: '',
                address_2: '',
                discount: 0,
                company_name: '',
                tax_id: '',
            },

        }

        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        this.getUserData();
    }

    async getUserData() {
        let userData = await this.props.getCurrentProfile();
        delete userData.createdAt;
        delete userData.updatedAt;
        delete userData.status;
        this.setState({formData: {...this.state.formData, ...userData}})
    }

    onSubmit() {
        console.log(`Submit!`);
        console.log(this.state.formData)
        let data = {...this.state.formData};
        if (data && _.isEmpty(data.password)) {
            delete data.password;
        }
        if (data.password !== data.password_retype) {
            return toast.error('Password and Password retype are not the same.');
        }
        if (data.password.length < 6) {
            return toast.error('Password atleast 6 characters.');
        }
        this.props.updateCurrentProfile(this.state.formData);
    }

    handleChange(field, e) {
        this.state.formData[field] = e.currentTarget.value;
        this.setState({formData: {...this.state.formData}});
    }

    render() {
        return (
            <Paper  elevation={1} square className={"container"}>
                <Typography variant="h5" component="h4" className={"mc_page_title"}>
                    User Update
                </Typography>
                <Grid container spacing={16}>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <TextField
                            className={"full-width"}
                            label="First name"
                            type="text"
                            margin="normal"
                            value={this.state.formData.first_name}
                            onChange={(e) => this.handleChange('first_name', e)}
                            />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <TextField
                            className={"full-width"}
                            label="Last name"
                            type="text"
                            margin="normal"
                            value={this.state.formData.last_name}
                            onChange={(e) => this.handleChange('last_name', e)}
                            />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <TextField
                            className={"full-width"}
                            label="Email"
                            type="text"
                            margin="normal"
                            value={this.state.formData.email}
                            onChange={(e) => this.handleChange('email', e)}
                            disabled={true}
                            />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <FormControl className={`full-width missing-margin`}>
                            <InputLabel htmlFor="role-helper">Role</InputLabel>
                            <Select
                                value={this.state.formData.role}
                                onChange={(e) => this.handleSelectChange('role', e)}
                                input={<Input name="role" id="role-helper" />}
                                className={`full-width`}
                                disabled={true}
                            >
                                <MenuItem value={'admin'} selected>Admin</MenuItem>
                                <MenuItem value={'employee'}>Employee</MenuItem>
                                <MenuItem value={'customer'}>Customer</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <TextField
                            className={"full-width"}
                            label="Password"
                            type="password"
                            margin="normal"
                            value={this.state.formData.password}
                            onChange={(e) => this.handleChange('password', e)}
                            />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <TextField
                            className={"full-width"}
                            label="Password Retype"
                            type="password"
                            margin="normal"
                            value={this.state.formData.password_retype}
                            onChange={(e) => this.handleChange('password_retype', e)}
                            />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <TextField
                            className={"full-width"}
                            label="Phone number"
                            type="text"
                            margin="normal"
                            value={this.state.formData.phone}
                            onChange={(e) => this.handleChange('phone', e)}
                            />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <TextField
                            className={"full-width"}
                            label="Address 1"
                            type="text"
                            margin="normal"
                            value={this.state.formData.address_1}
                            onChange={(e) => this.handleChange('address_1', e)}
                            />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <TextField
                            className={"full-width"}
                            label="Address 2"
                            type="text"
                            margin="normal"
                            value={this.state.formData.address_2}
                            onChange={(e) => this.handleChange('address_2', e)}
                            />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <TextField
                            className={"full-width"}
                            label="Discount"
                            type="text"
                            margin="normal"
                            value={this.state.formData.discount}
                            onChange={(e) => this.handleChange('discount', e)}
                            disabled
                            />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <TextField
                            className={"full-width"}
                            label="Company name"
                            type="text"
                            margin="normal"
                            value={this.state.formData.company_name}
                            onChange={e => this.handleChange("company_name", e)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <TextField
                        className={"full-width"}
                        label="Tax ID"
                        type="text"
                        margin="normal"
                        value={this.state.formData.tax_id}
                        onChange={e => this.handleChange("tax_id", e)}
                        />
                    </Grid>
                    <Grid container spacing={16} className={`footer-padding`}>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                            <Button variant="contained" color="primary" className={`pull-right`} onClick={this.onSubmit}>
                                Update
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                            <Button variant="contained" color="default" onClick={() => browserHistory.goBack()}>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        );
    }
}

export default Profile;
import React from 'react';
import {FinancalList} from '../components/Financal';
import {connect} from 'react-redux';
import { 
	getAllListOrdersForFinance, getAllListItemsInOrder,
	getItemData, getOrderMeta, saveOrderMeta,
} from "../actions/order";
import {
	getAllUserForFinance,
	getAllListOrdersForFinanceWithoutPaging,
} from "../actions/financal";
import {
	getAllPaymentHistoryInRange,
} from "../actions/payment";

class FinancalPage extends React.Component {

    renderComponent(action) {
		switch (action.toUpperCase()) {
			case "LIST":
				return (
					<FinancalList {...this.props} />
				)
			default:
				return (
					<FinancalList {...this.props} />
				)
		}
	}

	render() {
		const { action } = this.props.params;
		return (
			<div className={"mc_page"}>
				{this.renderComponent(action)}
			</div>
		);
	}
}

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
	getAllListOrdersForFinance: async (data, moreData) => {
		return await dispatch(getAllListOrdersForFinance(data, moreData));
	},
	getAllListOrdersForFinanceWithoutPaging: async (data, moreData) => {
		return await dispatch(getAllListOrdersForFinanceWithoutPaging(data, moreData));
	},
	getAllListItemsInOrder: async data => {
		return await dispatch(getAllListItemsInOrder(data));
	},
	getItemData: async (data) => {
		return await dispatch(getItemData(data));
	},
	getOrderMeta: async (data) => {
		return await dispatch(getOrderMeta(data));
	},
	getAllUserForFinance: async () => {
		return await dispatch(getAllUserForFinance());
	},
	getAllPaymentHistoryInRange: async (data) => {
		return await dispatch(getAllPaymentHistoryInRange(data));
	},
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(FinancalPage);
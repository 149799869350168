import React from "react";
import { browserHistory } from "react-router";
import {connect} from "react-redux";
import {
  Paper,
  Typography,
  Grid,
  Button,
  TextField,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Divider,
	Table, TableBody, TableCell, TableHead, TableRow,
} from "@material-ui/core";
import Fab from '@material-ui/core/Fab';
import { Add as AddIcon, Delete as DeleteIcon} from "@material-ui/icons";
import { validateCategory } from "../../utils/validatation";
import "./Order.css";
import AnyUpload from "../common/AnyUpload/AnyUpload";
import uniqueString from "unique-string";
import { withStyles } from '@material-ui/core/styles';

class OrderCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        order_code: '',
        items: [
        ],
			},
			openDialog: false,
			openConfirm: false,
			selectedItem: null,
			itemData: {
				item_email: '',
				item_name: '',
				item_description: '',
				item_amount: '',
				item_attachment: '',
				item_code: '',
			}
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.handleDialogCancel = this.handleDialogCancel.bind(this);
    this.handleDialogAddItem = this.handleDialogAddItem.bind(this);
    this.deleteOrderItem = this.deleteOrderItem.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.handleOpenConfirm = this.handleOpenConfirm.bind(this);
    this.handleCloseConfirm = this.handleCloseConfirm.bind(this);
    this.handleActionConfirm = this.handleActionConfirm.bind(this);
    this.onAttachmentUploaded = this.onAttachmentUploaded.bind(this);
    this.validateItem = this.validateItem.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    // Generate a code for new order
		this.state.formData.order_code = "order-" + uniqueString();
		//let {userData: {role}} = this.props.user;
		let userData = this.props.user.userData;
		let role = userData.role;

		userData = userData || {};
		if (userData && userData.email) {
			this.state.itemData.item_email = userData.email;
		}
		if (role && role ==="admin" || role && role === "employee") {
			this.createOrder = this.props.adminCreateOrder;
		} else if (role && role ==="customer") {
			this.createOrder = this.props.userCreateOrder;
		} else {
			this.createOrder = console.log
		}
    //Fetch category for create product
		this.setState({ ...this.state });
	}
	
	openDialog() {
		let {userData} = this.props.user;
		userData = userData || {};
		if (userData && userData.email) {
			this.state.itemData.item_email = this.state.itemData.item_email ? this.state.itemData.item_email : userData.email;
		}
		this.state.openDialog = true;
		this.state.itemData.item_code = "item-" + uniqueString();
		this.setState({...this.state})
	}

	handleOpenConfirm(index) {
		this.state.openConfirm = true;
		this.state.selectedItem = index;
		this.setState({...this.state})
	}

	handleCloseConfirm() {
		this.state.openConfirm = false;
		this.setState({...this.state})
	}

	async handleActionConfirm() {
		await this.deleteOrderItem(this.state.selectedItem)
		await this.setState({selectedItem: null})
		await this.handleCloseConfirm();
	}

	handleCloseDialog() {
		this.setState({openDialog: false})
	}

	handleDialogAddItem() {
		//validate
		if (!this.validateItem()) {
			return;
		}

		this.state.formData.items.push(this.state.itemData);
		this.state.itemData = {
			...this.state.itemData,
			item_name: '',
			item_description: '',
			item_amount: '',
			item_attachment: '',
			item_code: '',
		}
		this.state.openDialog = false;
		this.setState({...this.state});
	}

	deleteOrderItem(index) {
		this.state.formData.items.splice(index, 1);
		this.setState({...this.state});
	}

	handleDialogCancel() {
		this.setState({itemData : {
			item_email: '',
			item_name: '',
			item_description: '',
			item_amount: '',
			item_attachment: '',
		}});
		this.handleCloseDialog();
	}

  onSubmit() {
    console.log(`Submit!`);
		console.log(this.state.formData);
		const { formData } = this.state;
		const { items } = formData;
		if (!items.length > 0) {
			return;
		}
    return this.createOrder(formData);
  }

  handleChange(field, e) {
    this.state.formData[field] = e.currentTarget.value;
    this.setState({ formData: { ...this.state.formData } });
	}
	
	handleChangeItemData(field, e) {
		this.state.itemData[field] = e.currentTarget.value;
    this.setState({ itemData: { ...this.state.itemData } });
	}

  onAttachmentUploaded(uploaded) {
    let { itemData } = this.state;
    itemData.item_attachment = uploaded.uploaded_path;
    this.setState({ itemData });
	}
	
	validateItem() {
		const {item_name, item_amount, item_description} = this.state.itemData;
		if (!item_name || !item_amount || !item_description) {
			return false;
		} else {
			return true;
		}
	}

  render() {
		const {classes} = this.props;
		let {userData} = this.props.user;
		userData = userData || {};
    return (
      <Paper elevation={1} square className={"container"}>
				<Grid container>
					<Grid item xs={12} sm={6} md={6} lg={6}>
						<Typography variant="h5" component="h4" className={"mc_page_title"}>
							Order Create
						</Typography>	
						<Typography variant="h6" gutterBottom>
							Items ({this.state.formData.items.length})
						</Typography>	
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={6}>
						<Button
							variant="contained"
							color="primary"
							className={`pull-right`}
							onClick={this.onSubmit}
							style={{margin: 7}}
							size={"small"}
							disabled={! this.state.formData.items.length > 0}
						>
							Submit
						</Button>
						<Button
							variant="contained"
							color="default"
							className={`pull-right`}
							onClick={() =>
								browserHistory.push(`/admin/order-management/list`)
							}
							style={{margin: 7}}
							size={"small"}
						>
							Cancel
						</Button>
					</Grid>
				</Grid>
			
				<Divider/>
        <Grid container>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<Paper>
							<div className={"wrapper-scroll"}>
								<div className={"table-scroll"}>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell className={`vertical-align-middle smaller-width`}>Orderer Email</TableCell>
											<TableCell className={`vertical-align-middle smaller-width`}>Item Name</TableCell>
											<TableCell className={`vertical-align-middle smaller-width`}>Item Description</TableCell>
											<TableCell className={`vertical-align-middle smaller-width`} align="right">Item Amount</TableCell>
											<TableCell className={`vertical-align-middle attachment-width`} align="right">Item Attachment</TableCell>
											<TableCell className={`vertical-align-middle smaller-width`} align="right">Item Action</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{this.state.formData.items.map((row, index) => (
											<TableRow key={index}>
												<TableCell component="th" scope="row">{row.item_email}</TableCell>
												<TableCell>{row.item_name}</TableCell>
												<TableCell>{row.item_description}</TableCell>
												<TableCell align="right">{row.item_amount}</TableCell>
												<TableCell align="right">{row.item_attachment}</TableCell>
												<TableCell align="right">
													<Fab color="secondary" aria-label="Delete" size="small" onClick={() => /*this.deleteOrderItem(index)*/ this.handleOpenConfirm(row.index)}>
														<DeleteIcon />
													</Fab>
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
								</div>
								<Fab color="primary" aria-label="Add" className={`add-icon`} size="small" onClick={this.openDialog}>
									<AddIcon />
								</Fab>
							</div>
						</Paper>
					</Grid>
        </Grid>

				<Dialog
          open={this.state.openDialog}
          onClose={this.handleCloseDialog}
					aria-labelledby="form-dialog-title"
					disableBackdropClick={true}
        >
          <DialogTitle id="form-dialog-title">Add Item</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Fill fields to add an item.
            </DialogContentText>
						<Grid container>
							<Grid item xs={12} sm={12} md={12} lg={12}>
								<TextField
									className={"full-width"}
									label="Orderer Email"
									type="text"
									margin="normal"
									value={this.state.itemData.item_email}
									onChange={e => this.handleChangeItemData("item_email", e)}
									variant="outlined"
									disabled={userData.role == "customer"}
									error={!this.state.itemData.item_email}
								/>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12}>
								<TextField
									className={"full-width"}
									label="Item name"
									type="text"
									margin="normal"
									value={this.state.itemData.item_name}
									onChange={e => this.handleChangeItemData("item_name", e)}
									variant="outlined"
									error={!this.state.itemData.item_name}
								/>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12}>
								<TextField
									className={"full-width"}
									label="Item Amount"
									type="number"
									margin="normal"
									value={this.state.itemData.item_amount}
									onChange={e => this.handleChangeItemData("item_amount", e)}
									variant="outlined"
									error={!this.state.itemData.item_amount}
								/>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12}>
								<TextField
									className={"full-width"}
									label="Item description"
									type="text"
									margin="normal"
									value={this.state.itemData.item_description}
									onChange={e => this.handleChangeItemData("item_description", e)}
									variant="outlined"
									multiline
									error={!this.state.itemData.item_description}
								/>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12}>
								<AnyUpload label="Product Attachment" type="*" onChange={this.onAttachmentUploaded} 
									customName={`${this.state.itemData.item_email}_${this.state.formData.order_code}_${this.state.itemData.item_code}`}/>
							</Grid>
						</Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDialogCancel} color="secondary">
              Cancel
            </Button>
            <Button onClick={this.handleDialogAddItem} color="primary" disabled={!this.validateItem()}>
              Add
            </Button>
          </DialogActions>
        </Dialog>

				{/* CONFIRMATION DIALOG */}
				<Dialog
          open={this.state.openConfirm}
          onClose={this.handleCloseConfirm}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">{"Warning!"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
							Are you sure you want to delete this item?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseConfirm} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleActionConfirm} color="primary" autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    );
  }
}

const mapStateToProps = (state) => {
	return {...state}
};
const mapDispatchToProps = (dispatch) => ({});
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(OrderCreate));

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
	},
	fab: {
    margin: theme.spacing.unit,
  },
});
import Immutable from 'seamless-immutable';
import {PRODUCT_GET_LIST_SUCCESS} from '../actions/product';

const INIT_STATE = Immutable({
    productData: null,
    productList: [],
    access_token: '',
});

export default function (state = INIT_STATE, action) {
    switch (action.type) {
        case PRODUCT_GET_LIST_SUCCESS:
            return Object.assign({}, state, {productList: action.payload});
        default:
            return state;
    }
}

import React from 'react';
import { browserHistory } from 'react-router';
import {
	Paper,
	Typography,
	Grid,
	IconButton,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions
} from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import { MaterialTable } from '../../components/common/Table';
import moment from 'moment';
import _ from 'lodash';
import { doRightRole, checkPermissionAdmin } from '../../utils/helper';

class SupplierList extends React.Component {
	constructor(props) {
		super(props);

		this.renderActions = this.renderActions.bind(this);
		this.handleOpenConfirm = this.handleOpenConfirm.bind(this);
		this.handleCloseConfirm = this.handleCloseConfirm.bind(this);
		this.handleActionConfirm = this.handleActionConfirm.bind(this);

		this.state = {
			openConfirm: false,
			selectedItem: null,
			style_actions: {}
		};
	}

	componentDidMount() {
		this.props.fetchAllSupplier({
			limit: 10,
			page: 1,
			orderBy: 'id',
			orderDir: 'asc',
			textSearch: ''
		});
		window.addEventListener('scroll', this.handleScrollDebounce, true);
		document
			.querySelector('.table-scrollable')
			.dispatchEvent(new Event('scroll'));
	}

	handleScrollDebounce = _.debounce(event => {
		let alignElement = document.querySelector('.table-scrollable');
		let anchorElement = document.querySelector(
			'.table-scrollable > table > thead > tr > th:last-child'
		);
		const { style_actions } = this.state;
		const maxOffsetLeft =
			anchorElement.offsetLeft + anchorElement.offsetWidth - 38;
		let left = alignElement.offsetWidth + event.srcElement.scrollLeft;
		left = left > maxOffsetLeft ? maxOffsetLeft : left;
		style_actions['left'] = `calc(${left}px)`;
		this.setState({ style_actions: style_actions });
	}, 100);

	handleOpenConfirm(index) {
		this.state.openConfirm = true;
		this.state.selectedItem = index;
		this.setState({ ...this.state });
	}

	handleCloseConfirm() {
		this.state.openConfirm = false;
		this.setState({ ...this.state });
	}

	async handleActionConfirm() {
		if (this.state.selectedItem) {
			await this.props.deleteSupplier({
				supplier_id: this.state.selectedItem.id
			});
		}
		await this.props.fetchAllSupplier({
			limit: 10,
			page: 1,
			orderBy: 'id',
			orderDir: 'asc',
			textSearch: ''
		});
		await this.setState({ selectedItem: null });
		await this.handleCloseConfirm();
	}

	renderTime(row) {
		return moment(row.createdAt).format('DD-MM-YY HH:mm:SS');
	}

	renderActions(row) {
		return (
			<div className='actions' style={{ ...this.state.style_actions }}>
				<IconButton
					variant='contained'
					size='small'
					color='primary'
					className='small-btn'
					onClick={() =>
						browserHistory.push(`/admin/supplier-management/update/${row.id}`)
					}
					disabled={!checkPermissionAdmin()}
				>
					<Edit />
				</IconButton>
				<IconButton
					variant='contained'
					size='small'
					color='secondary'
					className='small-btn'
					onClick={() => this.handleOpenConfirm(row)}
					disabled={!checkPermissionAdmin()}
				>
					<Delete />
				</IconButton>
			</div>
		);
	}

	render() {
		const { supplier } = this.props;

		const SUPPLIER_PAGE_COLUMNS = [
			{ title: '#', sortable: false, data_field: 'index' },
			{ title: 'Supplier name', sortable: false, data_field: 'supplier_name' },
			{
				title: 'Supplier email',
				sortable: false,
				data_field: 'supplier_email'
			},
			{
				title: 'Supplier phone',
				sortable: false,
				data_field: 'supplier_phone'
			},
			{ title: 'Supplier qq', sortable: false, data_field: 'supplier_qq' },
			{
				title: 'Supplier wechat',
				sortable: false,
				data_field: 'supplier_wechat'
			},
			{
				title: 'Supplier skype',
				sortable: false,
				data_field: 'supplier_skype'
			},
			{
				title: 'Created at',
				sortable: false,
				data_field: null,
				render: this.renderTime
			},
			{
				title: 'Actions',
				sortable: false,
				data_field: null,
				render: this.renderActions
			}
		];

		return (
			<Paper elevation={1} square className={'container'}>
				<Typography variant='h5' component='h4' className={'mc_page_title'}>
					Supplier Management
				</Typography>
				<MaterialTable
					columns={SUPPLIER_PAGE_COLUMNS}
					rows={supplier.list}
					totals={supplier.totals}
					getList={this.props.fetchAllSupplier}
					addNewLink={'/admin/supplier-management/create'}
				/>
				{/* CONFIRMATION DIALOG */}
				<Dialog
					open={this.state.openConfirm}
					onClose={this.handleCloseConfirm}
					aria-labelledby='responsive-dialog-title'
				>
					<DialogTitle id='responsive-dialog-title'>{'Warning!'}</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Are you sure you want to delete this supplier?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleCloseConfirm} color='primary'>
							Cancel
						</Button>
						<Button
							onClick={this.handleActionConfirm}
							color='primary'
							autoFocus
						>
							Delete
						</Button>
					</DialogActions>
				</Dialog>
			</Paper>
		);
	}
}

export default SupplierList;

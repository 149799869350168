import Immutable from 'seamless-immutable';
import { FETCH_ALL_PAYMENT_INRANGE } from '../actions/financal';

const INIT_STATE = Immutable({
    list: [],
    totals: 0,
});

export default function (state = INIT_STATE, action) {
    switch (action.type) {
        case FETCH_ALL_PAYMENT_INRANGE:
            return Object.assign({}, state, {list: action.payload});
        default:
            return state;
    }
}

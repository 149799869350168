import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import GroupIcon from '@material-ui/icons/Group';
import CategoryIcon from '@material-ui/icons/Category';
import ProductIcon from '@material-ui/icons/ShoppingBasket';
import {browserHistory} from 'react-router';
import './Sidebar.css';

class Sidebar extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <Drawer open={this.props.utils.isShowSidebar} className={"sidebar-component"} onClose={() => this.props.toggleSidebar(!this.props.utils.isShowSidebar)}>
                <List component="nav" className={"navbar-component"}>
                    <ListItem>
                        <ListItemText primary="Pnlab" />
                    </ListItem>
                    <ListItem button onClick={() => {browserHistory.push(`/admin/user-management/list`); this.props.toggleSidebar(!this.props.utils.isShowSidebar)}}>
                        <ListItemIcon>
                            <GroupIcon />
                        </ListItemIcon>
                        <ListItemText primary="User Management" />
                    </ListItem>
                    <ListItem button onClick={() => {browserHistory.push(`/admin/order-management/list`); this.props.toggleSidebar(!this.props.utils.isShowSidebar)}}>
                        <ListItemIcon>
                            <CategoryIcon />
                        </ListItemIcon>
                        <ListItemText primary="Order Management" />
                    </ListItem>
                    <ListItem button onClick={() => {browserHistory.push(`/admin/supplier-management/list`); this.props.toggleSidebar(!this.props.utils.isShowSidebar)}}>
                        <ListItemIcon>
                            <CategoryIcon />
                        </ListItemIcon>
                        <ListItemText primary="Supplier Management" />
                    </ListItem>
                    <ListItem button onClick={() => {browserHistory.push(`/admin/exchange-management/list`); this.props.toggleSidebar(!this.props.utils.isShowSidebar)}}>
                        <ListItemIcon>
                            <CategoryIcon />
                        </ListItemIcon>
                        <ListItemText primary="Exchange Management" />
                    </ListItem>
                    <ListItem button onClick={() => {browserHistory.push(`/admin/discount-management/list`); this.props.toggleSidebar(!this.props.utils.isShowSidebar)}}>
                        <ListItemIcon>
                            <CategoryIcon />
                        </ListItemIcon>
                        <ListItemText primary="Discount Management" />
                    </ListItem>
                    <ListItem button onClick={() => {browserHistory.push(`/admin/financal-management/list`); this.props.toggleSidebar(!this.props.utils.isShowSidebar)}}>
                        <ListItemIcon>
                            <CategoryIcon />
                        </ListItemIcon>
                        <ListItemText primary="Financal Management" />
                    </ListItem>
                </List>
            </Drawer>
        );
    }
}

export default Sidebar;
import React from 'react';
import {browserHistory} from 'react-router';
import TableHeader from './TableHeader';
import TableBody from './TableBody';
import './Table.css';
import {
    Table, TablePagination, Button, Paper,
    TableCell, Tooltip, TableRow, TableSortLabel,
    FormControl, InputLabel, Input, InputAdornment,
} from '@material-ui/core';
import Fab from '@material-ui/core/Fab';

import {
    Search, Add,
} from '@material-ui/icons';

class MaterialTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            limit: 10,
            page: 1,
            orderBy: 'id',
            orderDir: 'desc',
            textSearch: '',
        }
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
        this.handleTextSearch = this.handleTextSearch.bind(this);
    }

    async handleChangePage(event, e) {
        await this.setState({page: e + 1})
        await this.props.getList({...this.state});
    }

    async handleChangeRowsPerPage(event, e) {
        await this.setState({limit: +e.key})
        await this.props.getList({...this.state});
    }

    async handleTextSearch(event, e) {
        await this.setState({textSearch: event.currentTarget.value})
        if (this.textSearchTimeout) {
            clearTimeout(this.textSearchTimeout);
        }
        this.textSearchTimeout = setTimeout(async () => {
            await this.props.getList({...this.state});
        }, 1000)
    }

    render() {
        const {
            limit,
            page,
            orderBy,
            orderDir,
            textSearch,
        } = this.state;
        const {addNewLink, isSearchable = true} = this.props;
        return (
                <div className={`table-wrapper`}>
                    <div className={'search-text'}>
                        <h2 className={`totals`}>
                            <span>Total: {this.props.totals}</span>
                        </h2>
                        {
                            isSearchable ? 
                            <FormControl className="search-text-input">
                                <InputLabel htmlFor="adornment-amount">Search</InputLabel>
                                <Input
                                    id="adornment-amount"
                                    value={this.state.amount}
                                    onChange={this.handleTextSearch}
                                    startAdornment={<InputAdornment position="start"><Search/></InputAdornment>}
                                />
                            </FormControl>
                            :
                            ''
                        }
                        
                    </div>
                    <div className="table-scrollable">
                        <Table className={"table"} aria-labelledby="tableTitle">
                            <TableHeader columns={this.props.columns} onFetch={(c, i) => this.props.getList({...this.state, orderBy: c.data_field, orderDir: c.orderDir})}/>
                            <TableBody columns={this.props.columns} rows={this.props.rows} />
                        </Table>
                    </div>
                    {
                        addNewLink ? 
                        <Fab color="primary" aria-label="Add" size="small" className="table-add-btn" onClick={() => browserHistory.push(addNewLink)}>
                            <Add />
                        </Fab>:
                        null
                    }
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={this.props.totals || 0}
                        rowsPerPage={limit || 0}
                        page={page - 1 >= 0 ? page - 1: 0}
                        backIconButtonProps={{
                            'aria-label': 'Previous Page',
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'Next Page',
                        }}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                </div>
        )
    }
}

export default MaterialTable;
import React from 'react';
import { connect } from 'react-redux';
import {
    Paper, Typography, Grid,
} from '@material-ui/core';
import {SupplierList, SupplierCreate, SupplierUpdate} from '../components/Supplier';
import {createSupplier, fetchAllSupplier, deleteSupplier, getSupplierData} from '../actions/supplier';

class SupplierPage extends React.Component {
    constructor(props) {
        super(props);
        this.renderComponent = this.renderComponent.bind(this);
    }

    renderActions(row) {
        return (<button>Actions</button>);
    }

    renderComponent(action) {
        switch (action.toUpperCase()) {
            case "LIST":
                return (
                    <SupplierList {...this.props}/>
                )
            case "CREATE":
                return (
                    <SupplierCreate {...this.props}/>
                )
            case "UPDATE":
                return (
                    <SupplierUpdate {...this.props}/>
                )
            default:
                return (
                    <SupplierList {...this.props}/>
                )
        }
    }

    render() {
        const {action} = this.props.params;
        return (
            <div className={"mc_page"}>
                {this.renderComponent(action)}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {...state}
}

const mapDispatchToProps = dispatch => {
    return {
        createSupplier: async (data) => {
            return await dispatch(createSupplier(data))
        },
        fetchAllSupplier: async (data) => {
            return await dispatch(fetchAllSupplier(data));
        },
        deleteSupplier: async (data) => {
            return await dispatch(deleteSupplier(data));
        },
        getSupplierData: async (data) => {
            return await dispatch(getSupplierData(data));
        },
        // updateUserProfile: async (data) => {
        //     return await dispatch(updateUserProfile(data));
        // }
    }
}

const action = {
    LIST: 'list',
    CREATE: 'create',
    UPDATE: 'update',
}

export default connect(mapStateToProps, mapDispatchToProps)(SupplierPage);
import axios from "axios";
import qs from "qs";
import {
  browserHistory
} from "react-router";
import {
  REACT_APP_NODE_API_DOMAIN,
  REACT_APP_NODE_API_PORT,
  STATUS_CODE
} from "../constants";

class API {
  constructor() {
    this.axios = axios.create({
      baseURL: `${REACT_APP_NODE_API_DOMAIN}`,
      timeout: 100000
    });
  }

  static setHeader(token) {
    return {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
  }

  doPutRequest(url, data, customConfig = {}) {
    const token = localStorage.getItem("token") || null;

    const config = Object.assign({}, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    },
      customConfig
    );
    return beforeResponse(this.axios.put(url, data, config));
  }

  doPostRequest(url, data, customConfig = {}) {
    const token = localStorage.getItem("token") || null;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      },
      ...customConfig,
    };
    return beforeResponse(this.axios.post(url, data, config));
  }

  doGetRequest(url, data) {
    const token = localStorage.getItem("token") || null;

    const config = Object.assign({}, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }, {
        params: data
      });
    return beforeResponse(this.axios.get(url, config));
  }

  doDeleteRequest(url, data) {
    const token = localStorage.getItem("token") || null;

    const config = Object.assign({}, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }, {
          params: data
        });
    return beforeResponse(this.axios.delete(url, config));
  }

  async login(data) {
    return await this.axios.post('/login', qs.stringify(data));
  }

  async logout() {
    return await this.axios.post('/admin/api/logout');
  }

  async invite(data) {
    return await this.doPostRequest('/admin/invite', qs.stringify(data));
  }

  async register(data) {
    return await this.doPostRequest('/register', qs.stringify(data));
  }

  async userGetAllList(data) {
    return await this.doPostRequest('/admin/getAllList', qs.stringify(data));
  }

  async getAllListProduct(data) {
    return await this.doPostRequest('/admin/product/getAllListProduct', qs.stringify(data));
  }

  async getAdminProfile() {
    return await this.doGetRequest('/admin/getAdminProfile');
  }

  async deleteUser(id) {
    return await this.doPostRequest('/admin/user/delete', { id: id });
  }

  async deleteProduct(id) {
    return await this.doPostRequest('/admin/product/delete', { id: id });
  }

  async getUserProfile(id) {
    return await this.doPostRequest('/admin/user/getUserProfile', { id: id });
  }

  async getProductData(id) {
    return await this.doPostRequest('/admin/product/getProductData', { id: id });
  }

  async updateUserProfile(data) {
    return await this.doPostRequest('/admin/user/updateUserProfile', data);
  }

  async activate(data) {
    return await this.doPostRequest('/admin/user/activate', data);
  }

  async createProduct(data) {
    return await this.doPostRequest('/admin/product/create', data);
  }

  async logout() {
    return await this.doPostRequest('/admin/api/logout');
  }

  async getSignedUrl(data) {
    return await this.doPostRequest('/admin/common/getSignedUrl', data);
  }

  async getCategoriesInShort(data) {
    return await this.doPostRequest('/admin/category/getCategoriesInShort', data);
  }

  async getAllListCategories(data) {
    return await this.doGetRequest('/admin/category/getAllListCategory', data);
  }

  async createCategory(data) {
    return await this.doPostRequest('/admin/category/create', data);
  }

  async getCategoryData(data) {
    return await this.doPostRequest('/admin/category/getCategoryData', data);
  }

  async adminCreateOrder(data) {
    return await this.doPostRequest('/admin/order/create', data);
  }

  async userCreateOrder(data) {
    return await this.doPostRequest('/user/order/create', data);
  }

  async getAllListOrders(data) {
    return await this.doPostRequest('/admin/order/getAllListOrders', data);
  }

  async getAllListOrdersForUser(data) {
    return await this.doPostRequest('/user/order/getAllListOrders', data);
  }

  async getAllListOrdersForFinance(data) {
    return await this.doPostRequest('/admin/order/getAllListOrdersForFinance', data);
  }
  
  async getAllListItemsInOrder(data) {
    return await this.doPostRequest('/admin/order/getAllListItemsInOrder', data);
  }

  async getAllListItemsInOrderForUser(data) {
    return await this.doPostRequest('/user/order/getAllListItemsInOrder', data);
  }

  async getItemData(data) {
    return await this.doPostRequest('/admin/order/getItemData', data);
  }

  async getUserItemData(data) {
    return await this.doPostRequest('/user/order/getItemData', data);
  }

  async updateItem(data) {
    return await this.doPostRequest('/admin/order/updateItem', data);
  }

  async deleteItem(data) {
    return await this.doPostRequest('/admin/order/deleteItem', data);
  }

  async createSupplier(data) {
    return await this.doPostRequest('/admin/supplier', data);
  }

  async fetchAllSupplier(data) {
    return await this.doGetRequest('/admin/supplier', data);
  }

  async deleteSupplier(data) {
    return await this.doDeleteRequest('/admin/supplier', data);
  }

  async getSupplierData(data) {
    return await this.doGetRequest(`/admin/supplier/${data.id}`);
  }

  async createExchange(data) {
    return await this.doPostRequest('/admin/exchange', data);
  }

  async fetchAllExchange(data) {
    return await this.doGetRequest('/admin/exchange', data);
  }

  async deleteExchange(data) {
    return await this.doDeleteRequest('/admin/exchange', data);
  }

  async getExchangeData(data) {
    return await this.doGetRequest(`/admin/exchange/${data.id}`);
  }

  async createDiscount(data) {
    return await this.doPostRequest('/admin/discount', data);
  }

  async fetchAllDiscount(data) {
    return await this.doGetRequest('/admin/discount', data);
  }

  async deleteDiscount(data) {
    return await this.doDeleteRequest('/admin/discount', data);
  }

  async getDiscountData(data) {
    return await this.doGetRequest(`/admin/discount/${data.id}`);
  }

  async fetchAllDiscountNoQuery() {
    return await this.doGetRequest(`/admin/discounts/`);
  }

  async fetchAllExchangeNoQuery() {
    return await this.doGetRequest(`/admin/exchanges/`);
  }

  async fetchAllSupplierNoQuery() {
    return await this.doGetRequest(`/admin/suppliers/`);
  }

  async getOrderMeta(code) {
    return await this.doGetRequest(`/user/order/getOrderMeta`, {order_code: code});
  }

  async savePdf(data) {
    return await this.doPostRequest(`/admin/savePdf`, data);
  }

  async saveOrderMeta(data) {
    return await this.doPostRequest(`/admin/order/saveOrderMeta`, data);
  }

  async addPaymentHistory(data) {
    return await this.doPostRequest(`/admin/payment/addPaymentHistory`, data);
  }

  async getPaymentHistory(orderCode) {
    return await this.doGetRequest(`/user/payment/getPaymentHistory/${orderCode}`);
  }

  async getAllUserForFinance() {
    return await this.doGetRequest(`/admin/user/getAllUserForFinance`);
  }

  async getAllListOrdersForFinanceWithoutPaging(data) {
    return await this.doPostRequest(`/admin/order/getAllListOrdersForFinanceWithoutPaging`, data);
  }

  async getAllPaymentHistoryInRange(data) {
    return await this.doPostRequest(`/admin/payment/getAllPaymentHistoryInRange`, data);
  }

  async getCurrentProfile() {
    return await this.doGetRequest(`/user/getCurrentProfile`);
  }

  async updateCurrentProfile(data) {
    return await this.doPostRequest(`/user/updateCurrentProfile`, data);
  }

  async resetPassword(data) {
    return await this.doPostRequest(`/resetPassword`, data);
  }

  async getUserListForFinance(data) {
    return await this.doPostRequest(`/user/getUserListForFinance`, data);
  }

  async changePassword(data) { 
    const {token} = data;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }
    return await this.doPostRequest(`/changePassword`, data, config);
  }

  async getTemplateFile(url, data) {
    const config = Object.assign({}, {
      ...data
    });
    return beforeResponse(this.axios.get(url, config));
  }
}


const beforeResponse = async request => {
  let res = await request;
  if (res && res.data && res.data.code === STATUS_CODE.TOKEN_EXPIRED) {
    localStorage.clear();
    browserHistory.push("/auth/login");
  } else {
    return res;
  }
};

export default new API();
import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import {
    Paper, Typography, Grid,
} from '@material-ui/core';
import {MaterialTable} from '../components/common/Table';
import {UserList, UserCreate, UserUpdate} from '../components/User';
import {
    userGetAllList, invite, deleteUser, 
    getUserProfile, updateUserProfile
} from '../actions/user';
import {
    fetchAllDiscountNoQuery
} from '../actions/discount';

class UserPage extends React.Component {
    constructor(props) {
        super(props);
        this.renderComponent = this.renderComponent.bind(this);
    }

    USER_PAGE_COLUMNS = [
        {title: "#", sortable: false, data_field: 'index',},
        {title: "Full Name", sortable: false, data_field: null, render: this.renderFullName},
        {title: "Email", sortable: false, data_field: 'email'},
        {title: "Role", sortable: false, data_field: 'role',},
        {title: "Status", sortable: false, data_field: 'status',},
        {title: "Discount", sortable: false, data_field: 'discount',},
        {title: "Created at", sortable: false, data_field: 'createdAt',},
        {title: "Actions", sortable: false, data_field: null, render: this.renderActions},
    ]

    renderFullName(row) {
        return row.first_name + ' ' + row.last_name;
    }

    renderActions(row) {
        return (<button>Actions</button>);
    }

    renderComponent(action) {
        switch (action.toUpperCase()) {
            case "LIST":
                return (
                    <UserList {...this.props}/>
                )
            case "CREATE":
                return (
                    <UserCreate {...this.props}/>
                )
            case "UPDATE":
                return (
                    <UserUpdate {...this.props}/>
                )
            default:
                return (
                    <UserList {...this.props}/>
                )
        }
    }

    render() {
        const {action} = this.props.params;
        return (
            <div className={"mc_page"}>
                {this.renderComponent(action)}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {...state}
}

const mapDispatchToProps = dispatch => {
    return {
        userGetAllList: async (data) => {
            return await dispatch(userGetAllList(data));
        },
        invite: async (data) => {
            return await dispatch(invite(data));
        },
        deleteUser: async (data) => {
            return await dispatch(deleteUser(data));
        },
        getUserProfile: async (data) => {
            return await dispatch(getUserProfile(data));
        },
        updateUserProfile: async (data) => {
            return await dispatch(updateUserProfile(data));
        },
        fetchAllDiscountNoQuery: async () => {
            return await dispatch(fetchAllDiscountNoQuery());
        },
    }
}

const action = {
    LIST: 'list',
    CREATE: 'create',
    UPDATE: 'update',
}

export default connect(mapStateToProps, mapDispatchToProps)(UserPage);
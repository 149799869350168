import Immutable from "seamless-immutable";
import { FETCH_CATEGORIES_SUCCESS } from "../actions/category";
const INIT_STATE = Immutable({
  userData: null,
  access_token: ""
});

export default function(state = INIT_STATE, action) {
  switch (action.type) {
    case FETCH_CATEGORIES_SUCCESS:
      return Object.assign({}, state, {
        categories: action.payload
      });
    default:
      return state;
  }
}

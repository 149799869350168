import './ImagePreview.css'
import defaultAvatar from '../../../assets/images/male-user.png'
import React from 'react';
import FileViewer from 'react-file-viewer';

const ImagePreview = (props) => {
    const {className, src, progress, loading} = props;
    let url = src ? src.indexOf('?') >= 0 ? src.split('?')[0]: src : defaultAvatar;
    let type = props.type || '';
    let isImage = (type) => {
        return type.indexOf( FILE_TYPE.IMAGE ) >= 0 ? {backgroundImage: `url(${url})`} : {};
    }
    return (
        <div className={className || `image-preview-wrapper`} style={isImage(type)}>
            {
               
                type.indexOf( FILE_TYPE.VIDEO ) >= 0 ? 
                <video controls src={url || defaultAvatar} className={`image-responsive`}/>
                : type.indexOf( FILE_TYPE.PDF ) >= 0 && src ? 
                <iframe src={`http://docs.google.com/gview?url=${url}&embedded=true`} width="100%" height="100%"></iframe>: null
            }
            <div className={`${loading ? `image-uploading-overlay` : `hidden`}`}>Uploading: {progress}%</div>
        </div>
    );
}

const FILE_TYPE = {
    IMAGE : 'image',
    VIDEO : 'video',
    PDF : 'pdf',
}

export default ImagePreview;
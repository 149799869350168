import React from 'react';
import Excel from 'exceljs/modern.browser';
import moment from 'moment';
import api from '../../../utils/api';
import { Button } from '@material-ui/core';
import _ from 'lodash';
class ExcelExporterJS extends React.Component {
	constructor(props) {
		super(props);
		this.workbook = new Excel.Workbook();
	}

	async componentDidMount() {
		this.workbook.views = [
			{
				x: 0,
				y: 0,
				width: 10000,
				height: 20000
			}
		];
	}

	downloadWorksheet = async () => {
		let res = await api.getTemplateFile(
			'/user/file/getTemplateFile?template_id=1',
			{
				responseType: 'blob'
			}
		);
		let buffer = await new Response(res.data).arrayBuffer();
		await this.workbook.xlsx.load(buffer);
		this.worksheet = this.workbook.getWorksheet(1);

		await this.renderTemplate();
		console.log(`RENDERING ....`);
		const { fileName = 'Template_Example' } = this.props;
		this.workbook.xlsx.writeBuffer().then(function(data) {
			const blob = new Blob([data], {
				type:
					'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
			});
			const url = window.URL.createObjectURL(blob);
			const anchor = document.createElement('a');
			anchor.href = url;
			anchor.download = `${fileName}.xlsx`;
			anchor.click();
			window.URL.revokeObjectURL(url);
			anchor.remove();
		});
	};

	renderTemplate = async () => {
		const data = (await this.props.initialize()) || {};
		const { items, userData = {} } = data;
		await this.renderUserData(userData);
		await this.renderOrderData(items, userData);
	};

	renderUserData = async userData => {
		this.worksheet.getCell('A14').value =
			convertToSpace(this.worksheet.getCell('A14').value) +
			` ${userData.first_name} ${userData.last_name}`;
		this.worksheet.getCell('A15').value =
			convertToSpace(this.worksheet.getCell('A15').value) +
			` ${userData.address_1 || userData.address_2 || ''}`;
		this.worksheet.getCell('M15').value =
			convertToSpace(this.worksheet.getCell('M15').value) +
			` ${userData.phone}`;
		this.worksheet.getCell('L13').value =
			convertToSpace(this.worksheet.getCell('L13').value) +
			` ${moment().format('DD/MM/YYYY')}`;
		this.worksheet.getCell('M13').value =
			convertToSpace(this.worksheet.getCell('M13').value) +
			` ${moment().format('DD/MM/YYYY')}`;
	};

	findRowForStartLoop = (signal = '_START_LOOP_') => {
		return new Promise((resolve, reject) => {
			this.worksheet.eachRow((row, rowNum) => {
				row.eachCell((cell, cellNum) => {
					if (cell.value == signal) {
						return resolve(rowNum);
					}
				});
			});
		});
	};

	renderOrderData = async (items, userData = {}) => {
		const keys = Object.keys(items);
		const maxLength = keys.length;
		let startLoop = await this.findRowForStartLoop();
		let exchanges = await api.fetchAllExchange();
		if (exchanges && exchanges.data && exchanges.data.data) {
			exchanges = exchanges.data.data.list || [];
		}

		// Set order ID
		this.worksheet.getCell('N11').value =
			items && items[keys[0]] ? items[keys[0]].order_code : '';

		for (var i = 0; i < maxLength; i++) {
			let rowData = { ...items[keys[i]] };
			let exchange = exchanges.find(ex => ex.id == rowData.item_price_unit) || {
				exchange_value: 0
			};

			var newRow = [
				i + 1,
				rowData.item_name,
				,
				rowData.item_description,
				,
				,
				,
				,
				,
				`cái`,
				`2 tuần`,
				rowData.item_amount,
				rowData.item_unit_price * +exchange.exchange_value * +rowData.factor,
				rowData.item_total_price
			];
			if (i == maxLength - 1) {
				await this.worksheet.spliceRows(startLoop + i, 1, newRow);
			} else {
				await this.worksheet.spliceRows(startLoop + i, 1, newRow, []);
			}
			await this.worksheet.mergeCells(`B${startLoop + i}:C${startLoop + i}`);
			await this.worksheet.mergeCells(`D${startLoop + i}:I${startLoop + i}`);
			this.worksheet.getRow(startLoop + i).font = { name: 'Arial', size: 10 };
		}

		// Add FORMULA
		this.worksheet.getCell(`N${startLoop + maxLength}`).value = {
			formula: `SUM(N${startLoop}:N${startLoop + maxLength - 1}) / ${1 - +userData.discount / 100}`
		};
		this.worksheet.getCell(`N${startLoop + maxLength + 1}`).value = {
			formula: `N${startLoop + maxLength}*${+userData.discount / 100}`
		};
		this.worksheet.getCell(`N${startLoop + maxLength + 2}`).value = {
			formula: `(N${startLoop + maxLength} - N${startLoop +
				maxLength +
				1})*10/100`
		};
		this.worksheet.getCell(`N${startLoop + maxLength + 3}`).value = {
			formula: `N${startLoop + maxLength}-N${startLoop +
				maxLength +
				1}+N${startLoop + maxLength + 2}`
		};

		// Change this for each template
		let footerLeng = 13;
		for (var j = 0; j < footerLeng; j++) {
			if (footerLeng - 1 === j) {
				await this.worksheet.mergeCells(
					`A${startLoop + maxLength + j}:G${startLoop + maxLength + j}`
				);
				await this.worksheet.mergeCells(
					`H${startLoop + maxLength + j}:N${startLoop + maxLength + j}`
				);
				continue;
			}
			if (j >= 0 && j <= 3) {
				await this.worksheet.mergeCells(
					`A${startLoop + maxLength + j}:M${startLoop + maxLength + j}`
				);
				continue;
			}
			await this.worksheet.mergeCells(
				`A${startLoop + maxLength + j}:N${startLoop + maxLength + j}`
			);
		}

		// Border
		let startCol = 'A',
			endCol = 'N';
		let startRow = startLoop,
			endRow = startLoop + maxLength + 5;

		for (let col = startCol.charCodeAt(0); col <= endCol.charCodeAt(0); col++) {
			for (let row = startRow; row <= endRow; row++) {
				this.worksheet.getCell(`${String.fromCharCode(col)}${row}`).border = {
					top: { style: 'thin' },
					left: { style: 'thin' },
					bottom: { style: 'thin' },
					right: { style: 'thin' }
				};
			}
		}
	};

	render() {
		const { items } = this.props;
		return (
			<Button
				variant='contained'
				onClick={this.downloadWorksheet}
				color='primary'
				disabled={_.isEmpty(items)}
			>
				Save Excel *
			</Button>
		);
	}
}

function convertToSpace(string) {
	return string ? string : '';
}

export default ExcelExporterJS;

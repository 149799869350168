import React from "react";
import { browserHistory } from "react-router";
import {
  Paper,
  Typography,
  Grid,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Input,
  MenuItem,
  FormHelperText
} from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import { validateProduct } from "../../utils/validatation";
import "./Product.css";
import FileUpload from "../common/FileUpload/FileUpload";

class ProductCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        //id: '', //number
        product_name: '', //string
        product_description: '', //string
        product_content: '', //string
        product_code: '', //PR017447
        product_stock: '', //number
        product_price: '', //price
        product_status: '', //status
        product_brand: '', // string
        product_manufactor: '',
        product_thumbnail: '',
        product_price_lv2: '',
        product_price_lv3: '',
        product_category: '',
      }
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeHtml = this.handleChangeHtml.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.onThumbnailUploaded = this.onThumbnailUploaded.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  async componentDidMount() {
    //Fetch category for create product
    this.state.categories = await this.props.getCategoriesInShort();
    this.setState({...this.state});
  }

  onSubmit() {
    console.log(`Submit!`);
    console.log(this.state.formData);
    const { formData } = this.state;
    return this.props.createProduct(formData);
  }

  handleChange(field, e) {
    this.state.formData[field] = e.currentTarget.value;
    this.setState({ formData: { ...this.state.formData } });
  }

  handleChangeHtml(field, e) {
    this.state.formData[field] = e;
    this.setState({ formData: { ...this.state.formData } });
  }

  handleSelectChange(field, e) {
    this.state.formData[field] = e.target.value;
    this.setState({ formData: { ...this.state.formData } });
  }

  onThumbnailUploaded(uploaded) {
    let { formData } = this.state;
    formData.product_thumbnail = uploaded.uploaded_path;
    this.setState({ formData });
  }

  renderCategories(categories) {
    categories = categories || [];
    return categories.map((category, index) => {
      if (index == 0) {
        return (
          <MenuItem key={category.id} value={category.id} selected>
            {category.category_name}
          </MenuItem>
        )  
      } else {
        return (
          <MenuItem key={category.id} value={category.id}>
            {category.category_name}
          </MenuItem>
        )
      }
    })
  }

  render() {
    return (
      <Paper elevation={1} square className={"container"}>
        <Typography variant="h5" component="h4" className={"mc_page_title"}>
          Product Create
        </Typography>
        <Grid container spacing={16}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <FileUpload label="Product Thumbnail" type="image/*" onChange={this.onThumbnailUploaded} allowedType={['jpg', 'png']} />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <TextField
              className={"full-width"}
              label="Code (left empty for auto)"
              type="text"
              margin="normal"
              value={this.state.formData.product_code}
              onChange={e => this.handleChange("product_code", e)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <TextField
              className={"full-width"}
              label="Name"
              type="text"
              margin="normal"
              value={this.state.formData.product_name}
              onChange={e => this.handleChange("product_name", e)}
              required
              error={!this.state.formData.product_name}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <TextField
              className={"full-width"}
              label="Stock"
              type="number"
              margin="normal"
              value={this.state.formData.product_stock}
              onChange={e => this.handleChange("product_stock", e)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <FormControl className={`full-width missing-margin`}>
              <InputLabel htmlFor="role-helper">Status</InputLabel>
              <Select
                value={this.state.formData.product_status}
                onChange={e => this.handleSelectChange("product_status", e)}
                input={<Input name="product_status" id="role-helper" />}
                className={`full-width`}
                required
                error={!this.state.formData.product_status}
              >
                <MenuItem value={"in_stock"} selected>
                  In Stock
                </MenuItem>
                <MenuItem value={"out_of_stock"}>Out of Stock</MenuItem>
                <MenuItem value={"updating"}>Updating</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <FormControl className={`full-width missing-margin`}>
              <InputLabel htmlFor="role-helper">Category</InputLabel>
              <Select
                value={this.state.formData.product_category}
                onChange={e => this.handleSelectChange("product_category", e)}
                input={<Input name="product_category" id="role-helper" />}
                className={`full-width`}
                required
                error={!this.state.formData.product_category}
              >
                {
                  this.state.categories ?
                  this.renderCategories(this.state.categories) : null
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <TextField
              className={"full-width"}
              label="Price"
              type="number"
              margin="normal"
              value={this.state.formData.product_price}
              onChange={e => this.handleChange("product_price", e)}
              required
              error={!this.state.formData.product_price}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <TextField
              className={"full-width"}
              label="Price Lv2"
              type="text"
              margin="normal"
              value={this.state.formData.product_price_lv2}
              onChange={e => this.handleChange("product_price_lv2", e)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <TextField
              className={"full-width"}
              label="Price Lv3"
              type="text"
              margin="normal"
              value={this.state.formData.product_price_lv3}
              onChange={e => this.handleChange("product_price_lv3", e)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <TextField
              className={"full-width"}
              label="Manufactor"
              type="text"
              margin="normal"
              value={this.state.formData.product_manufactor}
              onChange={e => this.handleChange("product_manufactor", e)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <TextField
              className={"full-width"}
              label="Brand"
              type="text"
              margin="normal"
              value={this.state.formData.product_brand}
              onChange={e => this.handleChange("product_brand", e)}
            />
          </Grid>
          {/*<Grid item xs={12} sm={12} md={12} lg={12}>
            <InputLabel>Description</InputLabel>
            <Editor
              htmlContent={this.state.formData.product_description}
              onHtmlChange={e => this.handleChangeHtml("product_description", e)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <InputLabel>Content</InputLabel>
            <Editor
              htmlContent={this.state.formData.product_content}
              onHtmlChange={e => this.handleChangeHtml("product_content", e)}
            />
          </Grid> */}
          <Grid container spacing={16} className={`footer-padding`}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Button
                variant="contained"
                color="primary"
                className={`pull-right`}
                onClick={this.onSubmit}
                disabled={!validateProduct(this.state.formData)}
              >
                Create
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Button
                variant="contained"
                color="default"
                onClick={() =>
                  browserHistory.push(`/admin/product-management/list`)
                }
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default ProductCreate;

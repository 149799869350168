import React from 'react';
import moment from 'moment';

const FinancalList = (props) => {
    const {anchorId, width, height, data : {
      financeData : {
        totalMoneyNeedPayTillLastMonth,
        totalMoneyNeedPayThisMonth,
        totalMoneyPaiedTillLastMonth,
        totalMoneyPaiedThisMonth,
      },
      orderList,
      paymentList,
      orderTotals,
      user,
      date_from, 
      date_to,
    }} = props;
    return (
        <div id={anchorId} style={{width: `${width}`, height: `${height}`}}>
        <table cellSpacing={0} border={0} style={{width: '100%'}}>
          <tbody>
            <tr>
              <td height={40} align="left" colSpan={7} valign="middle" haligh="center" style={{borderBottom: '3px solid rgb(55, 108, 54)'}}>
                <font color="#404040" face="Microsoft Sans Serif" size={4}><b>BÁO CÁO TÀI CHÍNH</b></font>
              </td>
            </tr>
            <tr>
              <td height={40} align="left" valign="bottom" style={{borderBottom: '2px solid rgb(55, 108, 54)'}} colSpan={3}>
                <font color="#404040" face="Microsoft Sans Serif"><b>THÔNG TIN KHÁCH HÀNG</b></font>
              </td>
              <td align="left" valign="middle" haligh="center">
                <font color="#404040"><br /></font>
              </td>
              <td colSpan={3} align="left" valign="bottom" style={{borderBottom: '2px solid rgb(55, 108, 54)'}}>
                <b><font face="Microsoft Sans Serif" color="#404040">THÔNG TIN KHOẢN VAY</font></b>
              </td>
            </tr>
            <tr>
              <td colSpan={2} height={20} align="left" valign="middle" haligh="center" style={{borderTop: '1px solid rgb(127, 127, 127)', borderBottom: '1px solid rgb(127, 127, 127)'}}>
                <i><font color="#595959">Tên khách hàng</font></i>
              </td>
              <td align="right" valign="bottom" bgcolor="#D9D9D9" sdval={10000} sdnum="1033;0;#,##0.00 &quot;â‚«&quot;" style={{borderTop: '1px solid rgb(127, 127, 127)', borderBottom: '1px solid rgb(127, 127, 127)'}}>
                <font color="#404040">{user.name}</font>
              </td>
              <td align="left" valign="middle" haligh="center">
                <font color="#404040"><br /></font>
              </td>
              <td colSpan={2} align="left" valign="middle" haligh="center" style={{borderTop: '1px solid rgb(127, 127, 127)', borderBottom: '1px solid rgb(127, 127, 127)'}}>
                <font color="#595959"><i>Nợ cũ</i></font>
              </td>
              <td align="right" valign="bottom" bgcolor="#D9D9D9" sdval="108.526277960481" sdnum="1033;0;#,##0.00 &quot;â‚«&quot;" style={{borderTop: '1px solid rgb(127, 127, 127)', borderBottom: '1px solid rgb(127, 127, 127)'}}>
                {totalMoneyNeedPayTillLastMonth}
              </td>
            </tr>
            <tr>
              <td colSpan={2} height={20} align="left" valign="middle" haligh="center" style={{borderTop: '1px solid rgb(127, 127, 127)', borderBottom: '1px solid rgb(127, 127, 127)'}}>
                <font color="#595959"><i>Email</i></font>
              </td>
              <td align="right" valign="bottom" bgcolor="#D9D9D9" sdval="0.055" sdnum="1033;0;0.00%" style={{borderTop: '1px solid rgb(127, 127, 127)', borderBottom: '1px solid rgb(127, 127, 127)'}}>
                {user.email}
              </td>
              <td align="left" valign="middle" haligh="center">
                <font color="#404040"><br /></font>
              </td>
              <td colSpan={2} align="left" valign="middle" haligh="center" style={{borderTop: '1px solid rgb(127, 127, 127)', borderBottom: '1px solid rgb(127, 127, 127)'}}>
                <font color="#595959"><i>Tiền hàng tháng này</i></font>
              </td>
              <td align="right" valign="bottom" bgcolor="#D9D9D9" sdval={120} sdnum="1033;0;#,##0" style={{borderTop: '1px solid rgb(127, 127, 127)', borderBottom: '1px solid rgb(127, 127, 127)'}}>
                {totalMoneyNeedPayThisMonth}
              </td>
            </tr>
            <tr>
              <td colSpan={2} height={20} align="left" valign="middle" haligh="center" style={{borderTop: '1px solid rgb(127, 127, 127)', borderBottom: '1px solid rgb(127, 127, 127)'}}>
                <font color="#595959"><i>Xem từ ngày</i></font>
              </td>
              <td align="right" valign="bottom" bgcolor="#D9D9D9" sdval={10} sdnum="1033;0;#,##0" style={{borderTop: '1px solid rgb(127, 127, 127)', borderBottom: '1px solid rgb(127, 127, 127)'}}>
                {moment(date_from).format('YYYY-MM-DD')}
              </td>
              <td align="left" valign="middle" haligh="center">
                <font color="#404040"><br /></font>
              </td>
              <td colSpan={2} align="left" valign="middle" haligh="center" style={{borderTop: '1px solid rgb(127, 127, 127)', borderBottom: '1px solid rgb(127, 127, 127)'}}>
                <font color="#595959"><i>Đã thanh toán tháng này</i></font>
              </td>
              <td align="right" valign="bottom" bgcolor="#D9D9D9" sdval="3023.15335525769" sdnum="1033;0;#,##0.00 &quot;â‚«&quot;" style={{borderTop: '1px solid rgb(127, 127, 127)', borderBottom: '1px solid rgb(127, 127, 127)'}}>
                {totalMoneyPaiedThisMonth}
              </td>
            </tr>
            <tr>
              <td colSpan={2} height={20} align="left" valign="middle" haligh="center" style={{borderTop: '1px solid rgb(127, 127, 127)', borderBottom: '1px solid rgb(127, 127, 127)'}}>
                <font color="#595959"><i>Xem đến ngày</i></font>
              </td>
              <td align="right" valign="bottom" bgcolor="#D9D9D9" sdval={43565} sdnum="1033;1033;M/D/YYYY" style={{borderTop: '1px solid rgb(127, 127, 127)', borderBottom: '1px solid rgb(127, 127, 127)'}}>
                {moment(date_to).format('YYYY-MM-DD')}
              </td>
              <td align="left" valign="middle" haligh="center">
                <font color="#404040"><br /></font>
              </td>
              <td colSpan={2} align="left" valign="middle" haligh="center" style={{borderTop: '1px solid rgb(127, 127, 127)', borderBottom: '1px solid rgb(127, 127, 127)'}}>
                <font color="#595959"><i>Tiền nợ tháng này</i></font>
              </td>
              <td align="right" valign="bottom" bgcolor="#D9D9D9" sdval="13023.1533552577" sdnum="1033;0;#,##0.00 &quot;â‚«&quot;" style={{borderTop: '1px solid rgb(127, 127, 127)', borderBottom: '1px solid rgb(127, 127, 127)'}}>
                {totalMoneyNeedPayThisMonth - totalMoneyPaiedThisMonth}
              </td>
            </tr>
            <tr>
              <td colSpan={2} height={20} align="left" valign="middle" haligh="center" style={{borderTop: '1px solid rgb(127, 127, 127)', borderBottom: '1px solid rgb(127, 127, 127)'}}>
                <i style={{color: 'rgb(89, 89, 89)'}}>Ngày xuất báo cáo</i>
              </td>
              <td align="right" valign="bottom" bgcolor="#D9D9D9" sdval={43565} sdnum="1033;1033;M/D/YYYY" style={{borderTop: '1px solid rgb(127, 127, 127)', borderBottom: '1px solid rgb(127, 127, 127)'}}>
                {moment().format('YYYY-MM-DD HH:mm')}
              </td>
              <td align="left" valign="middle" haligh="center">
                <font color="#404040"><br /></font>
              </td>
              <td colSpan={2} align="left" valign="middle" haligh="center" style={{borderTop: '1px solid rgb(127, 127, 127)', borderBottom: '1px solid rgb(127, 127, 127)'}}>
                <font color="#595959"><i>Tổng nợ</i></font>
              </td>
              <td align="right" valign="bottom" bgcolor="#D9D9D9" sdval="13023.1533552577" sdnum="1033;0;#,##0.00 &quot;Ã¢â€šÂ«&quot;" style={{borderTop: '1px solid rgb(127, 127, 127)', borderBottom: '1px solid rgb(127, 127, 127)'}}>
                {totalMoneyNeedPayThisMonth + totalMoneyNeedPayTillLastMonth - totalMoneyPaiedThisMonth - totalMoneyPaiedTillLastMonth}
              </td>
            </tr>
            <tr>
              <td height={20} align="left" valign="middle" haligh="center">
                <font color="#404040"><br /></font>
              </td>
              <td align="left" valign="middle" haligh="center">
                <font color="#404040"><br /></font>
              </td>
              <td align="left" valign="middle" haligh="center">
                <font color="#404040"><br /></font>
              </td>
              <td align="left" valign="middle" haligh="center">
                <font color="#404040"><br /></font>
              </td>
              <td align="left" valign="middle" haligh="center">
                <font color="#404040"><br /></font>
              </td>
              <td align="left" valign="middle" haligh="center">
                <font color="#404040"><br /></font>
              </td>
              <td align="left" valign="middle" haligh="center">
                <font color="#404040"><br /></font>
              </td>
            </tr>
            <tr className="bottom-border" style={{borderBottom: '2px solid rgb(55, 108, 54)'}}>
              <td height={39} align="left" valign="middle" style={{textAlign: 'center'}}>
                <font color="#404040">No.</font>
              </td>
              <td align="left" valign="middle" style={{textAlign: 'center'}}>
                <font color="#404040">Ngày đặt hàng</font>
              </td>
              <td align="right" valign="middle" style={{textAlign: 'center'}}>
                <font color="#404040">ID đơn hàng</font>
              </td>
              <td align="right" valign="middle" style={{textAlign: 'center'}}>
                <font color="#404040">Tên mặt hàng</font>
              </td>
              <td align="right" valign="middle" style={{textAlign: 'center'}}>
                <font color="#404040">Email người đặt hàng</font>
              </td>
              <td align="right" valign="middle" style={{textAlign: 'center'}}>
                <font color="#404040">Số lượng</font>
              </td>
              <td align="right" valign="middle" style={{textAlign: 'center'}}>
                <font color="#404040">Tổng số tiền</font>
              </td>
            </tr>
            {
              orderList.map((order, index) => {
                return (
                  <tr key={index} className="bottom-border">
                    <td height={39} align="left" valign="middle" style={{textAlign: 'center'}}>
                      <font color="#404040">{order.index}</font>
                    </td>
                    <td align="left" valign="middle" style={{textAlign: 'center'}}>
                      <font color="#404040">{moment(order.createdAt).format('YYYY-MM-DD HH:mm')}</font>
                    </td>
                    <td align="right" valign="middle" style={{textAlign: 'center'}}>
                      <font color="#404040">{order.item_code ? order.item_code.substring(0, 15): ''}</font>
                    </td>
                    <td align="right" valign="middle" style={{textAlign: 'center'}}>
                      <font color="#404040">{order.item_name}</font>
                    </td>
                    <td align="right" valign="middle" style={{textAlign: 'center'}}>
                      <font color="#404040">{order.user_email}</font>
                    </td>
                    <td align="right" valign="middle" style={{textAlign: 'center'}}>
                      <font color="#404040">{order.item_amount}</font>
                    </td>
                    <td align="right" valign="middle" style={{textAlign: 'center'}}>
                      <font color="#404040">{order.item_total_price}</font>
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
        <table style={{width: '100%'}}>
          <tbody>
            <tr style={{height: '50px'}}>
            </tr>
            <tr ><td colSpan={7} valign="bottom" >
                <b><font face="Microsoft Sans Serif" color="#404040">LỊCH SỬ THANH TOÁN</font></b>
              </td></tr>
            <tr style={{height: '30px'}}>
            </tr>
            <tr className="bottom-border" style={{borderBottom: '2px solid rgb(55, 108, 54)'}}>
              <td height={39} align="left" valign="middle" style={{textAlign: 'center'}}>
                <font color="#404040" style={{textAlign: 'center'}}>No.</font>
              </td>
              <td align="left" valign="middle" style={{textAlign: 'center'}}>
                <font color="#404040" style={{textAlign: 'center'}}>Ngày thanh toán</font>
              </td>
              <td align="right" valign="middle" style={{textAlign: 'center'}}>
                <font color="#404040" style={{textAlign: 'center'}}>Số tiền</font>
              </td>
              <td align="right" valign="middle" style={{textAlign: 'center'}} colSpan={4}>
                <font color="#404040" style={{textAlign: 'center'}}>Chú thích</font>
              </td>
            </tr>
            {
              paymentList.map((payment, index) => (
                <tr className="bottom-border">
                  <td height={39} align="left" valign="middle" style={{textAlign: 'center'}}>
                    <font color="#404040" style={{textAlign: 'center'}}>{index + 1}</font>
                  </td>
                  <td align="left" valign="middle" style={{textAlign: 'center'}}>
                    <font color="#404040" style={{textAlign: 'center'}}>{moment(payment.createdAt).format('YYYY-MM-DD')}</font>
                  </td>
                  <td align="right" valign="middle" style={{textAlign: 'center'}}>
                    <font color="#404040" style={{textAlign: 'center'}}>{payment.payment_value}</font>
                  </td>
                  <td align="right" valign="middle" style={{textAlign: 'center'}} colSpan={4}>
                    <font color="#404040" style={{textAlign: 'center'}}>{payment.payment_note}</font>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    );
}

export default FinancalList;

import React from 'react';
import {connect} from 'react-redux';
import {withLocalize} from "react-localize-redux";
import {renderToStaticMarkup} from "react-dom/server";
import globalTranslations from "../translations/global.json";
import {ToastContainer, toast} from 'react-toastify';
import Popup from 'react-popup';
import {offlineLoginSuccess} from '../actions/user';
import 'react-toastify/dist/ReactToastify.css';

class App extends React.Component {
    constructor(props) {
        super(props);
    }

    componentWillMount() {
        this.props.initialize({
            languages: [
                {name: "English", code: "en"},
                {name: "Vietnamese", code: "vi"}
            ],
            translation: globalTranslations,
            options: {
                renderToStaticMarkup,
                renderInnerHtml: true,
                defaultLanguage: "en"
            }
        });
        this.props.addTranslation(globalTranslations);

        this.props.setActiveLanguage("en");

        
        const token = localStorage.getItem('token');
        const userData = localStorage.getItem('userData');

        try {
            let userInfo = JSON.parse(userData);
            if (token && userInfo) {
                this.props.offlineLoginSuccess({...userInfo, token: token});
            }
        } catch (e) {
            console.log('Error occurs: ', e)
        }
    }

    render() {
        const {isLoading} = this.props.utils;
        const childrenWithProps = React.Children.map(this.props.children, child =>
            React.cloneElement(child, {...this.props, children: child.props.children}));
        return (
            <div className={`pnlab-admin-wrapper`}>
                {childrenWithProps}
                <ToastContainer position="top-right" autoClose={3500} progressClassName={"fancy-progress-bar"}/>
                <Popup
                    className="mm-popup"
                    btnClass="mm-popup__btn"
                    closeBtn={false}
                    closeHtml={null}
                    defaultOk="Ok"
                    defaultCancel="Cancel"
                    wildClasses={false}
                    escToClose={false} />
                <div className={`${isLoading ? "overlay-loading" : "hidden" }`}>
                    <div className="lds-dual-ring"></div>
                </div>
            </div>
        );
    }

}

const mapStateToProps = (state) => ({
    ...state,
});


const mapDispatchToProps = dispatch => ({
    enableLoading: dispatch => {
    },
    loadingSuccess: dispatch => {
    },
    offlineLoginSuccess: async data => {
        return await dispatch(offlineLoginSuccess(data));
    }
});

export default withLocalize(connect(mapStateToProps, mapDispatchToProps)(App));

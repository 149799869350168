import api from '../utils/api';
import {toast} from 'react-toastify';
import {enableLoading, loadingFailed, loadingSuccess} from '../actions/utils';
import {browserHistory} from 'react-router';

export const FETCH_CATEGORIES = `FETCH_CATEGORIES`;
export const FETCH_CATEGORIES_SUCCESS = `FETCH_CATEGORIES_SUCCESS`;

export const getAllListCategories = (data) => {
    return async (dispatch) => {
        try {
            await dispatch(enableLoading());
            let res = await api.getAllListCategories(data) || {};
            if (res.data && res.data.status === "success") {
                const {data, message} = res.data;
                if (message) toast.success(message);
                if (data) await dispatch(getAllListCategoriesSuccess(data));
                await dispatch(loadingSuccess());
            } else {
                if (res.data && res.data.message) toast.error(res.data.message);
                await dispatch(loadingFailed());
            }
        } catch (e) {
            console.log(e);
            await dispatch(loadingFailed());
            toast.error(e.message);
        }
    }
}

export const getAllListCategoriesSuccess = (data) => {
    return {
        type: FETCH_CATEGORIES_SUCCESS,
        payload: data
    }
}

export const getCategoriesInShort = (exclude) => {
    return async (dispatch) => {
        try {
            await dispatch(enableLoading());
            let res = await api.getCategoriesInShort(exclude) || {};
            if (res.data && res.data.status === "success") {
                const {data, message} = res.data;
                //if (message) toast.success(message);
                await dispatch(loadingSuccess());
                if (data) return data;
            } else {
                if (res.data && res.data.message) toast.error(res.data.message);
                await dispatch(loadingFailed());
                return [];
            }
        } catch (e) {
            console.log(e);
            await dispatch(loadingFailed());
            toast.error(e.message);
        }
    }
}

export const createCategory = (query) => {
    return async (dispatch) => {
        try {
            await dispatch(enableLoading());
            let res = await api.createCategory(query) || {};
            if (res.data && res.data.status === "success") {
                const {data, message} = res.data;
                if (message) toast.success(message);
                await dispatch(loadingSuccess());
                browserHistory.push('/admin/category-management/list')
            } else {
                if (res.data && res.data.message) toast.error(res.data.message);
                await dispatch(loadingFailed());
            }
        } catch (e) {
            console.log(e);
            await dispatch(loadingFailed());
            toast.error(e.message);
        }
    }
}

export const getCategoryData = (query) => {
    return async (dispatch) => {
        try {
            await dispatch(enableLoading());
            let res = await api.getCategoryData(query) || {};
            if (res.data && res.data.status === "success") {
                const {data, message} = res.data;
                //if (message) toast.success(message);
                await dispatch(loadingSuccess());
                //browserHistory.push('/admin/category-management/list')
                if (data) return data;
            } else {
                if (res.data && res.data.message) toast.error(res.data.message);
                await dispatch(loadingFailed());
            }
        } catch (e) {
            console.log(e);
            await dispatch(loadingFailed());
            toast.error(e.message);
        }
    }
}
import Immutable from 'seamless-immutable';
import {LOGIN, LOGIN_SUCCESS, UPDATE_LOGGED_USER_INFO, USER_GET_LIST_SUCCESS, OFFLINE_LOGIN_SUCCESS} from '../actions/user';

const INIT_STATE = Immutable({
    userData: null,
    userList: [],
    token: '',
});

export default function (state = INIT_STATE, action) {
    switch (action.type) {
        case LOGIN:
            return state;
        case LOGIN_SUCCESS:
            return Object.assign({}, state, {userData: action.payload.userData, token: action.payload.token});
        case OFFLINE_LOGIN_SUCCESS:
            return Object.assign({}, state, {userData: action.payload, token: action.payload.token});
        case UPDATE_LOGGED_USER_INFO:
            return Object.assign({}, state, {userData: action.payload});
        case USER_GET_LIST_SUCCESS:
            return Object.assign({}, state, {userList: action.payload});
        default:
            return state;
    }
}

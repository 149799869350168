export const ENABLE_LOADING = `ENABLE_LOADING`;
export const LOADING_SUCCESS = `LOADING_SUCCESS`;
export const LOADING_FAILED = `LOADING_FAILED`;
export const TOGGLE_SIDEBAR = `TOGGLE_SIDEBAR`;

export const enableLoading = () => ({
    type: ENABLE_LOADING,
})

export const loadingSuccess = () => ({
    type: LOADING_SUCCESS,
})

export const loadingFailed = () => ({
    type: LOADING_FAILED,
})

export const toggleSidebar = (isOpen) => ({
    type: TOGGLE_SIDEBAR,
    payload: isOpen,
})

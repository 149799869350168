import api from '../utils/api';
import {toast} from 'react-toastify';
import {enableLoading, loadingFailed, loadingSuccess} from '../actions/utils';
import {browserHistory} from 'react-router';

export const CREATE_EXCHANGE_SUCCESS = `CREATE_EXCHANGE_SUCCESS`;
export const FETCH_EXCHANGE_SUCCESS = `FETCH_EXCHANGE_SUCCESS`;
export const FETCH_ALL_EXCHANGE_SUCCESS = `FETCH_ALL_EXCHANGE_SUCCESS`;

export const getExchangeData = (data) => {
    return async (dispatch) => {
        try {
            await dispatch(enableLoading());
            let res = await api.getExchangeData(data) || {};
            if (res.data && res.data.status === "success") {
                const {data, message} = res.data;
                await dispatch(loadingSuccess());
                if (data) return data;
            } else {
                if (res.data && res.data.message) toast.error(res.data.message);
                await dispatch(loadingFailed());
            }
        } catch (e) {
            console.log(e);
            await dispatch(loadingFailed());
            toast.error(e.message);
        }
    }
}

export const deleteExchange = (data) => {
    return async (dispatch) => {
        try {
            await dispatch(enableLoading());
            let res = await api.deleteExchange(data) || {};
            if (res.data && res.data.status === "success") {
                const {data, message} = res.data;
                if (message) toast.success(message);
                await dispatch(loadingSuccess());
            } else {
                if (res.data && res.data.message) toast.error(res.data.message);
                await dispatch(loadingFailed());
            }
        } catch (e) {
            console.log(e);
            await dispatch(loadingFailed());
            toast.error(e.message);
        }
    }
}

export const fetchAllExchange = (data) => {
    return async (dispatch) => {
        try {
            await dispatch(enableLoading());
            let res = await api.fetchAllExchange(data) || {};
            if (res.data && res.data.status === "success") {
                const {data, message} = res.data;
                //if (message) toast.success(message);
                await dispatch(loadingSuccess());
                //if (data) browserHistory.push('/admin/Exchange-management/list');
                if (data) await dispatch(fetchAllExchangeSuccess(data));
            } else {
                if (res.data && res.data.message) toast.error(res.data.message);
                await dispatch(loadingFailed());
            }
        } catch (e) {
            console.log(e);
            await dispatch(loadingFailed());
            toast.error(e.message);
        }
    }
}

export const fetchAllExchangeNoQuery = () => {
    return async (dispatch) => {
        try {
            await dispatch(enableLoading());
            let res = await api.fetchAllExchangeNoQuery() || {};
            if (res.data && res.data.status === "success") {
                const {data, message} = res.data;
                await dispatch(loadingSuccess());
                if (data) return data;
            } else {
                if (res.data && res.data.message) toast.error(res.data.message);
                await dispatch(loadingFailed());
            }
        } catch (e) {
            console.log(e);
            await dispatch(loadingFailed());
            toast.error(e.message);
        }
    }
}

export const fetchAllExchangeSuccess = (data) => {
    return {
        type: FETCH_ALL_EXCHANGE_SUCCESS,
        payload: data
    }
}

export const createExchange = (data) => {
    return async (dispatch) => {
        try {
            await dispatch(enableLoading());
            let res = await api.createExchange(data) || {};
            if (res.data && res.data.status === "success") {
                const {data, message} = res.data;
                if (message) toast.success(message);
                await dispatch(loadingSuccess());
                if (data) browserHistory.push('/admin/exchange-management/list');
            } else {
                if (res.data && res.data.message) toast.error(res.data.message);
                await dispatch(loadingFailed());
            }
        } catch (e) {
            console.log(e);
            await dispatch(loadingFailed());
            toast.error(e.message);
        }
    }
}
import api from "../utils/api";
import { toast } from "react-toastify";
import { enableLoading, loadingFailed, loadingSuccess } from "../actions/utils";
import { doRightRole } from "../utils/helper";
import { browserHistory } from "react-router";

export const CREATE_ORDER_SUCCESS = `CREATE_ORDER_SUCCESS`;
export const FETCH_ORDER_SUCCESS = `FETCH_ORDER_SUCCESS`;

export const adminCreateOrder = data => {
  return async dispatch => {
    try {
      await dispatch(enableLoading());
      let res = (await api.adminCreateOrder(data)) || {};
      if (res.data && res.data.status === "success") {
        const { data, message } = res.data;
        if (message) toast.success(message);
        await dispatch(loadingSuccess());
        if (data) browserHistory.push("/admin/order-management/list");
      } else {
        if (res.data && res.data.message) toast.error(res.data.message);
        await dispatch(loadingFailed());
      }
    } catch (e) {
      console.log(e);
      await dispatch(loadingFailed());
      toast.error(e.message);
    }
  };
};

export const userCreateOrder = data => {
  return async dispatch => {
    try {
      await dispatch(enableLoading());
      let res = (await api.userCreateOrder(data)) || {};
      if (res.data && res.data.status === "success") {
        const { data, message } = res.data;
        if (message) toast.success(message);
        await dispatch(loadingSuccess());
        if (data) browserHistory.push("/user/order-management/list");
      } else {
        if (res.data && res.data.message) toast.error(res.data.message);
        await dispatch(loadingFailed());
      }
    } catch (e) {
      console.log(e);
      await dispatch(loadingFailed());
      toast.error(e.message);
    }
  };
};

export const getAllListItemsInOrder = data => {
  return async dispatch => {
    try {
      await dispatch(enableLoading());
      let res = (await api.getAllListItemsInOrder(data)) || {};
      if (res.data && res.data.status === "success") {
        const { data, message } = res.data;
        if (message) toast.success(message);
        if (data) await dispatch(getAllListOrdersSuccess(data));
        await dispatch(loadingSuccess());
      } else {
        if (res.data && res.data.message) toast.error(res.data.message);
        await dispatch(loadingFailed());
      }
    } catch (e) {
      console.log(e);
      await dispatch(loadingFailed());
      toast.error(e.message);
    }
  };
};

export const getAllListItemsInOrderForUser = data => {
  return async dispatch => {
    try {
      await dispatch(enableLoading());
      let res = (await api.getAllListItemsInOrderForUser(data)) || {};
      if (res.data && res.data.status === "success") {
        const { data, message } = res.data;
        if (message) toast.success(message);
        if (data) await dispatch(getAllListOrdersSuccess(data));
        await dispatch(loadingSuccess());
      } else {
        if (res.data && res.data.message) toast.error(res.data.message);
        await dispatch(loadingFailed());
      }
    } catch (e) {
      console.log(e);
      await dispatch(loadingFailed());
      toast.error(e.message);
    }
  };
};

export const getAllListOrders = data => {
  return async dispatch => {
    try {
      await dispatch(enableLoading());
      let res = (await api.getAllListOrders(data)) || {};
      if (res.data && res.data.status === "success") {
        const { data, message } = res.data;
        if (message) toast.success(message);
        if (data) await dispatch(getAllListOrdersSuccess(data));
        await dispatch(loadingSuccess());
      } else {
        if (res.data && res.data.message) toast.error(res.data.message);
        await dispatch(loadingFailed());
      }
    } catch (e) {
      console.log(e);
      await dispatch(loadingFailed());
      toast.error(e.message);
    }
  };
};

export const getAllListOrdersForUser = data => {
  return async dispatch => {
    try {
      await dispatch(enableLoading());
      let res = (await api.getAllListOrdersForUser(data)) || {};
      if (res.data && res.data.status === "success") {
        const { data, message } = res.data;
        if (message) toast.success(message);
        if (data) await dispatch(getAllListOrdersSuccess(data));
        await dispatch(loadingSuccess());
      } else {
        if (res.data && res.data.message) toast.error(res.data.message);
        await dispatch(loadingFailed());
      }
    } catch (e) {
      console.log(e);
      await dispatch(loadingFailed());
      toast.error(e.message);
    }
  };
};

export const getAllListOrdersForFinance = (data, moreData) => {
  return async dispatch => {
    try {
      await dispatch(enableLoading());
      let res =
        (await api.getAllListOrdersForFinance({ ...data, ...moreData })) || {};
      if (res.data && res.data.status === "success") {
        const { data, message } = res.data;
        if (message) toast.success(message);
        if (data) await dispatch(getAllListOrdersSuccess(data));
        await dispatch(loadingSuccess());
      } else {
        if (res.data && res.data.message) toast.error(res.data.message);
        await dispatch(loadingFailed());
      }
    } catch (e) {
      console.log(e);
      await dispatch(loadingFailed());
      toast.error(e.message);
    }
  };
};

export const getAllListOrdersSuccess = data => {
  return {
    type: FETCH_ORDER_SUCCESS,
    payload: data
  };
};

export const getItemData = data => {
  return async dispatch => {
    try {
      await dispatch(enableLoading());
      let res =
        (await doRightRole(
          {
            employee: async data => await api.getItemData(data),
            admin: async data => await api.getItemData(data),
            customer: async data => await api.getUserItemData(data)
          },
          data
        )) || {};
      if (res.data && res.data.status === "success") {
        const { data, message } = res.data;
        if (message) toast.success(message);
        await dispatch(loadingSuccess());
        if (data) return data;
      } else {
        if (res.data && res.data.message) toast.error(res.data.message);
        await dispatch(loadingFailed());
      }
    } catch (e) {
      console.log(e);
      await dispatch(loadingFailed());
      toast.error(e.message);
    }
  };
};

export const getOrderMeta = id => {
  return async dispatch => {
    try {
      await dispatch(enableLoading());
      let res = (await api.getOrderMeta(id)) || {};
      if (res.data && res.data.status === "success") {
        const { data, message } = res.data;
        if (message) toast.success(message);
        await dispatch(loadingSuccess());
        if (data) return data;
      } else {
        if (res.data && res.data.message) toast.error(res.data.message);
        await dispatch(loadingFailed());
      }
    } catch (e) {
      console.log(e);
      await dispatch(loadingFailed());
      toast.error(e.message);
    }
  };
};

export const updateItem = data => {
  return async dispatch => {
    try {
      await dispatch(enableLoading());
      let res = (await api.updateItem(data)) || {};
      if (res.data && res.data.status === "success") {
        const { data, message } = res.data;
        if (message) toast.success(message);
        await dispatch(loadingSuccess());
        if (data) browserHistory.go(-1);
      } else {
        if (res.data && res.data.message) toast.error(res.data.message);
        await dispatch(loadingFailed());
      }
    } catch (e) {
      console.log(e);
      await dispatch(loadingFailed());
      toast.error(e.message);
    }
  };
};

export const deleteItem = data => {
  return async dispatch => {
    try {
      await dispatch(enableLoading());
      let res = (await api.deleteItem(data)) || {};
      if (res.data && res.data.status === "success") {
        const { data, message } = res.data;
        if (message) toast.success(message);
        await dispatch(loadingSuccess());
        //if (data) browserHistory.push('/admin/order-management/list');
      } else {
        if (res.data && res.data.message) toast.error(res.data.message);
        await dispatch(loadingFailed());
      }
    } catch (e) {
      console.log(e);
      await dispatch(loadingFailed());
      toast.error(e.message);
    }
  };
};

export const savePdf = data => {
  return async dispatch => {
    try {
      await dispatch(enableLoading());
      let res = (await api.savePdf(data)) || {};
      if (res.data && res.data.status === "success") {
        const { data, message } = res.data;
        if (message) toast.success(message);
        await dispatch(loadingSuccess());
        if (data) return Promise.resolve(data);
      } else {
        if (res.data && res.data.message) toast.error(res.data.message);
        await dispatch(loadingFailed());
      }
    } catch (e) {
      console.log(e);
      await dispatch(loadingFailed());
      toast.error(e.message);
    }
  };
};

export const saveOrderMeta = data => {
  return async dispatch => {
    try {
      await dispatch(enableLoading());
      let res = (await api.saveOrderMeta(data)) || {};
      if (res.data && res.data.status === "success") {
        const { data, message } = res.data;
        if (message) toast.success(message);
        await dispatch(loadingSuccess());
        if (data) return Promise.resolve(data);
      } else {
        if (res.data && res.data.message) toast.error(res.data.message);
        await dispatch(loadingFailed());
      }
    } catch (e) {
      console.log(e);
      await dispatch(loadingFailed());
      toast.error(e.message);
    }
  };
};

export const addPaymentHistory = data => {
  return async dispatch => {
    try {
      await dispatch(enableLoading());
      let res = (await api.addPaymentHistory(data)) || {};
      if (res.data && res.data.status === "success") {
        const { data, message } = res.data;
        if (message) toast.success(message);
        await dispatch(loadingSuccess());
        if (data) return Promise.resolve(data);
      } else {
        if (res.data && res.data.message) toast.error(res.data.message);
        await dispatch(loadingFailed());
      }
    } catch (e) {
      console.log(e);
      await dispatch(loadingFailed());
      toast.error(e.message);
    }
  };
};

export const getPaymentHistory = data => {
  return async dispatch => {
    try {
      await dispatch(enableLoading());
      let res = (await api.getPaymentHistory(data)) || {};
      if (res.data && res.data.status === "success") {
        const { data, message } = res.data;
        if (message) toast.success(message);
        await dispatch(loadingSuccess());
        if (data) return Promise.resolve(data);
      } else {
        if (res.data && res.data.message) toast.error(res.data.message);
        await dispatch(loadingFailed());
      }
    } catch (e) {
      console.log(e);
      await dispatch(loadingFailed());
      toast.error(e.message);
    }
  };
};

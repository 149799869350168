import React from "react";
import { browserHistory } from "react-router";
import {
    Paper,
    Typography,
    Grid,
    Button,
    TextField,
    FormControl,
    InputLabel,
    Select,
    Input,
    MenuItem,
    FormHelperText,
    Badge
} from "@material-ui/core";
import "./Order.css";
import { buildS3Url, checkPermissionAdmin } from "../../utils/helper";
import { DatePicker } from "material-ui-pickers";
import moment from "moment";
import { ITEM_STATUS } from "../../constants";
import Helper from "../../utils/helper";

class OrderUpdate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {
                //id: '', //number
                item_code: "",
                item_status: "",
                item_creator: "",
                item_name: "",
                item_amount: "",
                item_description: "",
                item_attachment: "",
                item_unit_price: "",
                item_price_unit: "",
                item_total_price: "",
                item_supplier: "",
                item_import_code: "",
                item_export_code: "",
                item_shipping_fee: "",
                item_shipping_expected: null,
                item_note: "",
                factor: 1,
                isEdited: 0,
                discount: 0,
            },
            itemMetadata: {
                exchange: -1
            },
            exchanges: [],
            suppliers: [],
            discount: 0,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleMetaSelectChange = this.handleMetaSelectChange.bind(this);
        this.handleSelectTimeChange = this.handleSelectTimeChange.bind(this);
        this.getCreatorData = this.getCreatorData.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        // Fetch order data for edit
        const { id } = this.props.params;
        this.getItemData({ id: id });
        //Fetch exchanges
        this.fetchAllExchangeNoQuery();
        this.fetchAllSupplierNoQuery();
    }

    async fetchAllExchangeNoQuery() {
        let exchanges = await this.props.fetchAllExchangeNoQuery();
        this.setState({ exchanges });
    }

    async fetchAllSupplierNoQuery() {
        let suppliers = await this.props.fetchAllSupplierNoQuery();
        this.setState({ suppliers });
    }

    async getItemData(param) {
        let itemData = (await this.props.getItemData(param)) || {};
        delete itemData.created_at;
        delete itemData.updated_at;
        await this.getCreatorData(itemData);
        itemData.item_shipping_expected = itemData.item_shipping_expected ?
            new moment(itemData.item_shipping_expected) :
            null;
        if (itemData) {
            this.state.formData = {
                ...this.state.formData,
                ...itemData,
                factor: itemData.factor || 1,
                item_total_price: itemData.item_total_price.toFixed(2)
            };
        }
        this.setState({ formData: {...this.state.formData } });
    }

    async getCreatorData(itemData) {
        let creatorData = await this.props.getUserProfile(itemData.item_creator);
        this.state.discount = itemData.discount || creatorData.discount;
        this.setState({...this.state });
    }

    onSubmit() {
        console.log(`Submit!`);
        let { formData } = this.state;
        formData = {
            ...formData,
            item_shipping_expected: formData.item_shipping_expected ?
                formData.item_shipping_expected.format("Y-M-D hh:mm:ss") : null,
            item_total_price: Helper.calculateTotalItemPrice(
                this.state.formData.item_amount,
                this.state.formData.item_unit_price,
                this.state.exchanges,
                this.state.formData.item_price_unit,
                this.state.formData.item_shipping_fee,
                this.state.formData.factor,
                this.state.discount
            ),
            isEdited: this.state.formData.item_total_price == Helper.calculateTotalItemPrice(
                this.state.formData.item_amount,
                this.state.formData.item_unit_price,
                this.state.exchanges,
                this.state.formData.item_price_unit,
                this.state.formData.item_shipping_fee,
                this.state.formData.factor,
                this.state.discount
            ),
            discount: this.state.discount,
        };
        console.log(formData);
        return this.props.updateItem(formData);
    }

    handleChange(field, e) {
        this.state.formData[field] = e.currentTarget.value;
        this.setState({ formData: {...this.state.formData } });
    }

    handleSelectChange(field, e) {
        this.state.formData[field] = e.target.value;
        this.setState({ formData: {...this.state.formData } });
    }

    handleMetaSelectChange(field, e) {
        this.state.itemMetadata[field] = e.target.value;
        this.setState({ itemMetadata: {...this.state.itemMetadata } });
    }

    handleSelectTimeChange(field, e) {
        this.state.formData[field] = e;
        this.setState({ formData: {...this.state.formData } });
    }
    renderCategories(categories) {
        categories = categories || [];
        return categories.map((order, index) => {
            if (index == 0) {
                return ( <
                    MenuItem key = { order.id }
                    value = { order.id }
                    selected > { order.order_name } <
                    /MenuItem>
                );
            } else {
                return ( <
                    MenuItem key = { order.id }
                    value = { order.id } > { order.order_name } <
                    /MenuItem>
                );
            }
        });
    }

    render() {
        let { exchanges, suppliers } = this.state;
        exchanges = Array.isArray(exchanges) ? exchanges : [];
        suppliers = Array.isArray(suppliers) ? suppliers : [];
        return ( <
            Paper elevation = { 1 }
            square className = { "container" } >
            <
            Typography variant = "h5"
            component = "h4"
            className = { "mc_page_title" } >
            Order Update <
            /Typography> <
            Grid container spacing = { 16 } >
            <
            Grid item xs = { 12 }
            sm = { 6 }
            md = { 3 }
            lg = { 3 } >
            <
            TextField className = { "full-width" }
            label = "Item Name"
            type = "text"
            margin = "normal"
            value = { this.state.formData.item_name }
            onChange = { e => this.handleChange("item_name", e) }
            disabled = {!checkPermissionAdmin() }
            /> < /
            Grid > <
            Grid item xs = { 12 }
            sm = { 6 }
            md = { 3 }
            lg = { 3 } >
            <
            TextField className = { "full-width" }
            label = "Item Amount"
            type = "number"
            margin = "normal"
            value = { this.state.formData.item_amount }
            onChange = { e => this.handleChange("item_amount", e) }
            /> < /
            Grid > <
            Grid item xs = { 12 }
            sm = { 6 }
            md = { 3 }
            lg = { 3 } >
            <
            TextField className = { "full-width" }
            label = "Item Description"
            type = "text"
            margin = "normal"
            value = { this.state.formData.item_description }
            onChange = { e => this.handleChange("item_description", e) }
            disabled = {!checkPermissionAdmin() }
            /> < /
            Grid > <
            Grid item xs = { 12 }
            sm = { 6 }
            md = { 3 }
            lg = { 3 } >
            <
            TextField className = { "full-width" }
            label = "Item Attachment"
            type = "text"
            margin = "normal"
            value = { this.state.formData.item_attachment }
            onClick = {
                () =>
                window.open(
                    buildS3Url(this.state.formData.item_attachment),
                    "__blank"
                )
            }
            disabled = {!checkPermissionAdmin() }
            /> < /
            Grid > <
            Grid item xs = { 12 }
            sm = { 6 }
            md = { 3 }
            lg = { 3 } >
            <
            Grid container >
            <
            Grid item xs = { 12 }
            sm = { 6 }
            md = { 6 }
            lg = { 6 } >
            <
            TextField className = { "full-width" }
            label = "Item Unit Price"
            type = "text"
            margin = "normal"
            value = { this.state.formData.item_unit_price }
            onChange = { e => this.handleChange("item_unit_price", e) }
            /> < /
            Grid > <
            Grid item xs = { 12 }
            sm = { 6 }
            md = { 6 }
            lg = { 6 } >
            <
            TextField className = { "full-width" }
            label = "Factor"
            type = "text"
            margin = "normal"
            value = { this.state.formData.factor }
            onChange = { e => this.handleChange("factor", e) }
            /> < /
            Grid > <
            /Grid> < /
            Grid > <
            Grid item xs = { 12 }
            sm = { 6 }
            md = { 3 }
            lg = { 3 } >
            <
            Grid container >
            <
            Grid item xs = { 12 }
            sm = { 8 }
            md = { 8 }
            lg = { 8 } >
            <
            TextField className = { "full-width" }
            label = { < > Item Total Price < span className = "padding-badge" > { ' ▾' + this.state.discount + '% ' } < /span></ > }
            type = "text"
            margin = "normal"
            value = {
                this.state.formData.isEdited ?
                this.state.formData.item_total_price : Helper.calculateTotalItemPrice(
                    this.state.formData.item_amount,
                    this.state.formData.item_unit_price,
                    this.state.exchanges,
                    this.state.formData.item_price_unit,
                    this.state.formData.item_shipping_fee,
                    this.state.formData.factor,
                    this.state.discount
                )
            }
            onChange = { e => this.handleChange("item_total_price", e) }
            disabled = {!checkPermissionAdmin() }
            /> < /
            Grid > <
            Grid item xs = { 12 }
            sm = { 4 }
            md = { 4 }
            lg = { 4 } >
            <
            FormControl className = { `full-width missing-margin` } >
            <
            InputLabel htmlFor = "exchange-helper" > (Unit) < /InputLabel> <
            Select value = { this.state.formData.item_price_unit }
            onChange = {
                e =>
                this.handleSelectChange("item_price_unit", e)
            }
            input = { < Input name = "exchange"
                id = "exchange-helper" / >
            }
            className = { `full-width` } > {
                exchanges.map((value, index) => {
                    return ( <
                        MenuItem value = { value.id }
                        key = { index }
                        selected = {
                            value.id == this.state.formData.item_price_unit
                        } > { value.exchange_unit } <
                        /MenuItem>
                    );
                })
            } <
            /Select> < /
            FormControl > <
            /Grid> < /
            Grid > <
            /Grid> <
            Grid item xs = { 12 }
            sm = { 6 }
            md = { 3 }
            lg = { 3 } >
            <
            FormControl className = { `full-width missing-margin` } >
            <
            InputLabel htmlFor = "item_status-helper" > Item Status < /InputLabel> <
            Select value = { this.state.formData.item_status }
            onChange = { e => this.handleSelectChange("item_status", e) }
            input = { < Input name = "item_status"
                id = "item_status-helper" / >
            }
            className = { `full-width` } > {
                ITEM_STATUS.map((value, index) => {
                    return ( <
                        MenuItem value = { value }
                        key = { index }
                        selected = { value == this.state.formData.item_status } > { value } <
                        /MenuItem>
                    );
                })
            } <
            /Select> < /
            FormControl > <
            /Grid> <
            Grid item xs = { 12 }
            sm = { 6 }
            md = { 3 }
            lg = { 3 } >
            <
            FormControl className = { `full-width missing-margin` } >
            <
            InputLabel htmlFor = "item_supplier-helper" >
            Item Supplier <
            /InputLabel> <
            Select value = { this.state.formData.item_supplier }
            onChange = { e => this.handleSelectChange("item_supplier", e) }
            input = { < Input name = "item_supplier"
                id = "item_supplier-helper" / >
            }
            className = { `full-width` } > {
                suppliers.map((value, index) => {
                    return ( <
                        MenuItem value = { value.id }
                        key = { index }
                        selected = { value.id == this.state.formData.item_supplier } > { value.supplier_name } <
                        /MenuItem>
                    );
                })
            } <
            /Select> < /
            FormControl > <
            /Grid> <
            Grid item xs = { 12 }
            sm = { 6 }
            md = { 3 }
            lg = { 3 } >
            <
            TextField className = { "full-width" }
            label = "Item Import Code"
            type = "text"
            margin = "normal"
            value = { this.state.formData.item_import_code }
            onChange = { e => this.handleChange("item_import_code", e) }
            /> < /
            Grid > <
            Grid item xs = { 12 }
            sm = { 6 }
            md = { 3 }
            lg = { 3 } >
            <
            TextField className = { "full-width" }
            label = "Item Export Code"
            type = "text"
            margin = "normal"
            value = { this.state.formData.item_export_code }
            onChange = { e => this.handleChange("item_export_code", e) }
            /> < /
            Grid > <
            Grid item xs = { 12 }
            sm = { 6 }
            md = { 3 }
            lg = { 3 } >
            <
            TextField className = { "full-width" }
            label = "Item Shipping Fee (VND)"
            type = "number"
            margin = "normal"
            value = { this.state.formData.item_shipping_fee }
            onChange = { e => this.handleChange("item_shipping_fee", e) }
            /> < /
            Grid > <
            Grid item xs = { 12 }
            sm = { 6 }
            md = { 3 }
            lg = { 3 } >
            <
            DatePicker margin = "normal"
            label = "Item Shipping Expected"
            format = "DD/MM/YYYY"
            className = "fullwidth"
            value = { this.state.formData.item_shipping_expected }
            onChange = {
                e =>
                this.handleSelectTimeChange("item_shipping_expected", e)
            }
            /> < /
            Grid > <
            Grid item xs = { 12 }
            sm = { 6 }
            md = { 3 }
            lg = { 3 } >
            <
            TextField className = { "full-width" }
            label = "Item Note"
            type = "text"
            margin = "normal"
            value = { this.state.formData.item_note }
            onChange = { e => this.handleChange("item_note", e) }
            multiline /
            >
            <
            /Grid> <
            Grid container spacing = { 16 }
            className = { `footer-padding` } >
            <
            Grid item xs = { 12 }
            sm = { 6 }
            md = { 6 }
            lg = { 6 }
            className = "flex-lg-row-reverse" >
            <
            Button variant = "contained"
            color = "primary"
            className = { `pull-right` }
            onClick = { this.onSubmit } >
            Update <
            /Button> < /
            Grid > <
            Grid item xs = { 12 }
            sm = { 6 }
            md = { 6 }
            lg = { 6 } >
            <
            Button variant = "contained"
            color = "default"
            onClick = {
                () =>
                browserHistory.push(`/admin/order-management/list`)
            } >
            Cancel <
            /Button> < /
            Grid > <
            /Grid> < /
            Grid > <
            /Paper>
        );
    }
}

export default OrderUpdate;